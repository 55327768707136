import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { FileUp, XCircle } from "lucide-react";
import { useEchoEffect } from "@helpers/utils";
import { useTranslation } from "react-i18next";
import BaseModal from "@reusables/Modals/BaseModal";
import { ReactComponent as SuccessSVG } from "@assets/icons/ic_success.svg";
import { BaseCircularProgressWithContent } from "@reusables/BaseCircularProgressWithContent";
import {
    finishPriceReconversion,
    resetPriceReconversion,
    startPriceReconversion,
    updatePriceReconversionProgress
} from "@redux/features/products/reconversion/reconversionSlice";
import {
    isPriceReconversionStateActive,
    isPriceReconversionStateFinished,
    selectProgressMean,
} from "@redux/features/products/reconversion/types";
import { PriceReconversion } from "@/types/general";
import { invalidateModuleCache } from "../cacheUtils";

export default function PriceReconversionIndicator(): JSX.Element {
    const { t } = useTranslation("", { keyPrefix: "products.allProducts.reconversion.reconversionIndicator.states" });
    const dispatch = useAppDispatch();
    const company = useAppSelector((state) => state.auth.user?.company);
    const reconversionState = useAppSelector((state) => state.priceReconversion.value);

    // Listen for "ReconversionStarted"
    useEchoEffect<PriceReconversion.PriceReconversionStarted>(
        `reconversion.${company?.id}`,
        "Reconversion\\ReconversionStarted",
        (data) => {
            dispatch(startPriceReconversion({ module: data.module }));
        },
        undefined,
        [company]
    );

    // Listen for "ReconversionProgressUpdate"
    useEchoEffect<PriceReconversion.PriceReconversionProgressUpdate>(
        `reconversion.${company?.id}`,
        "Reconversion\\ReconversionProgressUpdate",
        (data) => {
            dispatch(updatePriceReconversionProgress({
                current: data.current,
                total: data.total
            }));
        },
        undefined,
        [company, dispatch]
    );

    // Listen for "ReconversionFinished"
    useEchoEffect<PriceReconversion.PriceReconversionFinished>(
        `reconversion.${company?.id}`,
        "Reconversion\\ReconversionFinished",
        (finishData) => {
            dispatch(finishPriceReconversion({
                has_errors: !finishData.success,
                data: finishData.data
            }));
            finishData.success && invalidateModuleCache(finishData.data.module, dispatch);
        },
        undefined,
        [company]
    );

    const [isResultModalOpen, setIsResultModalOpen] = useState(false);

    return (
        <>
            {
                // While ACTIVE, show a progress indicator
                isPriceReconversionStateActive(reconversionState) && (
                    <BaseCircularProgressWithContent
                        size={26}
                        {
                            ...reconversionState.progress && {
                                value: selectProgressMean(reconversionState) * 100,
                                variant: "indeterminate"
                            }
                        }
                        tooltip={t("inProgress")}
                    >
                        <FileUp size={12} />
                    </BaseCircularProgressWithContent>
                )
            }
            {
                // When FINISHED, show a final state (100% progress) and allow opening a modal for result
                isPriceReconversionStateFinished(reconversionState) && (
                    <>
                        <BaseCircularProgressWithContent
                            size={26}
                            variant="determinate"
                            value={100}
                            sx={{
                                color: reconversionState.has_errors ? "#E25C5C" : "#A6DD4C"
                            }}
                            onClick={() => setIsResultModalOpen(true)}
                            tooltip={reconversionState.has_errors
                                ? t("finishedWithErrors")
                                : t("finishedSuccessfully")}
                        >
                            <FileUp size={12} />
                        </BaseCircularProgressWithContent>

                        <BaseModal
                            isOpen={isResultModalOpen}
                            onClose={() => {
                                dispatch(resetPriceReconversion());
                                setIsResultModalOpen(false);
                            }}
                            padding="40px"
                            position="center"
                        >
                            {
                                reconversionState.has_errors ? (
                                    <div className="flex items-center gap-4">
                                        <XCircle width={40} height={40} fill="#FF0000" color="#FFF" />
                                        <div className="flex flex-col items-start space-y-4">
                                            <div className="text-accent font-bold text-xl text-center">
                                                {t("error.title")}
                                            </div>
                                            <div className="text-accent text-lg">
                                                {t("error.message")}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex items-center gap-4">
                                        <SuccessSVG width={40} height={40} />
                                        <div className="text-accent font-bold text-xl text-center">
                                            {t("reconverted")}
                                        </div>
                                    </div>
                                )
                            }
                        </BaseModal>
                    </>
                )
            }
        </>
    );
}
