import React from "react";
import { OrderStatus } from "@/types/general";
import { useTranslation } from "react-i18next";
import { Ban, CheckCircle2, CircleDashed, Contrast } from "lucide-react";
import BaseTooltip from "@reusables/BaseTooltip";
import { cn } from "@helpers/utils";

type BaseOrderStatusBadgeProps = {
    status: OrderStatus.Base;
    message?: string | null;
    compact?: boolean;
}

type StatusProperties = {
    icon: JSX.Element;
}

const statusProperties: Partial<Record<OrderStatus.Base, StatusProperties>> = {
    new: {
        icon: <CircleDashed size={14} className="text-green-500" />
    },
    in_progress: {
        icon: <Contrast size={14} className="text-orange-300" />
    },
    completed: {
        icon: <CheckCircle2 size={14} className="text-blue-600" />
    },
    cancelled: {
        icon: <Ban size={14} />
    }
};

export default function BaseOrderStatusBadge({ status: statusKey, message, compact }: BaseOrderStatusBadgeProps) {
    const { t } = useTranslation("", { keyPrefix: "general.orders.statuses.base" });

    if (!(statusKey in statusProperties)) {
        return null;
    }

    const status = statusProperties[statusKey] as StatusProperties;

    return (
        <BaseTooltip title={message ?? ""} placement="top" active={!!message}>
           <div
                className="flex items-center rounded-xl px-2 p-1 border border-solid text-xs text-accent relative group"
                style={{
                    backgroundColor: "#FBFBFB",
                    borderColor: "#E4E4E4",
                }}
            >
                {status.icon}
                <span
                    className={cn("ml-1", compact && "ml-0 group-hover:ml-1 transition-margin duration-300 max-w-0 group-hover:max-w-[150px] overflow-hidden" )}
                    style={{
                        transition: "max-width 0.3s ease, margin-left 0.4s ease",
                    }}
                >
                    {t(statusKey)}
                </span>
                {message && <div className="absolute top-[0px] right-[0px] w-[6px] h-[6px] rounded-xl bg-red-500 m-0 p-0" />}
            </div>
        </BaseTooltip>
    );
}