import React, { useEffect } from "react";
import { Control, Controller, useWatch } from "react-hook-form";
import {
    CategoryMutationFormTyping
} from "@components/Dashboard/pages/SettingsPage/General/Categories/modals/MutationModal/index";
import { useTranslation } from "react-i18next";
import { useGetTwentyFourSevenOfficeSalesAccountsQuery } from "@redux/features/integrations/integrationsApi";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import BaseIntegrationSection from "@reusables/BaseIntegrationSection";

interface TwentyFourSevenOfficeIntegrationSectionProps {
    control: Control<CategoryMutationFormTyping>;
}

export default function TwentyFourSevenOfficeIntegrationSection(props: TwentyFourSevenOfficeIntegrationSectionProps) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.categories.modals.mutation.integrations.twentyFourSevenOffice" });

    const {
        data: salesAccountsOptions,
        isLoading: isSalesAccountsLoading
    } = useGetTwentyFourSevenOfficeSalesAccountsQuery();

    const [isOpen, setIsOpen] = React.useState(false);

    const twentyFourSevenOfficeWatch = useWatch({
        control: props.control,
        name: "integrations.twenty_four_seven_office"
    });

    useEffect(() => {
        if (twentyFourSevenOfficeWatch) {
            setIsOpen(true);
        }
    }, [twentyFourSevenOfficeWatch]);

    return (
        <BaseIntegrationSection
            integration={"twenty_four_seven_office"}
            hideIntegrationBadge
            isOpen={isOpen}
            onOpenChange={setIsOpen}
        >
            <Controller
                name={"integrations.twenty_four_seven_office.sales_account"}
                control={props.control}
                render={({ field, fieldState }) => (
                    <BaseDropdown
                        {...field}
                        {...fieldState}

                        label={t("salesAccount.label")}
                        placeholder={t("salesAccount.placeholder")}

                        options={salesAccountsOptions ?? []}
                        getter={{
                            key: opt => opt.id,
                            label: opt => opt.name,
                            caption: opt => opt.number
                        }}
                        isLoading={isSalesAccountsLoading}

                        virtualize
                        autocomplete
                    />
                )}
            />
        </BaseIntegrationSection>
    );
}