import React from 'react'
import BaseButton from '@reusables/BaseButton';
import BaseModal from '@reusables/Modals/BaseModal';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import BaseInput from '@reusables/BaseInput';
import { useCancelPurchaseOrderMutation } from '@redux/features/purchases/purchasesApi';
import { toastError } from '@helpers/utils';

interface CancellationModalProps {
    isOpen: boolean;
    entityId?: number;
    onClose: () => void;
    onCancellation?: () => void;
}

const formSchema = z.object({
    reason: z.string().trim().min(3).max(100)
});

type FormTyping = z.infer<typeof formSchema>;

export default function OrderCancellationModal({ isOpen, entityId, onClose, onCancellation }: CancellationModalProps) {
    const { t } = useTranslation("", { keyPrefix: "purchaseOrder.general.modals.cancellation" });

    const { control, handleSubmit } = useForm<FormTyping>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            reason: ""
        }
    });

    const [cancelOrder, { isLoading: isActiveCancellationLoading }] = useCancelPurchaseOrderMutation();

    const onSubmit = handleSubmit((data: FormTyping) => {
        if (entityId) {
            cancelOrder({
                id: entityId,
                reason: data.reason
            })
                .unwrap()
                .then(() => {
                    toast.success(t("responses.success"));
                    onCancellation?.();
                })
                .catch(e => {
                    toastError(e);
                })
                .finally(() => onClose())
        }
    })

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            width={700}
            padding="56px"
            isLoading={isActiveCancellationLoading}
        >
            <form className="space-y-[32px]" onSubmit={onSubmit}>
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                <div className="text-center text-accent">{t("subheading")}</div>

                <Controller
                    control={control}
                    name="reason"
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t("fields.reason.label") + "*"}
                            placeholder={t("fields.reason.placeholder")}
                        />
                    )}
                />
                <div className="grid grid-cols-2 gap-[16px]">
                    <BaseButton type='button' text={t("buttons.close")} size="md" primaryOutlined onClick={onClose} />
                    <BaseButton type='submit' text={t("buttons.confirm")} size="md" />
                </div>
            </form>
        </BaseModal>
    )
}
