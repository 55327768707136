import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import BaseTable from "@reusables/BaseTable";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { PurchaseOrdersFormTyping } from "../../types";
import AddProductModal from "./AddProductModal";
import TotalCell from "./TotalCell";
import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";
import { ReactComponent as TrashIcon } from "@assets/icons/ic_trash.svg";
import { normalizePrice } from "@helpers/utils";
import SummaryBlock from "./SummaryBlock";
import AddCollectionModal from "./AddCollectionModal";
import { Alert, Collapse } from "@mui/material";
import BaseBadge from "@components/reusables/BaseBadge";
import { ReactComponent as CollectionsIcon } from "@assets/icons/sidebar/ic_folders.svg";
import { v4 as uuidv4 } from "uuid";


export default function ProductSection() {
    const { t } = useTranslation("", {
        keyPrefix: "purchaseOrder.general.orders"
    });

    const { control, getValues, setValue, formState } = useFormContext<PurchaseOrdersFormTyping>();

    const { fields: lines, append, replace, update } = useFieldArray({
        control,
        name: "lines",
        keyName: "loop_id"
    });

    const [isAddProductModalOpen, setIsAddProductModalOpen] = React.useState(false);
    const [isAddCollectionModalOpen, setIsAddCollectionModalOpen] = React.useState(false);
    const [selectedLine, setSelectedLine] = React.useState<PurchaseOrdersFormTyping["lines"][0]>();
    const [selectedLineIndex, setSelectedLineIndex] = React.useState<number>();

    const [collapsedGroups, setCollapsedGroups] = React.useState<Record<string, boolean>>({});

    const toggleCollapse = (groupKey: string) => {
        setCollapsedGroups(prevState => ({
            ...prevState,
            [groupKey]: !prevState[groupKey]
        }));
    };

    const exchangeRate = useWatch({ control, name: "exchange_rate" });
    const supplier = useWatch({ control, name: "supplier" });

    useEffect(() => {
        const upToDateLines = getValues(`lines`);
        const updatedLines = upToDateLines.map(line => {
            if (line.data && line.data.unit_price) {
                return {
                    ...line,
                    data: {
                        ...line.data,
                        unit_price: {
                            converted: normalizePrice(line.data.unit_price.original * (exchangeRate ?? 1)),
                            original: line.data.unit_price.original
                        }
                    }
                };
            }
            return line;
        });

        replace(updatedLines);
    }, [exchangeRate]);

    const handleRemove = (index: number) => {
        const line = lines[index];
        const { type, key } = line;
        const deletedLines = getValues("deleted_lines") ?? [];
    
        const isGroupHeader = type === "group" && !line.data;
    
        if (isGroupHeader) {
            // Find all items belonging to the same group by their shared key
            const groupItems = lines.filter(item => item.key === key);
    
            const groupItemIds = groupItems.map(item => item.id).filter(Boolean);
    
            // Update deleted lines with group item IDs and remove group items
            setValue("deleted_lines", [...deletedLines, ...groupItemIds]);
            replace(lines.filter(item => item.key !== key));
        } else {
            // Add the single item's ID to deleted lines if it exists
            if (line.id) {
                setValue("deleted_lines", [...deletedLines, line.id]);
            }
    
            // Temporarily remove the item and store the updated lines
            const updatedLines = lines.filter((_, i) => i !== index);
    
            // Check if any items remain in the group
            const remainingGroupItems = updatedLines.filter(
                item => item.key === key && item.data
            );
    
            // If no items are left in the group, remove the group header
            if (remainingGroupItems.length === 0) {
                const groupHeaderIndex = updatedLines.findIndex(
                    item => item.key === key && !item.data
                );
    
                if (groupHeaderIndex !== -1) {
                    updatedLines.splice(groupHeaderIndex, 1);
                }
            }
    
            // Replace the lines with the updated list
            replace(updatedLines);
        }
    };
    
    return (
        <>
            <Collapse in={!!formState.errors.lines?.message}>
                <Alert severity={"error"}>{formState.errors.lines?.message}</Alert>
            </Collapse>
            <div className="border border-solid border-gray-300 rounded-[8px] p-[16px]">
                {lines.length ? (
                    <>
                        <div className="flex mb-[46px] items-center space-x-[8px]">
                            <div className="text-xl font-thin text-gray-600 grow">
                                {t("heading")}
                            </div>
                            {getValues("is_editable") === true && (
                                <>
                                    <BaseMaterialButton
                                        fontWeight={500}
                                        onClick={() => {
                                            setIsAddProductModalOpen(true);
                                            setSelectedLine(undefined);
                                        }}
                                    >
                                        {t("buttons.addProduct")}
                                    </BaseMaterialButton>
                                    <BaseMaterialButton
                                        fontWeight={500}
                                        onClick={() => setIsAddCollectionModalOpen(true)}
                                    >
                                        {t("buttons.addCollection")}
                                    </BaseMaterialButton>
                                </>
                            )}
                        </div>

                        <BaseTable
                            data={lines}
                            immutable
                            rowKeyGetter={(row) => row.loop_id}
                            columns={[
                                {
                                    header: t("columns.0"),
                                    getter: (row) => row.data ? 
                                        row.type === "group" ? 
                                            <div className="flex items-center">
                                                <div className="self-center w-[2px] h-[20px] bg-[#D9D6DA] rounded-sm mr-2"></div> 
                                                <div className="">{row?.data?.product?.code}</div>
                                            </div> 
                                        : row?.data?.product?.code 
                                        : row.type === "group" 
                                        ? 
                                        <div className="flex items-center">
                                            <div className="w-1 h-8 bg-[#B49FFB] rounded-sm"></div> 
                                                <div className="ml-3 absolute min-w-[120px] flex space-x-2">
                                                    <div className="font-semibold">{row.name}</div> 
                                                    <BaseBadge
                                                        className="px-2 bg-[#CDCFFD] rounded-xl"
                                                    >
                                                        <div className="flex items-center">
                                                            <CollectionsIcon className="mr-1 bg-[#CDCFFD] text-accent" />
                                                            {row.module === "collection" ? t("groups.0") : t("groups.1")}
                                                        </div>
                                                    </BaseBadge>
                                                </div>
                                            </div> 
                                        : "",
                                    sx: { width: "150px" }
                                },
                                {
                                    header: t("columns.1"),
                                    getter: (row) => row.data ?
                                        <div>{row?.data?.product?.name}</div> : "",
                                    sx: { width: "300px" }
                                },
                                {
                                    header: t("columns.2"),
                                    getter: (row) => row.data ?
                                        row?.data?.quantity : "",
                                    sx: { width: "150px" }
                                },
                                {
                                    header: t("columns.3"),
                                    getter: (row) => row.data ?
                                        <div className="flex items-center">
                                            {row?.data?.unit_price?.converted ? 
                                            <>
                                                {normalizePrice(row?.data?.unit_price?.converted)}
                                            </> : "-"} 
                                        </div> : "",
                                    sx: { width: "150px" }
                                },
                                {
                                    header: t("columns.4"),
                                    getter: (row) => row.data ?
                                        <div className="flex">
                                            {row?.data?.discount ? `${row?.data?.discount}%` : "-"}
                                        </div> : "",
                                    sx: { width: "150px" }
                                },
                                {
                                    header: t("columns.5"),
                                    getter: (row) => row.data ?
                                        <div className="flex">
                                            {row?.data?.tax?.rate ? `${row?.data?.tax?.rate}%` : "-"}
                                        </div> : "",
                                    sx: { width: "120px" }
                                },
                                {
                                    header: t("columns.6"),
                                    getter: (row, index) => row.data 
                                    ? <TotalCell index={index} lines_number={lines.length} />
                                    : row.type === "group" && !row.data ?
                                        "" :
                                        <TotalCell index={index} lines_number={lines.length} />,
                                    sx: { minWidth: "120px" }
                                },
                                {
                                    visible: getValues("is_editable") === true,
                                    header: () => (
                                        <div className="text-center">{t("columns.7")}</div>
                                    ),
                                    getter: (row, index) => (
                                            <div className={`flex justify-end space-x-2 ${row.type === 'group' && !row.data ? "text-[#5A5587]" : "text-tables-secondaryIcon"}`}>
                                                {!!row.data && (
                                                    <EditSVG
                                                    className="hover:text-tables-highlightedIcon cursor-pointer"
                                                    onClick={() => {
                                                        setIsAddProductModalOpen(true);
                                                        setSelectedLine(row);
                                                        setSelectedLineIndex(index);
                                                    }}
                                                />)}
                                                <TrashIcon
                                                    className="hover:text-tables-highlightedIcon cursor-pointer"
                                                    
                                                    onClick={() => handleRemove(index)}
                                                />
                                            </div>
                                        ),
                                    sx: { textAlign: "center" }
                                }
                            ]}
                            size={"small"}
                            rowOnClick={(row) => {
                                if (row.type === "group" && !row.data) {
                                    toggleCollapse(row.key ?? '');
                                }
                            }}
                            rowProps={(row) => {
                                if (row.type === "group" && !row.data && row.key) {
                                    return {
                                        className: "group-header",
                                        style: {
                                            backgroundColor: collapsedGroups[row.key] ? "#FFFFFF" : "#EEF2FF",
                                        }
                                    };
                                }

                                if (row.type === "group" && !!row.data && row.key) {
                                    return {
                                        className: collapsedGroups[row.key] ? "collapsed" : "expanded",
                                        style: {
                                            height: collapsedGroups[row.key] ? "0px" : "auto",
                                            opacity: collapsedGroups[row.key] ? 0 : 1,
                                        }
                                    };
                                }
                        
                            
                                if (row.type === "group" && !!row.data) {
                                    return { className: "group-cell" };
                                }
                                
                                return {};
                            }}
                            sx={{
                                "& .collapsed": {
                                    display: "none", // Hide rows that are collapsed
                                },
                                "& .expanded": {
                                    display: "table-row", // Ensure expanded rows are shown
                                },
                                "& .MuiTableBody-root": {
                                    position: "relative",
                                    "&::before": {
                                        content: '""',
                                        display: "block",
                                        height: "16px",  // Height of the spacing
                                        backgroundColor: "transparent", // Transparent to keep it invisible
                                    },
                                },
                                "& .group-header": {
                                    "&:hover": {
                                        cursor: "pointer"
                                    }
                                },
                                "& .group-cell .MuiTableCell-root": {
                                    padding: "4px 16px",
                                },
                                "& .group-header .MuiTableCell-root:first-of-type": {
                                    borderTopLeftRadius: "8px",
                                    borderBottomLeftRadius: "8px",
                                },
                                "& .group-header .MuiTableCell-root:last-of-type": {
                                    borderTopRightRadius: "8px",
                                    borderBottomRightRadius: "8px",
                                },
                            }}
                            headerSx={{ 
                                background: "#F9F9F9",
                            }}
                        />

                        <SummaryBlock />
                    </>
                ) : (
                    <>
                        <div className="text-xl text-center text-gray-300 font-thin">
                            {t("noProducts")}
                        </div>
                        <div className="grid grid-cols-2 gap-[40px] mt-[24px]">
                            <BaseMaterialButton
                                size="large"
                                fontWeight={500}
                                onClick={() => setIsAddProductModalOpen(true)}
                            >
                                {t("buttons.addProduct")}
                            </BaseMaterialButton>
                            <BaseMaterialButton
                                size="large"
                                fontWeight={500}
                                onClick={() => setIsAddCollectionModalOpen(true)}
                            >
                                {t("buttons.addCollection")}
                            </BaseMaterialButton>
                        </div>
                    </>
                )}

                <AddProductModal
                    isOpen={isAddProductModalOpen}
                    onClose={() => {
                        setIsAddProductModalOpen(false);
                        if (selectedLine) {
                            setSelectedLine(undefined);
                        }
                    }}
                    additionalData={{
                        currency: getValues("currency.symbol"),
                        exchange_rate: exchangeRate,
                        supplier_id: supplier?.id
                    }}
                    onLineMutation={(product) => {

                        if (product.should_use_bom) {
                            if (product.selected_bom) {
                                const bomKey = `${product.selected_bom.id}-${uuidv4()}`;

                                const groupHeader = {
                                    type: "group" as const,
                                    group_id: product.selected_bom.id,
                                    name: product.selected_bom.name,
                                    module: "bom",
                                    key: bomKey
                                };

                                const groupProducts = product.selected_bom.components.map((component) => ({
                                    
                                            type: "group" as const,
                                            group_id: product.selected_bom?.id,
                                            name: product.selected_bom?.name,
                                            module: "bom",
                                            key: bomKey,
                                            data: {
                                                product: component.product,
                                                quantity: component.quantity * (product.quantity ?? 1),
                                                unit_price: {
                                                    converted: normalizePrice(component.product.prices?.purchase_price * (exchangeRate ?? 1)),
                                                    original: component.product.prices?.purchase_price
                                                },
                                                discount: product.discount,
                                                tax: product.tax
                                            }
                                        })
                                    );
                                
                                // Append the group header
                                append(groupHeader as PurchaseOrdersFormTyping["lines"][0]);

                                // Append or update the group products
                                groupProducts.forEach(product => product?.data.product && append(product));
                            }
                        } else {
                            if (!selectedLine) {
                                append({
                                    type: "line",
                                    data: {
                                        ...product,
                                        quantity: product.quantity as number
                                    },
                                });
                            } else {
                                if (selectedLineIndex !== undefined) {
                                    update(selectedLineIndex, {
                                        ...selectedLine,
                                        data: {
                                            id: selectedLine.id,
                                            ...product,
                                            quantity: product.quantity as number,
                                        }
                                    });
                                }
                            }
                        }
                    }}
                    selectedLine={selectedLine}
                />

                <AddCollectionModal
                    isOpen={isAddCollectionModalOpen}
                    onClose={() => setIsAddCollectionModalOpen(false)}
                    onLineAddition={(collection) => {
                        const collectionKey = `${collection.collection.id}-${uuidv4()}`;

                        const groupHeader = {
                            type: "group",
                            group_id: collection.collection.id,
                            name: collection.collection.name,
                            module: "collection",
                            key: collectionKey
                        };
                
                        const groupProducts = collection.collection.products.map((prod) => ({
                            type: "group",
                            name: collection.collection.name,
                            module: "collection",
                            group_id: collection.collection.id,
                            key: collectionKey,
                            data: {
                                product: prod,
                                quantity: prod.quantity * collection.quantity,
                                unit_price: {
                                    converted: normalizePrice(prod.prices.purchase_price * (exchangeRate ?? 1)),
                                    original: prod.prices.purchase_price
                                },
                                tax: prod.tax
                            }
                        }));
                
                        append(groupHeader as PurchaseOrdersFormTyping["lines"][0]);
                
                        groupProducts.forEach(product => append(product as PurchaseOrdersFormTyping["lines"][0]));
                    }}
                />
            </div>
        </>
    );
}
