import { AppThunk } from "@redux/hooks";
import { isErrorWithMessage } from "@redux/api/query";
import { toast } from "react-toastify";
import i18n from "@/i18n";
import { resetImport, startImport } from "@redux/features/importExport/import/importSlice";

type ModulesWithImportSupport = "tripletex" | "product" | "poweroffice" | "twenty_four_seven_office";


/**
 * Thunk to perform an import for a specific module. It incorporates the necessary logic to start the import process and everything else will be held by the sockets listeners.
 * You must create imports logic ONLY here, otherwise talk to @nekear about this.
 * @param module
 * @param query
 */
export const performImport = <T>(module: ModulesWithImportSupport, query: () => Promise<T>): AppThunk => async (dispatch, getState) => {
    try {
        // Optional. Sockets listeners will catch the ImportStarted event and start the import process, however it will require some time and such manual start improves UX.
        // @see src/components/DashboardNav/components/ImportIndicators.tsx
        dispatch(startImport({ module }));

        return await query();
    } catch (e) {
        if (isErrorWithMessage(e)) {
            toast.error(e.message);
        } else {
            toast.error(i18n.t("general.responses.somethingWentWrong"));
        }
        console.log(`Error while performing an import for module [${module}]}:`, e);
        dispatch(resetImport());
        return Promise.reject(e);
    }
};