import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
    ArrayElementType,
    cn,
    fetchArrayReactively,
    fetchArrayServerside,
    genT,
    getCountryFlag,
    manualRequest,
    PredefinedTranslations,
    useFilters,
    useOrdering
} from "@helpers/utils";

import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";
import { ReactComponent as DeleteSVG } from "@assets/icons/ic_trash.svg";
import BaseAdminFilter from "@reusables/BaseAdminFilter";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseTable from "@reusables/BaseTable";
import BasePagination from "@reusables/BasePagination";
import BaseModal from "@reusables/Modals/BaseModal";
import BaseButton from "@reusables/BaseButton";
import { toast } from "react-toastify";
import { Location } from "@/types/general";
import BaseDropdown from "@reusables/dropdowns/BaseDropdownLegacy";
import { useGetCountriesQuery } from "@redux/api/internalApiSlice";
import { AbilityContext, Can } from "@/casl.config";
import { useAbility } from "@casl/react";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import SectionsCollapseTable
    from "@components/Dashboard/pages/SettingsPage/General/Locations/MainPage/parts/SectionsCollapseTable";
import { isErrorWithMessage } from "@redux/api/query";
import { useAppSelector } from "@redux/hooks";
import BaseIntegrationInject from "@reusables/BaseIntegrationInject";
import {
    getPowerOfficeIntegrationSettingsSelector,
    getTripletexIntegrationSettingsSelector
} from "@redux/features/auth/authSlice";

export default function LocationsMainPage() {
    const history = useHistory();
    const { t } = useTranslation("", { keyPrefix: "settings.general.locations" });

    const ability = useAbility(AbilityContext);

    // =========== DROPDOWNS =========== //
    const { data: countriesOptions = [], isLoading: countriesOptionsLoading } = useGetCountriesQuery();
    const [selectedCountry, setSelectedCountry] = useState<ArrayElementType<typeof countriesOptions>>();

    const {
        data: sectionsOptions,
        loading: sectionsOptionsLoading
    } = fetchArrayReactively<Location.Section.Root>({ route: "sub-location" });
    const [selectedSections, setSelectedSections] = useState<typeof sectionsOptions>([]);

    // =========== FILTERS =========== //
    const [searchingValue, setSearchingValue] = useState<string>();
    // Timer is needed to update searching string only after 1 second of user's inactivity after typing (reduces amount of calls to the server)
    const searchInputTimingCooldown = useRef<NodeJS.Timeout>();

    const { orderBy, setOrderBy } = useOrdering<Location.DTO.OrderBy>({ name: "id", type: "desc" });

    const filters = useFilters(() => ({
        search: searchingValue,
        country: selectedCountry?.name,
        sections: selectedSections.map(x => x.id)
    }), [searchingValue, selectedCountry, selectedSections]);

    const {
        loading: locationsLoading,
        data: locationsData,
        pagination: locationsPagination,
        call: fetchLocations
    } = fetchArrayServerside<Location.Root>({
        route: "location",
        debug: true,
        filters: filters,
        orderBy: orderBy,
        dynamicRefetch: true
    });

    const [activeLocationDeletion, setActiveLocationDeletion] = useState<Location.Root>();
    const [isLocationDeletionLoading, setIsLocationDeletionLoading] = useState<boolean>();

    const arePowerOfficeProjectsTurnedOn = useAppSelector(getPowerOfficeIntegrationSettingsSelector)?.projects_support;
    const areTripletexProjectsTurnedOn = useAppSelector(getTripletexIntegrationSettingsSelector)?.projects_support;

    return (
        <>
            <Can not I="locations.view" a="general">
                <NoPermissionBanner />
            </Can>

            <Can I="locations.view" a="general">
                {/* FILTERS BLOCK */}
                <BaseAdminFilter
                    permissionModule="general"
                    subPermission="locations.edit"
                    filterItems={
                        <div className="w-[363px]">
                            <BaseInputsGrid cols={1} gap={24}>
                                <BaseDropdown
                                    label={t("modals.filter.fields.country.label")}
                                    placeholder={t("modals.filter.fields.country.placeholder")}
                                    options={countriesOptions}
                                    value={selectedCountry}
                                    getter={{
                                        label: (opt) => opt.name,
                                        key: (opt) => opt.name,
                                        renderOption: (opt, icon) => (
                                            <div>
                                                <img src={getCountryFlag(opt.code)} width={20}
                                                     alt={opt.name + " flag"} />
                                                <span className="ml-[8px] grow">{opt.name}</span>
                                                {icon}
                                            </div>
                                        )
                                    }}

                                    onChange={(_, opt) => setSelectedCountry(opt)}

                                    autocomplete
                                    multiple
                                    brightLabel

                                    isLoading={countriesOptionsLoading}
                                />

                                <BaseDropdown
                                    label={t("modals.filter.fields.sections.label")}
                                    options={sectionsOptions}
                                    value={selectedSections}
                                    getter={{
                                        label: (opt) => opt.name,
                                        key: (opt) => opt.id
                                    }}

                                    onChange={newState => setSelectedSections(newState)}

                                    autocomplete
                                    multiple
                                    brightLabel

                                    emptyValue={genT(PredefinedTranslations.DropdownsALL)}

                                    isLoading={sectionsOptionsLoading}
                                />
                            </BaseInputsGrid>
                        </div>
                    }

                    handleSearch={val => {
                        if (searchInputTimingCooldown.current)
                            clearTimeout(searchInputTimingCooldown.current);

                        searchInputTimingCooldown.current = setTimeout(() => {
                            setSearchingValue(val);
                        }, 1000);
                    }}

                    handleFilter={(e) => console.log(e)}
                    handleCreate={() => history.push("/dashboard/settings/locations/new")}
                />

                <div className="levitation-extended mt-[32px]">
                    <BaseTable
                        data={locationsData}
                        columns={[
                            {
                                header: t("table.columns.locationName"),
                                getter: row => <div>
                                    <div className="bold-highlight">{row.store}</div>
                                    <div>{row.code}</div>
                                </div>,
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.country"),
                                getter: row => row.country,
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.city"),
                                getter: row => row.city ?? "-",
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.sections"),
                                getter: row => row.sections?.length ?? "-",
                                comparator: () => 0
                            },
                            {
                                visible: arePowerOfficeProjectsTurnedOn,
                                header: <div className="flex items-center space-x-2">
                                    {/* Added PowerOffice icon for simpler understanding what are "projects" */}
                                    <BaseIntegrationInject integration={"poweroffice"}>
                                        {
                                            (integration, isLoading) =>
                                                !isLoading ? <img src={integration?.imageUrl} width={20} height={20}
                                                                  alt={integration?.name} /> : null
                                        }
                                    </BaseIntegrationInject>
                                    <span>{t("table.columns.projects")}</span>
                                </div>,
                                getter: row => (row.integrations.poweroffice?.projects?.length ?? 0) > 0
                                    ? row.integrations.poweroffice!.projects.map(project => project.name).join(", ")
                                    : "-"
                            },
                            {
                                visible: areTripletexProjectsTurnedOn,
                                header: <div className="flex items-center space-x-2">
                                    {/* Added PowerOffice icon for simpler understanding what are "projects" */}
                                    <BaseIntegrationInject integration={"tripletex"}>
                                        {
                                            (integration, isLoading) =>
                                                !isLoading ? <img src={integration?.imageUrl} width={20} height={20}
                                                                  alt={integration?.name} /> : null
                                        }
                                    </BaseIntegrationInject>
                                    <span>{t("table.columns.projects")}</span>
                                </div>,
                                getter: row => (row.integrations.tripletex?.projects?.length ?? 0) > 0
                                    ? row.integrations.tripletex!.projects.map(project => project.name).join(", ")
                                    : "-"
                            },
                            {
                                visible: ability.can("locations.edit", "general") || ability.can("locations.edit", "general"),
                                header: <div className="text-right">{t("table.columns.actions")}</div>,
                                getter: (row, index, isCollapsed) => <div
                                    className={`space-x-[20px] text-tables-secondaryIcon text-right`}>
                                    <Can I="locations.edit" a="general">
                                        <EditSVG
                                            className={cn("hover:text-tables-highlightedIcon cursor-pointer", isCollapsed && "text-tables-highlightedIcon")}
                                            onClick={() => history.push(`/dashboard/settings/locations/${row.id}/edit`)}
                                        />
                                    </Can>
                                    <Can I="locations.edit" a="general">
                                        <DeleteSVG
                                            className={cn("hover:text-tables-highlightedIcon cursor-pointer", isCollapsed && "text-tables-highlightedIcon")}
                                            onClick={() => setActiveLocationDeletion(row)}
                                        />
                                    </Can>
                                </div>,
                                preventCollapsePropagation: true
                            }
                        ]}

                        collapse={{
                            fill: "#D9DAFD",
                            borderColor: "#B3B5EB",
                            content: mainRow => {
                                return <SectionsCollapseTable location={mainRow} />;
                            }
                        }}

                        alternate
                        isDataLoading={locationsLoading}
                        manualControls={{
                            ordering: (newOrdering) => {
                                if (newOrdering) {
                                    let name: Location.DTO.OrderBy | undefined;

                                    switch (newOrdering?.index) {
                                        case 0:
                                            name = "name";
                                            break;
                                        case 1:
                                            name = "country";
                                            break;
                                        case 2:
                                            name = "city";
                                            break;
                                        default:
                                            name = undefined;
                                    }

                                    if (name)
                                        setOrderBy({
                                            name,
                                            type: newOrdering.order
                                        });
                                } else {
                                    setOrderBy(undefined);
                                }
                            }
                        }}
                    />

                    {locationsPagination && <BasePagination
                        className="mt-[32px]"
                        config={locationsPagination}
                        disabled={locationsLoading}
                    />}
                </div>
            </Can>

            {/* DELETION MODAL */}
            <BaseModal
                isOpen={!!activeLocationDeletion}
                onClose={() => setActiveLocationDeletion(undefined)}
                width={700}
                padding="56px"
                isLoading={isLocationDeletionLoading}
            >
                <div className="space-y-[32px]">
                    <div
                        className="text-xl font-semibold text-center text-accent">{t("modals.confirmDelete.label")}</div>
                    <div className="text-center">{t("modals.confirmDelete.hint")} <span
                        className="bold-highlight">{activeLocationDeletion?.store}?</span></div>
                    <div className="grid grid-cols-2 gap-[16px]">
                        <BaseButton text="Cancel" size="md" primaryOutlined
                                    onClick={() => setActiveLocationDeletion(undefined)} />
                        <BaseButton text="Delete" size="md" onClick={() => {
                            if (activeLocationDeletion) {
                                manualRequest({
                                    route: `location/${activeLocationDeletion.id}`,
                                    method: "DELETE",
                                    onLoadingChange: (state) => {
                                        setIsLocationDeletionLoading(state);
                                    },
                                    then: () => {
                                        toast.success(t("modals.confirmDelete.responses.location.success"));
                                        fetchLocations();
                                    },
                                    catch: err => {
                                        if (isErrorWithMessage(err)) {
                                            toast.error(err.message);
                                        } else {
                                            toast.error(t("modals.confirmDelete.responses.location.error"));
                                        }
                                    },
                                    finally: () => void setActiveLocationDeletion(undefined)
                                });
                            }
                        }} />
                    </div>
                </div>
            </BaseModal>
        </>
    );
}