enum PriceReconversionStatus {
    IDLE = 'idle',
    ACTIVE = 'active',
    FINISHED = 'finished',
}

interface PriceReconversionStateBase {
    status: PriceReconversionStatus;
    module?: string;
}

interface PriceReconversionStateIdle extends PriceReconversionStateBase {
    status: PriceReconversionStatus.IDLE;
}

interface PriceReconversionStateActive extends PriceReconversionStateBase {
    status: PriceReconversionStatus.ACTIVE;
    progress?: { total: number; current: number }[];
}

interface PriceReconversionStateFinished extends PriceReconversionStateBase {
    status: PriceReconversionStatus.FINISHED;
    has_errors: boolean;
    data?: any;
}

type PriceReconversionState = PriceReconversionStateIdle | PriceReconversionStateActive | PriceReconversionStateFinished;

function isPriceReconversionStateActive(state: PriceReconversionState): state is PriceReconversionStateActive {
    return state.status === PriceReconversionStatus.ACTIVE;
}

function isPriceReconversionStateFinished(state: PriceReconversionState): state is PriceReconversionStateFinished {
    return state.status === PriceReconversionStatus.FINISHED;
}

/**
 * A utility selector to get the mean progress of all active reconversions
 * @param state active reconvert object
 */
const selectProgressMean = (state: PriceReconversionStateActive) => {
    const { progress } = state;

    if (!progress?.length) return 0;

    const totalProgress = progress.reduce((sum, p) => sum + p.current, 0);
    const totalTotal = progress.reduce((sum, p) => sum + p.total, 0);
    return totalTotal === 0 ? 0 : totalProgress / totalTotal;
}

export { isPriceReconversionStateActive, isPriceReconversionStateFinished,selectProgressMean, PriceReconversionStatus };
export type { PriceReconversionState, PriceReconversionStateActive, PriceReconversionStateFinished };