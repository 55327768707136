import BaseModal from "@reusables/Modals/BaseModal";
import { StockCount } from "@/types/general";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useMergeCountsMutation } from "@redux/features/stockCount/stockCountApi";
import _ from "lodash";
import BaseButton from "@reusables/BaseButton";
import { toast } from "react-toastify";
import BaseTable from "@reusables/BaseTable";
import { Box } from "@mui/material";

interface MergeModalProperties {
    isOpen: boolean;
    onClose: () => void;
    reports?: StockCount.Root[];
}

export default function MergeModal(props: MergeModalProperties) {
    const {t} = useTranslation("", {keyPrefix: "inventory.stockCount.modals.merge"});

    const [mergeReports, {isLoading: isMergeLoading}] = useMergeCountsMutation();

    const conflicts = useMemo(() => {
        if (!props.isOpen || !props.reports) {
            return undefined;
        }

        // Flatten all report entries into a single list
        const allEntries = props.reports.flatMap(report => report.entries.map(entry => ({
            ...entry,
            report: {
                id: report.id,
                worker: report.user
            },
        })));

        // Group entries by product ID
        const entriesByProduct = _.values(_.mapValues(
            _.groupBy(allEntries, "product.id"),
            entries => ({
                product: entries[0].product,
                entries: entries.map(entry => _.omit(entry, "product"))
            })
        ));

        // For each product, group its entries by location (store ID and section ID)
        const entriesByProductByLocation = _.map(entriesByProduct, product => {
            return {
                product: product.product,
                locations: _.values(_.mapValues(
                    _.groupBy(product.entries, entry => _.pick(entry, ["store.id", "section.id"])),
                    entries => ({
                        location: {
                            store: entries[0].store,
                            section: entries[0].section
                        },
                        entries: entries.map(entry => _.omit(entry, ["store", "section"]))
                    })
                ))
            };
        });

        // Identify conflicts (where more than one entry exists for the same location)
        const conflictsByLocation = _.map(entriesByProductByLocation, product => {
            return {
                ...product,
                locations: _.filter(product.locations, location => location.entries.length > 1)
            }
        });

        // Filter out products with no conflicts
        const conflictsList = conflictsByLocation.filter(product => product.locations.length > 0);

        // Extract IDs of all conflicting entries
        const conflictEntryIds = _.uniq(_.flatMap(conflictsList, product =>
            _.flatMap(product.locations, location => location.entries.map(entry => entry.report.id))
        ));

        // Return the structured conflicts data
        return {
            list: conflictsList,
            reportsIds: conflictEntryIds
        };
    }, [props.reports, props.isOpen]);


    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={1012}
            padding="56px"
            isLoading={isMergeLoading}
        >
            <div className="space-y-[16px]">
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                <div className="text-center text-accent font-thin">
                    {
                        conflicts?.reportsIds.length ?
                            <>
                                <div>
                                    {
                                        <Trans
                                            i18nKey="negative.subheading" t={t}
                                            components={{
                                                bold: <span className="font-semibold"/>,
                                            }}
                                            values={{
                                                reports: conflicts.reportsIds.sort((a, b) => a - b).join(", "),
                                            }}
                                        />
                                    }
                                </div>
                                <div>
                                    <Trans i18nKey="negative.additionalText" t={t}/>
                                </div>
                            </>
                            :
                            <Trans
                                i18nKey="positive.subheading" t={t}
                                components={{
                                    bold: <span className="font-semibold"/>,
                                }}
                                values={{
                                    reports: _.join(props.reports?.map(report => report.id), ", "),
                                }}
                            />
                    }
                </div>
                <div>
                    {!!conflicts?.list &&
                        conflicts.list.map(groupByProduct =>
                            <div key={groupByProduct.product.id} className="space-y-[8px]">
                                <div className="text-accent text-lg font-semibold">
                                    {groupByProduct.product.name}
                                </div>
                                <div className="pl-2" style={{borderLeft: "1px solid #D9D6DA"}}>
                                    {
                                        groupByProduct.locations.map((groupByLocation, index) =>
                                            <div key={index} className="space-y-[8px]">
                                                <Box
                                                    className="flex text-accent text-md space-x-[8px] relative"
                                                >
                                                    <div className="flex justify-center items-center border !border-[#D9D6DA] rounded-full h-[24px] w-[24px]">{index+1}</div>
                                                    <span className="font-semibold">
                                                        {groupByLocation.location.section ?
                                                            _.join([groupByLocation.location.store.name, groupByLocation.location.section?.name], " - ")
                                                            : groupByLocation.location.store.name
                                                        }
                                                    </span>
                                                </Box>
                                                <BaseTable
                                                    columns={[
                                                        // Report id
                                                        {
                                                            header: t("table.columns.0"),
                                                            getter: (row) => "#" + row.report.id,
                                                        },
                                                        // Worker
                                                        {
                                                            header: t("table.columns.1"),
                                                            getter: (row) => row.report.worker.first_name + " " + row.report.worker.last_name,
                                                        },
                                                        // Quantity in stock
                                                        {
                                                            header: t("table.columns.2"),
                                                            getter: (row) => row.system_quantity,
                                                        },
                                                        // Quantity counted
                                                        {
                                                            header: t("table.columns.3"),
                                                            getter: (row) => row.counted_quantity,
                                                        },
                                                    ]}
                                                    data={groupByLocation.entries}
                                                    size="small"
                                                    headerSx={{
                                                        backgroundColor: "#F7F7F7",
                                                        "& .MuiTableCell-head": {
                                                            fontWeight: 500,
                                                            color: "#3C3769"
                                                        }
                                                    }}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="grid grid-cols-2 gap-[16px]">
                <BaseButton text={t("buttons.cancel")} size="md" primaryOutlined onClick={props.onClose}/>
                    <BaseButton
                        text={t("buttons.submit")}
                        size="md"
                        onClick={() => {
                            if (props.reports) {
                                mergeReports({
                                    merging: props.reports.map(report => report.id)
                                })
                                    .unwrap()
                                    .then(() => {
                                        toast.success(t("responses.success"));
                                    })
                                    .catch((e) => {
                                        console.error(e);

                                        toast.error(t("responses.error"));
                                    })
                                    .finally(() => props.onClose());
                            }
                        }}
                    />
                </div>
            </div>
        </BaseModal>
    )
}