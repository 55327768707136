import * as React from "react";
import CheckIcon from "@mui/icons-material/Check";
import {BaseDropdownProperties} from "@reusables/dropdowns/BaseDropdown";

export default function ListOption<T>(props: {
    option: T,
    getter: BaseDropdownProperties<T>["getter"],
    optionProps: React.HTMLAttributes<HTMLLIElement>,
    style?: React.CSSProperties
}) {
    return (
        <li
            style={props.style}
            {...props.optionProps}
            key={props.getter.key(props.option)}
        >
            {
                props.getter.renderOption?.(props.option, <CheckIcon fontSize="small"
                                                                     className="selected-svg"/>)
                ??
                <div>
                    <div className="grow">{props.getter.label(props.option)}</div>
                    <CheckIcon fontSize="small" className="selected-svg"/>
                    {
                        !!props.getter.caption &&
                        <span
                            className="unselected-caption text-end text-lightGreen-500 font-thin truncate whitespace-nowrap hover:whitespace-normal"
                        >
                            {props.getter.caption(props.option)}
                        </span>
                    }
                </div>
            }
        </li>
    )
}
