import BaseDropdown, { adaptInfiniteLoader } from "@reusables/dropdowns/BaseDropdown";
import BaseInput from "@reusables/BaseInput";
import BaseButton from "@reusables/BaseButton";
import BaseModal from "@reusables/Modals/BaseModal";
import React, { useEffect, useState } from "react";
import { productsApi } from "@redux/features/products/productsApi";

import { ReactComponent as PercentageIcon } from "@assets/icons/ic_percentage.svg";
import { z } from "zod";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import { useTranslation } from "react-i18next";
import { cn, normalizePrice, toastError } from "@helpers/utils";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { isErrorWithMessage } from "@redux/api/query";
import { toast } from "react-toastify";
import { Customer } from "@/types/general";
import { useGetModuleCustomerQuery } from "@redux/features/customers/customersApi";
import dayjs from "dayjs";
import DiscountCard from "./DiscountCard";
import { Alert, Collapse, Fade, Stack } from "@mui/material";
import i18n from "i18next";
import BaseInfiniteLoader from "@reusables/dropdowns/BaseInfiniteLoader";
import { useGetModuleTaxesQuery } from "@redux/features/taxes/taxesApi";
import { SaleOrdersFormTyping, SOProduct } from "../../types";
import BaseTooltip from "@reusables/BaseTooltip";
import { HelpCircle } from "lucide-react";
import BaseLabel from "@reusables/BaseLabel";
import BOMSelector from "./components/BOMSelector";
import BaseSwitch from "@reusables/BaseSwitch";

const formScheme = z.object({
    id: z.number().nullish(),
    add_more: z.boolean(),
    comment: z.string().nullish(),
    product: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string(),
        in_stock: z.number().nullish(),
        prices: z.object({
            selling_price: z.number()
        }),
        category: z.object({
            id: z.number(),
            name: z.string(),
            code: z.string().nullish()
        }).nullish(),
        has_bom: z.boolean(),
        is_service: z.boolean()
    }),
    quantity: z.coerce.number().positive(),
    unit_price: z.coerce.number().min(0).nullish(),
    discount: z.coerce.number().min(0).max(100).optional(),
    tax: z.object({
        id: z.number(),
        name: z.string().nullish(),
        rate: z.number()
    }).nullish(),
    availableQuantity: z.number().nullish(),
    should_use_bom: z.boolean(),
    selected_bom: z.object({
        id: z.number(),
        name: z.string(),
        components: z.array(
            z.object({
                product: z.object({
                    id: z.number(),
                    name: z.string(),
                    code: z.string(),
                    in_stock: z.number().nullish(),
                    prices: z.object({
                        selling_price: z.number()
                    }),
                    is_service: z.boolean()
                }),
                quantity: z.coerce.number()
            })
        )
    })
        .optional()
})
    .superRefine((scheme, ctx) => {
        if (scheme.should_use_bom && !scheme.selected_bom) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                params: {
                    i18n: "sales.general.modals.addProduct.bom.validation.noBOMSelected"
                },
                path: ["selected_bom"]
            });
        }
        return scheme;
    });

export type AddProductFormSchemeTyping = z.infer<typeof formScheme>;

// Define a type for a discount option
export interface DiscountOption {
    id: string;
    group?: string;
    name?: string;
    discount: number | undefined;
    salePrice?: number;
    calculated_price: number | undefined;
    min_quantity?: number;
    valid_from?: dayjs.Dayjs;
    valid_to?: dayjs.Dayjs | null;
}

export interface AddProductModalProps {
    isOpen: boolean;
    onClose: () => void;

    onLineMutation: (newOrder: AddProductFormSchemeTyping & {
        in_stock?: number | null;
        min_sale_quantity?: number | null
    }) => void;
    customer?: Customer.Root;
    selectedLine?: SaleOrdersFormTyping["lines"][0];
}

export default function AddProductModal({
                                            isOpen,
                                            onClose,
                                            onLineMutation,
                                            customer,
                                            selectedLine
                                        }: AddProductModalProps): JSX.Element {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.general.orders.modals.addProduct" });

    const dispatch = useAppDispatch();
    const baseCurrency = useAppSelector(state => state.auth.user?.company.currency.symbol);

    const { data: taxesOptions, isLoading: isTaxesOptionsLoading } =
        useGetModuleTaxesQuery("sale", { skip: !isOpen });

    // State to store the discount options and the selected discount
    const [discountOptions, setDiscountOptions] = useState<DiscountOption[]>([]);
    const [selectedDiscount, setSelectedDiscount] = useState<DiscountOption>();

    const [productsOptions, setProductsOptions] = useState<SOProduct[]>([]);
    const [isProductLazyLoadingFetching, setIsProductLazyLoadingFetching] = useState(false);

    const { data: selectedCustomer, isLoading: isSelectedCustomerLoading } = useGetModuleCustomerQuery(
        { module: "sale", id: customer?.id as number },
        { skip: !customer }
    );

    const [isExtendedProductGetLoading, setIsExtendedProductGetLoading] = React.useState(false);

    const { control, reset, ...form } = useForm<AddProductFormSchemeTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            should_use_bom: false,
            add_more: false
        }
    });

    useEffect(() => {
        if (form.formState.isValid && !form.formState.isValidating) {
            reset({
                add_more: form.getValues("add_more"),
                should_use_bom: false
            });
        }
    }, [form.formState.submitCount, reset]);

    const selectedProduct = useWatch({
        control,
        name: "product"
    });

    const quantity = useWatch({
        control,
        name: "quantity"
    });

    const hasBOM = selectedProduct?.has_bom ?? false;

    const shouldUseBOM = useWatch({
        control,
        name: "should_use_bom"
    });

    useEffect(() => {
        if (isOpen) {
            reset();

            if (selectedLine && selectedLine.data) {
                form.setValue("id", selectedLine.id);
                form.setValue("comment", selectedLine.data.comment);
                form.setValue("product", selectedLine.data.product);
                form.setValue("quantity", selectedLine.data.quantity);
                form.setValue("unit_price", selectedLine.data.unit_price);
                form.setValue("discount", selectedLine.data.discount);
                form.setValue("tax", selectedLine.data.tax);
            }
        }
    }, [isOpen]);

    // Helper function to handle the selection of a discount
    const handleDiscountSelect = (value: DiscountOption): void => {
        // Check if the clicked discount is already selected (i.e. the user wants to deselect it)
        if (value.id === selectedDiscount?.id) {
            setSelectedDiscount(undefined);
            form.setValue("discount", undefined);
            form.setValue("unit_price", selectedProduct?.prices?.selling_price);
            // form.setValue("quantity", form.getValues("quantity"));
        } else {
            // Set the selected discount and update the form values
            setSelectedDiscount(value);
            form.setValue("discount", value.discount);
            form.setValue("unit_price", value.salePrice ?? selectedProduct?.prices?.selling_price);
            // form.setValue("quantity", value.min_quantity ?? form.getValues("quantity"));
        }
    };

    // Helper function to check if the current date is within the valid date range of the discount using dayjs
    function isValidDiscountDate(
        valid_from: dayjs.Dayjs | undefined,
        valid_to: dayjs.Dayjs | undefined,
        currentDate: dayjs.Dayjs
    ): boolean {
        const startDate = valid_from ? dayjs(valid_from) : null;
        const endDate = valid_to ? dayjs(valid_to) : null;
    
        // Check if the discount is valid for the current date
        const isCurrentValid = (!startDate || currentDate.isAfter(startDate)) &&
                               (!endDate || currentDate.isBefore(endDate));
    
        // Check if the discount starts in the future but within the next 30 days
        const isFutureValid = startDate && startDate.isAfter(currentDate) && startDate.diff(currentDate, "days") <= 30;
    
        return !!isCurrentValid || !!isFutureValid;
    }

    // Helper function to determine the priority discount option
    function determinePriorityDiscount(discountOptions: DiscountOption[], quantity: number) {
        if (!discountOptions.length) return null;

        // Filter out discounts where quantity does not meet min_quantity
        const validDiscounts = discountOptions.filter(
            (discount) => !discount.min_quantity || quantity >= discount.min_quantity
        );

        // Select the first valid option as the default
        return validDiscounts.length > 0 ? validDiscounts[0] : null;
    }

    useEffect(() => {
        if (selectedLine) return;

        // If the selected product or customer changes, update the discount options
        // Basically, we set the discount based on the next ierarchy of priority(From highest to lowest):
        // 1. Prices & Discounts
        // 2. General Customer Discount(Settings tab)
        // 3. Customer Groups
        const today = dayjs();
        let discountOptions: DiscountOption[] = [];
        const groupDiscountOptions: DiscountOption[] = [];

        // Fetch prices & discounts if available for the selected product
        // Checks if there's a discount directly associated with the selected product
        const productDiscountInfo = selectedCustomer?.products?.find(p => p.product?.id === selectedProduct?.id);
        const productDiscount = productDiscountInfo &&
        isValidDiscountDate(productDiscountInfo.valid_from, productDiscountInfo.valid_to, today) ? productDiscountInfo : undefined;

        const categoryDiscountInfo = selectedCustomer?.category_discounts?.find(c => c.category?.id === selectedProduct?.category?.id);
        const categoryDiscount = categoryDiscountInfo &&
        isValidDiscountDate(categoryDiscountInfo.valid_from, categoryDiscountInfo.valid_to, today) ? categoryDiscountInfo : undefined;

        // If there's a discount directly associated with the selected product, add it to the discount options
        if (productDiscount) {
            discountOptions.push({
                id: "pricesAndDiscounts-product",
                name: "Product Discount",
                group: "pricesAndDiscounts",
                discount: productDiscount.discount ?? 0,
                salePrice: normalizePrice(productDiscount.sale_price),
                calculated_price: productDiscount.discount && normalizePrice(productDiscount.sale_price) * (1 - productDiscount.discount / 100),
                min_quantity: productDiscountInfo?.min_quantity ?? undefined,
                valid_from: productDiscount.valid_from,
                valid_to: productDiscount.valid_to
            });
        }

        // If there's a discount associated with the product's category, add it to the discount options
        if (categoryDiscount) {
            discountOptions.push({
                id: "pricesAndDiscounts-category",
                name: "Category Discount",
                group: "pricesAndDiscounts",
                discount: categoryDiscount.discount ?? 0,
                calculated_price: categoryDiscount.discount && normalizePrice(selectedProduct?.prices?.selling_price) * (1 - categoryDiscount.discount / 100),
                min_quantity: categoryDiscountInfo?.min_quantity ?? undefined,
                valid_from: categoryDiscount.valid_from,
                valid_to: categoryDiscount.valid_to
            });
        }

        // If the customer has a general discount, add it to the discount options
        if (selectedCustomer?.discount) {
            discountOptions.push({
                id: "customerDiscount",
                discount: selectedCustomer.discount,
                calculated_price: normalizePrice(selectedProduct?.prices?.selling_price) * (1 - selectedCustomer.discount / 100),
                min_quantity: undefined
            });
        }

        // If the customer has a group discount, add it to the discount options
        selectedCustomer?.groups?.forEach(group => {
            group.products?.filter(product => product.product?.id === selectedProduct?.id).forEach(product => {
                if (isValidDiscountDate(product.valid_from, product.valid_to, today)) {
                    groupDiscountOptions.push({
                        id: `groupDiscount-${group.id}`,
                        name: `${group.name}`,
                        discount: group.discount,
                        salePrice: normalizePrice(product.sale_price),
                        calculated_price: normalizePrice(product.sale_price) * (1 - group.discount / 100),
                        min_quantity: undefined,
                        valid_from: product.valid_from,
                        valid_to: product.valid_to
                    });
                }
            });
        });

        // Sort the group discounts by price
        groupDiscountOptions.sort((a, b) => (a.calculated_price ?? 0) - (b.calculated_price ?? 0));

        // Merge sorted group discounts back into the main discountOptions array
        // This is done to ensure that the group discounts are sorted by price and are displayed first
        discountOptions = [...discountOptions, ...groupDiscountOptions];

        // Determine the pre-selected option based on priority
        const selectedDiscountOption = determinePriorityDiscount(discountOptions, form.getValues("quantity") ?? 0);

        // If there's a selected discount option, update the form values
        if (selectedDiscountOption) {
            form.setValue("discount", selectedDiscountOption.discount);
            form.setValue("unit_price", selectedDiscountOption.salePrice ?? selectedProduct?.prices?.selling_price);
            // form.setValue("quantity", selectedDiscountOption.min_quantity ?? form.getValues("quantity"));
        } else {
            // If there's no selected discount option, reset the form values
            form.setValue("discount", undefined);
            form.setValue("unit_price", selectedProduct?.prices?.selling_price);
            // form.setValue("quantity", form.getValues("quantity"));
        }

        // Update component state with the finalized list of discount options and the pre-selected discount
        setDiscountOptions(discountOptions);
        setSelectedDiscount(selectedDiscountOption ?? undefined);
    }, [selectedProduct, selectedCustomer]);


    // Disabling the discount selection if the quantity is less than the minimum quantity required for the discount
    useEffect(() => {
        console.log("Line 350");
        if (!selectedProduct || !discountOptions.length) return;

        // Validate the currently selected discount
        const validDiscounts = discountOptions.filter(
            (discount) => !discount.min_quantity || quantity >= discount.min_quantity
        );

        // Check if the current selected discount is still valid
        const isSelectedDiscountValid = validDiscounts.some(
            (discount) => discount.id === selectedDiscount?.id
        );

        if (!isSelectedDiscountValid) {
            const newDiscount = determinePriorityDiscount(validDiscounts, quantity);
            setSelectedDiscount(newDiscount ?? undefined);

            form.setValue("discount", newDiscount?.discount ?? undefined);
            form.setValue("unit_price", newDiscount?.salePrice ?? selectedProduct?.prices?.selling_price);
        }
    }, [quantity, discountOptions, selectedProduct]);

    const onSubmit = form.handleSubmit(data => {
        setIsExtendedProductGetLoading(true);
        dispatch(productsApi.endpoints.getModuleProduct.initiate({ module: "sale", id: data.product.id }))
            .unwrap()
            .then(product => {
                const locations = product.locations ?? [];
                const inStock = locations.reduce((acc, curr) => acc + (curr.in_stock ?? 0), 0);

                onLineMutation({
                    ...data,
                    in_stock: inStock,
                    min_sale_quantity: product.min_sale_quantity
                });

                if (!data.add_more) {
                    onClose();
                }
            }).catch(e => {
            onLineMutation(data);
            toastError(e);
        }).finally(() => {
            setIsExtendedProductGetLoading(false);
        });
    });

    function handleClose() {
        onClose();
    }

    return (
        <>
            <BaseModal
                isOpen={isOpen}
                onClose={handleClose}
                isLoading={isExtendedProductGetLoading || isSelectedCustomerLoading}
                width={900}
                padding="56px">
                <FormProvider control={control} {...form} reset={reset}>
                    <form className="space-y-[32px]" onSubmit={onSubmit}>
                        <div
                            className="modal-title">{selectedLine ? selectedLine.data?.product.name : t("heading")}</div>
                        <BaseInputsGrid cols={2}>
                            <Controller
                                name="product"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <BaseInfiniteLoader
                                        fetch={(search, page, limit) => {
                                            setIsProductLazyLoadingFetching(true);
                                            dispatch(productsApi.endpoints.getModuleProductsFull.initiate(
                                                    {
                                                        filters: {
                                                            search
                                                        },
                                                        pagination: {
                                                            page,
                                                            limit
                                                        },
                                                        module: "sale"
                                                    },
                                                    {
                                                        subscribe: false
                                                    }
                                                )
                                            ).unwrap()
                                                .then(res => {
                                                    setProductsOptions(res?.payload ?? []);
                                                })
                                                .catch(e => {
                                                    if (isErrorWithMessage(e)) {
                                                        toast.error(e.message);
                                                    } else {
                                                        toast.error(i18n.t("general.responses.somethingWentWrong"));
                                                    }

                                                    console.error("An error occurred in the products lazy loader", e);
                                                })
                                                .finally(() => {
                                                    setIsProductLazyLoadingFetching(false);
                                                });
                                        }}
                                        limit={100}
                                        result={productsOptions}
                                        isLoading={isProductLazyLoadingFetching}
                                        skip={!!selectedLine}
                                    >
                                        {
                                            (infinity) => (
                                                <>
                                                    <BaseDropdown
                                                        {...field}
                                                        {...fieldState}

                                                        {...adaptInfiniteLoader(infinity)}
                                                        label={t("fields.product.label") + " *"}
                                                        placeholder={t("fields.product.placeholder")}
                                                        getter={{
                                                            label: opt => opt.name,
                                                            key: opt => opt.id,
                                                            caption: opt => opt.code
                                                        }}
                                                        disabled={!!selectedLine}

                                                        virtualize
                                                        autocomplete
                                                    />
                                                </>
                                            )
                                        }
                                    </BaseInfiniteLoader>
                                )}
                            />

                            <Controller
                                name="quantity"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <BaseInput
                                        {...field}
                                        error={fieldState.error}
                                        label={t("fields.quantity.label")}
                                        placeholder={t("fields.quantity.placeholder")}
                                    />
                                )}
                            />

                            <Controller
                                name="discount"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <BaseInput
                                        {...field}
                                        error={fieldState.error}
                                        label={t("fields.discount.label")}
                                        placeholder={t("fields.discount.placeholder")}
                                        type="number"
                                        icon={{ right: { el: <PercentageIcon />, offset: "38px" } }}
                                    />
                                )}
                            />

                            <Controller
                                name="tax"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <BaseDropdown
                                        {...field}
                                        error={fieldState.error}

                                        label={t("fields.tax.label")}
                                        placeholder={t("fields.tax.placeholder")}
                                        options={taxesOptions}
                                        getter={{
                                            key: (opt) => opt.id,
                                            label: (opt) => `${opt.rate}%`,
                                            caption: (opt) => opt.name ?? ""
                                        }}
                                        isLoading={isTaxesOptionsLoading}
                                    />

                                )}
                            />

                            <Fade in={!shouldUseBOM}>
                                <div>
                                    <Controller
                                        name="unit_price"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <BaseInput
                                                {...field}
                                                error={fieldState.error}
                                                label={t("fields.unitPrice.label")}
                                                placeholder={t("fields.unitPrice.placeholder")}
                                                type="number"
                                                step="any"
                                                icon={{
                                                    right:
                                                        {
                                                            el:
                                                                <span
                                                                    className="w-max h-max text-purple-400 font-thin">{baseCurrency}</span>,
                                                            offset: "38px"
                                                        }
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </Fade>

                            <Fade in={!shouldUseBOM}>
                                <div>
                                    <Controller
                                        name="comment"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <div className="flex flex-col">
                                                <Stack direction={"row"} alignItems={"center"} spacing={1}
                                                       className={"pointer-events-auto"}>
                                                    <BaseLabel>{t("fields.comment.label")}</BaseLabel>
                                                    <BaseTooltip title={t("fields.comment.whatIsComment") + ""}
                                                                 placement={"top"}>
                                                        <HelpCircle className="mb-2" size={16} color={"#686868"} />
                                                    </BaseTooltip>
                                                </Stack>
                                                <BaseInput
                                                    {...field}
                                                    error={fieldState.error}
                                                    placeholder={t("fields.comment.placeholder")}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                            </Fade>
                        </BaseInputsGrid>
                        {
                            selectedProduct && selectedCustomer && (
                                <Fade in={!!selectedProduct} timeout={500} key={selectedProduct.id}>
                                    <div>
                                        <DiscountCard
                                            discounts={discountOptions}
                                            onDiscountSelect={handleDiscountSelect}
                                            selectedDiscount={selectedDiscount}
                                        />
                                    </div>
                                </Fade>
                            )
                        }
                        {!selectedLine && (
                            <>
                                <Collapse in={!!form.formState.errors.selected_bom}>
                                    <Alert severity={"error"}>{t("bom.validation.noBOMSelected")}</Alert>
                                </Collapse>

                                <Stack direction={"row"}>
                                    <div
                                        className={cn(
                                            `grow border-[1px] border-solid rounded-lg bg-white transition-all overflow-hidden`,
                                            form.formState.errors.selected_bom ? `border-inputs-border-error` : `border-inputs-border-default`,
                                            hasBOM ? "hover:border-inputs-border-focused" : "pointer-events-none opacity-30"
                                        )}
                                    >
                                        <Controller
                                            control={control}
                                            name="should_use_bom"
                                            render={({ field }) => (
                                                <div
                                                    className={cn(`flex flex-row items-center justify-between p-3`, hasBOM && "cursor-pointer transition-all hover:bg-purple-100 text-accent")}
                                                    onClick={() => {
                                                        if (hasBOM) field.onChange(!field.value);
                                                    }}
                                                >
                                                    <Stack direction={"row"} alignItems={"center"} spacing={1}
                                                           className={"pointer-events-auto"}>
                                                        <span>{t("bom.label")}</span>
                                                        <BaseTooltip title={t("bom.whatIsBOM") + ""} placement={"top"}>
                                                            <HelpCircle size={16} color={"#686868"} />
                                                        </BaseTooltip>
                                                    </Stack>
                                                    <BaseSwitch
                                                        checked={field.value}
                                                        className={cn(
                                                            "focus:outline-offset-1 focus:color-primary-500 focus:shadow-outline-primary-500 focus:border-primary-500 focus:ring-primary-500 focus:ring-offset-primary-500"
                                                        )}
                                                    />
                                                </div>
                                            )}
                                        />
                                        {!!selectedProduct && (
                                            <Collapse in={shouldUseBOM}>
                                                <BOMSelector active={shouldUseBOM} product_id={selectedProduct.id}
                                                             control={control} />
                                            </Collapse>
                                        )}
                                    </div>
                                </Stack>
                            </>
                        )}
                        <div className="flex flex-column items-end">
                            <Controller
                                name={"add_more"}
                                control={control}
                                render={({ field }) => (
                                    <div
                                        className="inline-flex items-center space-x-2 mb-2 user-select-none text-accent"
                                        onClick={() => field.onChange(!field.value)}
                                    >
                                        <BaseSwitch checked={field.value ?? false} />
                                        <span>{t("addMore")}</span>
                                    </div>
                                )}
                            />
                            <BaseButton
                                text={selectedLine ? t("buttons.save") : t("buttons.add")}
                                size="md"
                                className="w-full"
                            />
                        </div>
                    </form>
                </FormProvider>
            </BaseModal>
        </>
    );
}