import React, { useCallback, useState } from "react";

import BaseTable from "@reusables/BaseTable";
import { Menu, Pagination } from "@mui/material";
import BaseTooltip from "@reusables/BaseTooltip";
import { Link, useHistory } from "react-router-dom";
import BaseAdminFilter from "@reusables/BaseAdminFilter";

import customParseFormat from "dayjs/plugin/customParseFormat";
import BaseDatepicker from "@reusables/BaseDatepickerLegacy";
import { DatepickerRangeContext } from "@reusables/BaseDatepickerLegacy/context";

import dayjs from "dayjs";
import {
    calculateOrderPrice,
    formatPriceWithCurrency,
    genT,
    PredefinedTranslations,
    removeEmpty,
    useDatesRangeFilter,
    useOrdering,
    usePagination
} from "@helpers/utils";
import { OrderStatus, Product, SaleOrder } from "@/types/general";
import { useTranslation } from "react-i18next";
import { useGetModuleCustomersQuery } from "@redux/features/customers/customersApi";
import { AbilityContext, Can } from "@/casl.config";
import { useAbility } from "@casl/react";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import { BaseTinyActionIcon, TinyActionIconType } from "@components/reusables/BaseTinyActionIcon";
import { useGetSaleOrdersFullQuery } from "@redux/features/sales/orders/salesOrdersApi";
import OrderDeletionModal from "./components/modals/DeleteOrder";
import { ReactComponent as FastCartSVG } from "@assets/icons/ic_fast_cart.svg";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { toast } from "react-toastify";
import {
    CancelOrderAction,
    formRefillModalLines,
    isOrderDisabled,
    statusOptions
} from "@components/Dashboard/pages/Sales/Orders/utils";
import { RefillModalData } from "@components/Dashboard/pages/Sales/Orders/types";
import LowStockRefillModal from "./components/modals/LowStockRefillModal";
import _ from "lodash";
import SaleOrdersExportModal from "@components/Dashboard/pages/Sales/Orders/components/modals/SaleOrdersExportModal";
import { productsApi } from "@redux/features/products/productsApi";
import BaseInfiniteLoader from "@reusables/dropdowns/BaseInfiniteLoader";
import { isErrorWithMessage } from "@redux/api/query";
import i18n from "@/i18n";
import BaseDropdown, { adaptInfiniteLoader } from "@reusables/dropdowns/BaseDropdown";
import BasePermissionBlocker from "@reusables/BasePermissionBlocker";
import BaseOrderStatusBadge from "@/components/reusables/BaseOrderStatusBadge";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseSyncedFromSourcesBadge from "@reusables/BaseSyncedFromSourcesBadge";
import OrderCancellationModal from "./components/modals/CancellationOrderModal";

dayjs.extend(customParseFormat);

export default function SalesOrdersPage() {
    const history = useHistory();
    const { t } = useTranslation("", { keyPrefix: "sales.orders.main" });

    const baseCurrency = useAppSelector(state => state.auth.user?.company.currency);

    const dispatch = useAppDispatch();

    const ability = useAbility(AbilityContext);

    // ===> ACTIONS DOTS CONFIGURATION <===
    const [actionsAnchor, setActionsAnchor] = useState<HTMLDivElement>();
    const [actionEntity, setActionEntity] = useState<SaleOrder.Root>();

    // MODALS RELATED
    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState<boolean>(false);
    const [isCancellationModalOpen, setIsCancellationModalOpen] = useState<boolean>(false);

    // FILTERS RELATED
    const [searchingValue, setSearchingValue] = useState<string>();
    const searchInputDebounce = useCallback(_.debounce(setSearchingValue, 1000), [setSearchingValue]);

    const { data: customersOptions, isLoading: customersOptionsLoading } = useGetModuleCustomersQuery("sale");
    const [selectedCustomers, setSelectedCustomers] = useState<typeof customersOptions>([]);

    // Products options for dropdown filter (lazy loading)
    const [productsOptions, setProductsOptions] = useState<Product.Slim[]>([]);
    // Selected products for filtering by products (dropdown)
    const [selectedProducts, setSelectedProducts] = useState<Product.Slim[]>();
    // Selected status for filtering by status (dropdown)
    const [selectedStatus, setSelectedStatus] = useState<{ label: string; value: OrderStatus.Base; }[]>([]);

    const { datesRange, setDatesRange, filterAdaptedDatesRange } = useDatesRangeFilter();

    const pagination = usePagination({ page: 1, limit: 8 });

    const { orderBy, setOrderBy } = useOrdering<SaleOrder.DTO.OrderBy>({ name: "id", type: "desc" });


    const filters: SaleOrder.DTO.Filters = removeEmpty({
        search: searchingValue,
        customers: selectedCustomers?.map(x => x.id),
        products: selectedProducts?.map(x => x.id),
        dates_range: filterAdaptedDatesRange,
        status: selectedStatus?.map(x => x.value)
    });

    const { data: ordersResponse, isFetching: areOrdersLoading } = useGetSaleOrdersFullQuery({
        filters,
        orderBy: orderBy,
        pagination: {
            page: pagination.page,
            limit: pagination.limit
        }
    });

    const handlePOCreation = async (saleOrder: SaleOrder.Root) => {
        const linesToRefill = formRefillModalLines(saleOrder.lines);

        // This data will be transferred to the purchase order creation page via redux
        if (linesToRefill?.length) {
            setRefillModalData({
                // Order is needed for the RefillModal to allow filling needed data in the purchase order + referencing the parent SO.
                order: {
                    id: saleOrder.id,
                    code: saleOrder.code
                },
                lines: linesToRefill
            });
            setIsRefillModalOpen(true);
        } else {
            toast.warn(t("warnings.createPOAllInStock"));
        }
    };

    const [isRefillModalOpen, setIsRefillModalOpen] = useState<boolean>(false);
    const [refillModalData, setRefillModalData] = useState<RefillModalData>();


    const [isExportModalOpen, setIsExportModalOpen] = useState(false);

    const [isProductLazyLoadingFetching, setIsProductLazyLoadingFetching] = useState(false);

    const handleTotalPrice = (row: SaleOrder.Root) => {
        if (row.lines.length) {
            const total = row.lines.reduce((acc, line) => {
                if (line.type === "line") {
                    const { quantity, unit_price, discount, tax } = line.data;
                    return acc + calculateOrderPrice(quantity, unit_price, discount ?? 0) * (1 + (tax?.rate ?? 0) / 100);
                } else if (line.type === "group") {
                    return acc + line.data!.reduce((groupAcc, groupLine) => {
                        const { quantity, unit_price, discount, tax } = groupLine;
                        return groupAcc + calculateOrderPrice(quantity, unit_price, discount ?? 0) * (1 + (tax?.rate ?? 0) / 100);
                    }, 0);
                }
                return acc;
            }, 0);
            return formatPriceWithCurrency(total, baseCurrency);
        } else {
            return "-";
        }
    };

    const handleCollapseContent = (rowRelated: SaleOrder.Root) => {
        // Combine lines and group items, while expanding group items into individual lines
        const combinedItems = rowRelated.lines.flatMap(line => {
            if (line.type === "line") {
                return [line.data];
            } else if (line.type === "group") {
                return line.data!.map(groupLine => ({
                    ...groupLine,
                    groupName: line.name,
                    groupId: line.id
                }));
            }
            return [];
        });

        return (
            <BaseTable
                data={combinedItems}
                columns={[
                    {
                        header: t("subtable.columns.0"),
                        getter: (row) => <Link
                            to={`/dashboard/products/${row.product.id}/details`}><u>{row.product.code}</u></Link>
                    },
                    {
                        header: t("subtable.columns.1"),
                        getter: (row) => row.product.name
                    },
                    {
                        header: t("subtable.columns.2"),
                        getter: (row) => row.quantity
                    },
                    {
                        header: t("subtable.columns.3"),
                        getter: (row) => formatPriceWithCurrency(row.unit_price, baseCurrency)
                    },
                    {
                        header: t("subtable.columns.4"),
                        getter: (row) => row.discount ? row.discount.toFixed(2) + "%" : "-"
                    },
                    {
                        header: t("subtable.columns.5"),
                        getter: (row) => row.tax ? row.tax.rate.toFixed(2) + "%" : "-"
                    },
                    {
                        header: t("subtable.columns.6"),
                        getter: (row) => {
                            const total = calculateOrderPrice(row.quantity, row.unit_price, row.discount ?? 0);
                            const taxed = total * (1 + (row.tax?.rate ?? 0) / 100);
                            return formatPriceWithCurrency(taxed, baseCurrency);
                        }
                    }
                ]}
                size={"small"}
                boldHeaders
                hideTableWhenNothingFound
                nothingFound={{ height: 200 }}
            />
        );
    };

    return (
        <>
            {/* FILTERS BLOCK */}
            <BaseAdminFilter
                permissionModule="sale_order"
                filterItems={
                    <div className="w-[702px]">
                        <BaseInputsGrid cols={2} gap={24}>
                            <BaseDropdown
                                label={t("filters.customer.label")}
                                options={customersOptions}
                                value={selectedCustomers}
                                getter={{
                                    label: (item) => item.name,
                                    key: (item) => item.id
                                }}

                                multiple
                                autocomplete
                                brightLabel

                                onChange={(_, opts) => setSelectedCustomers(opts)}

                                emptyValue={genT(PredefinedTranslations.DropdownsALL)}

                                isLoading={customersOptionsLoading}
                            />

                            <BaseInfiniteLoader
                                fetch={(search, page, limit) => {
                                    setIsProductLazyLoadingFetching(true);
                                    dispatch(productsApi.endpoints.getModuleProductsFull.initiate(
                                            {
                                                filters: {
                                                    search,
                                                },
                                                pagination: {
                                                    page,
                                                    limit
                                                },
                                                module: "sale"
                                            },
                                            {
                                                subscribe: false
                                            }
                                        )
                                    ).unwrap()
                                        .then(res => {
                                            setProductsOptions(res?.payload ?? []);
                                        })
                                        .catch(e => {
                                            if (isErrorWithMessage(e)) {
                                                toast.error(e.message);
                                            } else {
                                                toast.error(i18n.t("general.responses.somethingWentWrong"));
                                            }
                                        })
                                        .finally(() => {
                                            setIsProductLazyLoadingFetching(false);
                                        });
                                }}
                                limit={100}
                                result={productsOptions}
                                isLoading={isProductLazyLoadingFetching}
                            >
                                {
                                    (infinity) => (
                                        <BaseDropdown
                                            {...adaptInfiniteLoader(infinity)}
                                            label={t("filters.product.label")}
                                            // options={productsOptions}
                                            value={selectedProducts}
                                            getter={{
                                                label: opt => opt.name,
                                                key: opt => opt.id,
                                                renderOption: (opt, icon) => (
                                                    <div>
                                                        <span className="grow">{opt.name}</span>
                                                        {
                                                            selectedProducts?.includes(opt) ? icon :
                                                                <span
                                                                    className="text-lightGreen-500 font-thin">{opt.code}</span>
                                                        }
                                                    </div>
                                                )
                                            }}

                                            onChange={(_, opts) => setSelectedProducts(opts)}

                                            virtualize
                                            autocomplete
                                            multiple

                                            emptyValue={genT(PredefinedTranslations.DropdownsALL)}

                                            brightLabel
                                        />
                                    )
                                }
                            </BaseInfiniteLoader>

                            <DatepickerRangeContext.Provider value={{
                                "delivery_date": { range: datesRange, setRange: setDatesRange }
                            }}>
                                <BaseDatepicker
                                    label={t("filters.date.label")}
                                    placeholder={t("filters.date.placeholder")}
                                    rangeConfig={{ groupKey: "delivery_date", role: "solo" }}
                                    brightLabel
                                />
                            </DatepickerRangeContext.Provider>

                            <BaseDropdown
                                label={t("filters.status.label")}
                                options={statusOptions}
                                getter={{
                                    label: (item) => item.label,
                                    key: (item) => item.value
                                }}
                                // multiple
                                onChange={(_, opts) => setSelectedStatus(opts)}
                                value={selectedStatus}

                                emptyValue={genT(PredefinedTranslations.DropdownsALL)}
                            />

                        </BaseInputsGrid>
                    </div>
                }

                handleSearch={searchInputDebounce}

                handleFilter={(e) => console.log(e)}
                handleCreate={() => history.push("/dashboard/sales/orders/new")}

                handleExport={() => void setIsExportModalOpen(true)}
            />

            {/* TABLE BLOCK */}
            <div className="levitation-extended mt-[32px]">
                <Can not I="view" a="sale_order">
                    <NoPermissionBanner />
                </Can>

                <Can I="view" a="sale_order">
                    <BaseTable
                        data={ordersResponse?.payload ?? []}
                        columns={[
                            {
                                header: t("table.columns.0"),
                                getter: (row) => 
                                    <div className="flex flex-row items-center space-x-[8px]">
                                        <BaseOrderStatusBadge status={row.status.name} message={row.status.meta?.cancel_reason} compact />                  
                                        <div className="bold-highlight">{row.code ?? row.id}</div>
                                        <BaseSyncedFromSourcesBadge
                                            integrations={[
                                                {
                                                    slug: "tripletex",
                                                    integrated: () => !!row.tripletex_id
                                                },
                                                {
                                                    slug: "twenty_four_seven_office",
                                                    integrated: () => !!row.twenty_four_seven_office_id
                                                },
                                                {
                                                    slug: "lime",
                                                    integrated: () => !!row.lime_id
                                                },
                                                {
                                                    slug: "poweroffice",
                                                    integrated: () => !!row.poweroffice_id
                                                }
                                            ]}
                                        />
                                    </div>,
                                sx: { width: "300px" }
                            },
                            {
                                header: t("table.columns.1"),
                                getter: (row) => <div className="bold-highlight">{row.customer.name}</div>,
                                comparator: () => 0
                            },
                            // TODO: add shipment column
                            // TODO: add invoice column
                            {
                                header: t("table.columns.4"),
                                getter: (row) => row.order_date.format("YYYY-MM-DD") ?? "-",
                                comparator: () => 0
                            },
                            // {
                            //     header: t("table.columns.3"),
                            //     getter: (row, index, collapseState) => {
                            //         switch(row.receive_state){
                            //             case 0: return <BaseChip fill="green">Received</BaseChip>;
                            //             case 1: return <BaseChip fill="blue">In progress</BaseChip>;
                            //             case 2: return <BaseChip fill="none" className={collapseState ? "bg-gray-300 text-accent" : ""}>Not received</BaseChip>
                            //         }
                            //     },
                            //     comparator: () => 0
                            // },
                            {
                                header: t("table.columns.5"),
                                getter: (row) => handleTotalPrice(row)
                            },
                            {
                                header: <div className="text-center">{t("table.columns.6")}</div>,
                                getter: (row, index, collapseState) => (
                                    <div
                                        className="flex space-x-[4px] justify-center align-center py-[10px] cursor-pointer group"
                                        onClick={(e) => {
                                            setActionsAnchor(e.currentTarget);
                                            setActionEntity(row);
                                        }}>
                                        {
                                            [...Array(3)].map((x, i) => <div key={i}
                                                                             className={`${collapseState ? "bg-gray-600" : "bg-gray-300"} rounded-full w-[5px] h-[5px] group-hover:bg-gray-600 transition-[.15s]`}></div>)
                                        }
                                    </div>
                                ),
                                preventCollapsePropagation: true
                            }
                        ]}
                        collapse={{
                            content: handleCollapseContent,
                            fill: "#D9DAFD",
                            borderColor: "#B1B2E1"
                        }}
                        
                        rowProps={(row) => ({
                            style: {
                                opacity: isOrderDisabled(row) ? 0.5 : 1
                            }
                        })}

                        manualControls={{
                            ordering: (newOrdering) => {
                                if (newOrdering) {
                                    let name: SaleOrder.DTO.OrderBy | undefined;

                                    switch (newOrdering?.index) {
                                        case 1:
                                            name = "customer";
                                            break;
                                        case 2:
                                            name = "date";
                                            break;
                                        default:
                                            name = undefined;
                                    }

                                    if (name)
                                        setOrderBy({
                                            name,
                                            type: newOrdering.order
                                        });
                                } else {
                                    setOrderBy(undefined);
                                }
                            }
                        }}

                        alternate
                        isDataLoading={areOrdersLoading}
                    />

                    <Menu
                        id="basic-menu"
                        anchorEl={actionsAnchor}
                        open={!!actionsAnchor}
                        onClose={() => setActionsAnchor(undefined)}
                    >
                        <div
                            className="flex space-x-[16px] p-[18px] bg-gradient-to-r from-gradients-primaryLinear-from to-gradients-primaryLinear-to text-[#fff]">
                            {/*<Can I="receive" a="sale_order">*/}
                            {/*    <BaseTooltip title={t("actions.receive") || ""} placement="bottom">*/}
                            {/*        <BaseTinyActionIcon*/}
                            {/*            type={TinyActionIconType.Receive}*/}
                            {/*            onClick={() => setIsReceiveModalOpen(true)}}*/}
                            {/*            variant="gradient"*/}
                            {/*        />*/}
                            {/*    </BaseTooltip>*/}
                            {/*</Can>*/}
                            <BaseTooltip title={t("actions.details") || ""} placement="bottom">
                                <BaseTinyActionIcon
                                    type={TinyActionIconType.ViewDetails}
                                    onClick={() => actionEntity && history.push(`/dashboard/sales/orders/${actionEntity.id}/details`)}
                                    variant="gradient"
                                />
                            </BaseTooltip>

                            <BasePermissionBlocker action="create" module="picking">
                                <BaseTooltip active={ability.can("create", "picking")}
                                             title={t("actions.picking") || ""} placement="bottom">
                                    <BaseTinyActionIcon
                                        type={TinyActionIconType.StartPicking}
                                        onClick={() => ability.can("create", "picking") && void history.push(`/dashboard/picking/new`, { saleOrder: actionEntity })}
                                        variant="gradient"
                                    />
                                </BaseTooltip>
                            </BasePermissionBlocker>
                            <BasePermissionBlocker action="purchase_order.create" module="sale_order">
                                <BaseTooltip active={ability.can("purchase_order.create", "sale_order")}
                                             title={t("actions.createPO") + ""} placement="bottom">
                                    <FastCartSVG onClick={async () => {
                                        const order = actionEntity;
                                        if (order && ability.can("purchase_order.create", "sale_order")) {
                                            await handlePOCreation(order);
                                        }
                                    }} />
                                </BaseTooltip>
                            </BasePermissionBlocker>
                            {/*<Can I="duplicate" a="sale_order">*/}
                            {/*    <BaseTooltip title={t("actions.duplicate") || ""} placement="bottom">*/}
                            {/*        <BaseTinyActionIcon*/}
                            {/*            type={TinyActionIconType.Duplicate}*/}
                            {/*            variant="gradient"*/}
                            {/*        />*/}
                            {/*    </BaseTooltip>*/}
                            {/*</Can>*/}
                            <BasePermissionBlocker action="edit" module="sale_order">
                                <BaseTooltip
                                    active={ability.can("edit", "sale_order")}
                                    title={t("actions.edit") || ""}
                                    placement="bottom"
                                    className={actionEntity?.is_editable ? "" : "cursor-not-allowed"}
                                >
                                    <BaseTinyActionIcon
                                        type={TinyActionIconType.Edit}
                                        onClick={() => ability.can("edit", "sale_order") && actionEntity && history.push(`/dashboard/sales/orders/${actionEntity.id}/edit`)}
                                        variant="gradient"
                                        className={actionEntity?.is_editable ? "" : "opacity-20 pointer-events-none"}
                                    />
                                </BaseTooltip>
                            </BasePermissionBlocker>
                            <BasePermissionBlocker action="delete" module="sale_order">
                                <BaseTooltip
                                    title={t("actions.delete") || ""}
                                    placement="bottom"
                                    className={actionEntity?.is_editable ? "" : "cursor-not-allowed"}
                                >
                                    <BaseTinyActionIcon
                                        type={TinyActionIconType.Delete}
                                        onClick={() => {
                                            ability.can("delete", "sale_order") && (
                                                setIsDeletionModalOpen(true),
                                                    setActionsAnchor(undefined)
                                            );
                                        }}
                                        variant="gradient"
                                        className={actionEntity?.is_editable ? "" : "opacity-20  pointer-events-none"}
                                    />
                                </BaseTooltip>
                            </BasePermissionBlocker>
                            <BasePermissionBlocker action="cancel" module="sale_order">
                                <CancelOrderAction
                                    actionEntity={actionEntity}
                                    action="cancel"
                                    module="sale_order"
                                    t={t}
                                    onClick={() => {
                                        setIsCancellationModalOpen(true);
                                        setActionsAnchor(undefined);
                                    }}
                                />
                            </BasePermissionBlocker>
                        </div>
                    </Menu>

                    {/* PAGINATION */}
                    <Pagination
                        className="mt-[32px]"
                        {...pagination.adapt(ordersResponse)}
                    />
                </Can>
            </div>

            {/* REFILL MODAL */}
            <LowStockRefillModal
                isOpen={isRefillModalOpen}
                onClose={() => setIsRefillModalOpen(false)}
                refill={refillModalData}
            />

            {/* DELETION MODAL */}
            <OrderDeletionModal
                isOpen={isDeletionModalOpen}
                entity_id={actionEntity?.id}
                onClose={() => {
                    setIsDeletionModalOpen(false);
                    setActionsAnchor(undefined);
                }}
            />

            {/* CANCELLATION MODAL */}
            <OrderCancellationModal
                isOpen={isCancellationModalOpen}
                entityId={actionEntity?.id}
                onClose={() => {
                    setIsCancellationModalOpen(false);
                    setActionsAnchor(undefined);
                }}
            />

            {/* EXPORT MODAL */}
            <SaleOrdersExportModal
                isOpen={isExportModalOpen}
                onClose={() => void setIsExportModalOpen(false)}
                filters={filters}
                orderBy={orderBy}
            />
        </>
    );
}

