import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { selectCategoryById, useGetCategoriesQuery } from "@redux/features/categories/categoriesApi";
import { useAppSelector } from "@redux/hooks";

import BaseMaterialButton from "@reusables/BaseMaterialButton";
import BaseTable from "@reusables/BaseTable";

import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";
import { ReactComponent as DeleteSVG } from "@assets/icons/ic_trash.svg";
import DeletionModal from "./modals/DeletionModal";

import { AbilityContext, Can } from "@/casl.config";
import { useAbility } from "@casl/react";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import BaseBadge from "@reusables/BaseBadge";
import MutationModal from "@components/Dashboard/pages/SettingsPage/General/Categories/modals/MutationModal";
import BaseSyncedFromSourcesBadge from "@reusables/BaseSyncedFromSourcesBadge";

export default function CategoriesPage() {
    const { t } = useTranslation("", { keyPrefix: "settings.general.categories" });

    const ability = useAbility(AbilityContext);

    // Getting categories data using RTK Query
    const { data: categories = [], isLoading: isLoadingCategories } = useGetCategoriesQuery();

    // Open states determine visibility of modals
    const [isMutationModalOpen, setIsMutationModalOpen] = useState(false);
    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

    // Ids and corresponding selected cache entries allows modals to work with data
    const [selectedCategoryId, setSelectedCategoryId] = useState<number>();
    const [deletableCategoryId, setDeletableCategoryId] = useState<number>();

    // Selected category and deletable category are used to pass data to modals
    const selectedCategory = useAppSelector(state => selectCategoryById(state, selectedCategoryId));
    const deletableCategory = useAppSelector(state => selectCategoryById(state, deletableCategoryId));

    return (
        <>
            <div className="levitation-extended">
                <div className="flex flex-row justify-between items-center mb-[44px]">
                    <div className="font-bold text-2xl text-accent">{t("heading")}</div>
                    <Can I="categories.edit" a="general">
                        <BaseMaterialButton fontWeight={500} onClick={() => {
                            setSelectedCategoryId(undefined);
                            setIsMutationModalOpen(true);
                        }}>
                            {t("table.buttons.add")}
                        </BaseMaterialButton>
                    </Can>
                </div>

                <Can not I="categories.view" a="general">
                    <NoPermissionBanner />
                </Can>

                <Can I="categories.view" a="general">
                    <BaseTable
                        data={categories}
                        columns={[
                            {
                                header: t("table.columns.0"),
                                getter: (row) =>
                                    <div className={"flex flex-row items-center space-x-2"}>
                                        <div className="bold-highlight">{row.code}</div>
                                        <BaseSyncedFromSourcesBadge
                                            integrations={[
                                                {
                                                    slug: "twenty_four_seven_office",
                                                    integrated: () => !!row?.twenty_four_seven_office_id
                                                }
                                            ]}
                                        />
                                    </div>

                            },
                            {
                                header: t("table.columns.1"),
                                getter: (row) => <div className="bold-highlight">{row.name}</div>,
                                comparator: (a, b) => a.name.localeCompare(b.name)
                            },
                            {
                                header: t("table.columns.2"),
                                getter: (row) => {
                                    if (row.is_service) {
                                        return <BaseBadge className={"bg-light-green"}>
                                            {t("table.isService")}
                                        </BaseBadge>;
                                    } else {
                                        return <></>;
                                    }
                                }
                            },
                            {
                                visible: ability.can("categories.edit", "general"),
                                header: () => "",
                                getter: (row) => (
                                    <div className="text-tables-secondaryIcon">
                                        <Can I="categories.edit" a="general">
                                            <EditSVG
                                                className="mr-[8px] cursor-pointer hover:text-tables-highlightedIcon"
                                                onClick={() => {
                                                    setSelectedCategoryId(row.id);
                                                    setIsMutationModalOpen(true);
                                                }}
                                            />
                                        </Can>
                                        <Can I="categories.edit" a="general">
                                            <DeleteSVG
                                                className="cursor-pointer hover:text-tables-highlightedIcon"
                                                onClick={() => {
                                                    setDeletableCategoryId(row.id);
                                                    setIsDeletionModalOpen(true);
                                                }}
                                            />
                                        </Can>
                                    </div>
                                ),
                                sx: {
                                    textAlign: "right"
                                }
                            }
                        ]}

                        isDataLoading={isLoadingCategories}

                        headerSx={{
                            backgroundColor: "#F9F9F9"
                        }}

                        sx={{
                            borderCollapse: "separate",
                            borderSpacing: "0 15px"
                        }}

                        size={"small"}
                    />
                </Can>
            </div>

            <MutationModal
                isOpen={isMutationModalOpen}
                onClose={() => setIsMutationModalOpen(false)}
                category={selectedCategory}
            />

            <DeletionModal
                isOpen={isDeletionModalOpen}
                onClose={() => setIsDeletionModalOpen(false)}
                category={deletableCategory}
            />
        </>
    );
}
