import React from 'react'
import { Picking } from '@/types/general';
import { useTranslation } from 'react-i18next';
import BaseModal from '@reusables/Modals/BaseModal';
import BaseTable from '@reusables/BaseTable';
import BaseButton from '@components/reusables/BaseButton';

interface LocationModalProps {
    isOpen: boolean;
    onClose: () => void;
    line: Picking.Components.LineData | undefined
}

export default function LocationsModal({ isOpen, onClose, line }: LocationModalProps) {
    const { t } = useTranslation("", { keyPrefix: "sales.picking.modals.locations" });

    return (
        <BaseModal 
            isOpen={isOpen}
            onClose={onClose}
            width={700}
            padding="56px"
            position="center"
        >
            <div className="flex flex-col space-y-8">
                <div className="text-xl font-semibold text-accent">{line?.product.name}</div>
                <BaseTable
                    data={line?.picks ?? []}
                    columns={[
                        {
                            header: t("columns.0"),
                            getter: (row) => `${row.outgoing_location.store.name} ${row.outgoing_location.section ? `- ${row.outgoing_location.section.name}` : ""}`,
                        },
                        {
                            header: t("columns.1"),
                            getter: (row) => row.picked_quantity,
                        }
                    ]}

                    boldHeaders
                    
                    sx={{
                        "& th, td": {
                            padding: "6px 16px"
                        },
                        "& th:first-child, td:first-child": {
                            paddingLeft: "0px"
                        },
                        "& th:last-child, td:last-child": {
                            paddingRight: "0px"
                        }
                    }}
                    headerSx={{
                        "& th": {
                            padding: "8px 16px"
                        }
                    }}
                />

                <BaseButton
                    text={t("buttons.close")}
                    onClick={onClose}
                    size="md"
                    primaryOutlined
                />
            </div>
        </BaseModal>
    )
}
