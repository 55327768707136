import React, { useMemo, useState } from "react";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import { BaseInputLikeContainer } from "@reusables/BaseInputLikeContainer";
import { useTranslation } from "react-i18next";
import { jsxSwitch } from "@helpers/utils";
import BaseChip from "@reusables/BaseChip";
import BaseTable from "@reusables/BaseTable";
import { Stack } from "@mui/material";
import { ReactComponent as DetailsSVG } from "@assets/icons/ic_document_search.svg";
import SerialNumbersModal from "@components/Dashboard/pages/Sales/Picking/components/modals/SerialNumbersModal";
import BatchNumbersModal from "@components/Dashboard/pages/Sales/Picking/components/modals/BatchNumbersModal";
import { Link } from "react-router-dom";
import BaseBadge from "@reusables/BaseBadge";
import { ViewLayoutProps } from "./index";
import BaseTooltip from "@components/reusables/BaseTooltip";
import { ReactComponent as CollectionsIcon } from "@assets/icons/sidebar/ic_folders.svg";
import { Picking } from "@/types/general";
import LocationsModal from "../modals/LocationsModal";
import TableButton from "@reusables/BaseTable/TableButton";
import { Forklift } from "lucide-react";

export interface TransformedLine {
    type: 'line' | 'group';
    id?: number;
    name?: string;
    module: 'bom' | 'collection';
    key?: string;
    data: Picking.Components.LineData | undefined; // Always a single LineData or null for group headers
}


export default function PickingDetails({ picking }: ViewLayoutProps) {
    const { t } = useTranslation("", { keyPrefix: "sales.picking.general" });

    const transformedLines = useMemo(() => {
        return picking.lines.map(line => {
            if (line.type === "line") {
                return [line];
            } else if (line.type === "group") {
                return [
                    {
                        type: "group",
                        name: line.name,
                        module: line.module,
                        data: undefined
                    },
                    ...(line.data!.map(line => ({
                        type: "group",
                        data: {
                            ...line
                        }
                    })))
                ]
            }
            return [];
        }).flat();
    }, [picking.lines]);


    const ordersSummary = useMemo(() => {
        return {
            picked: transformedLines.filter(line => line.data).reduce((acc, curr) => acc + (curr.data?.picks?.reduce((pick_acc, pick_curr) => pick_acc + (pick_curr.picked_quantity ?? 0), 0) ?? 0), 0),
            ordered: transformedLines?.filter(line => line.data).reduce((acc, curr) => acc + (curr.data?.product?.quantity ?? 0), 0)
        };
    }, [picking]);


    const serialNumbersLines = transformedLines.filter(line => line.data?.serial_numbers?.length);
    const batchNumbersLines = transformedLines.filter(line => line.data?.batch_numbers?.length);

    // Modals
    const [isSerialNumberModalOpen, setIsSerialNumberModalOpen] = React.useState(false);
    const [isBatchNumberModalOpen, setIsBatchNumberModalOpen] = React.useState(false);

    const [isLocationModalOpen, setIsLocationModalOpen] = useState<boolean>(false);
    const [locationData, setLocationData] = useState<Picking.Components.LineData | undefined>(undefined);

    return (
        <>
            <div className="space-y-[40px]">
                <BaseInputsGrid cols={{
                    lg: 3,
                    sm: 2
                }}>
                    <BaseInputLikeContainer
                        label={t("fields.customer.label")}
                        value={picking.order.customer.name}
                    />
                    <BaseInputLikeContainer
                        label={t("fields.saleOrder.label")}
                        value={picking.order.code ?? picking.order.id}
                        linkValue={`/dashboard/sales/orders/${picking.order.id}/details`}
                    />
                    <BaseInputLikeContainer
                        label={t("fields.pickingDate.label")}
                        value={picking.date.format("DD.MM.YYYY")}
                    />
                    <BaseInputLikeContainer
                        label={t("fields.invoicing.label")}
                        value={jsxSwitch(
                            {
                                0: <BaseChip className={"bg-[#F3F2EF]"}>{t(`invoicing.status.0`)}</BaseChip>,
                                1: <BaseChip fill={"green"}>
                                        <span className="space-x-1 mr-2">
                                            {picking.invoicing.map(invoice => 
                                                <BaseTooltip key={invoice.slug} title={invoice.name} placement="top">
                                                    <img 
                                                        src={invoice.logo}
                                                        className="w-[16px]" 
                                                        onClick={() => window.open(invoice.link, "_blank")}
                                                    />
                                                </BaseTooltip>
                                            )}
                                        </span>
                                        {t(`invoicing.status.1`)}
                                    </BaseChip>
                            },
                            Object.values(picking.invoicing).filter(x => x != null).length ? 1 : 0
                        )}
                    />
                    <BaseInputLikeContainer
                        label={t("fields.delivery.label")}
                        value={
                            <div className={"flex flex-row items-center space-x-[8px]"}>
                                <div>
                                {
                                    jsxSwitch(
                                        {
                                            "not_shipped": <BaseChip
                                                className={"bg-[#F3F2EF]"}>{t(`delivery.status.not_shipped.0`)}</BaseChip>,
                                            "in_progress": <BaseChip
                                                fill={"purple"}>{t(`delivery.status.in_progress`)}</BaseChip>,
                                            "delivered": <BaseChip
                                                fill={"green"}>{t(`delivery.status.delivered`)}</BaseChip>
                                        },
                                        picking.delivery_status
                                    )
                                }
                                </div>
                                {
                                    !!picking.shipment_change_date && <div className={"text-gray-600 text-sm"}>
                                        ({picking.shipment_change_date.format("DD.MM.YYYY")})
                                    </div>
                                }
                            </div>
                        } />
                </BaseInputsGrid>

                <div className="border border-solid border-gray-300 rounded-[8px] p-[16px]">
                    <div className="flex mb-[46px] items-center space-x-[8px]">
                        <div className="text-xl font-thin text-gray-600 grow">{t("products.heading")}</div>
                        <Stack direction={"row"} spacing={3}>
                            {
                                serialNumbersLines.length > 0 &&
                                <Stack
                                    direction={"row"}
                                    spacing={1}
                                    className={"hover:text-accent cursor-pointer"}
                                    onClick={() => setIsSerialNumberModalOpen(true)}
                                >
                                    <span>{t("products.modalReferences.serialNumbers")}</span>
                                    <DetailsSVG />
                                </Stack>
                            }
                            {
                                batchNumbersLines.length > 0 &&
                                <Stack
                                    direction={"row"}
                                    spacing={1}
                                    className={"hover:text-accent cursor-pointer"}
                                    onClick={() => setIsBatchNumberModalOpen(true)}
                                >
                                    <span>{t("products.modalReferences.batchNumbers")}</span>
                                    <DetailsSVG />
                                </Stack>
                            }
                        </Stack>
                    </div>
                    <BaseTable
                        hideTableWhenNothingFound
                        data={transformedLines as TransformedLine[]}
                        columns={[
                            {
                                header: t("products.columns.0"),
                                getter: (row) => row.data ?
                                    row.type === "group" ?
                                        <div className="flex items-center">
                                            <div className="self-center w-[2px] h-[20px] bg-[#D9D6DA] rounded-sm mr-2"></div>
                                            <Link
                                                to={`/dashboard/products/${row.data?.product?.id}/details`}><u>{row.data?.product?.code}</u></Link>
                                                {/* Comments is not implemented on the backend for /api/picking/{id} */}
                                                {/* {row.data.comment && (
                                                    <BaseTooltip
                                                        title={row.data.comment}
                                                        placement="top"
                                                    >
                                                        <MessageSquareText className="w-5 ml-2 text-[#5A5587] hover:text-accent cursor-pointer" />
                                                    </BaseTooltip>
                                                )} */}
                                        </div>
                                        : <div>
                                            <Link
                                                to={`/dashboard/products/${row.data?.product?.id}/details`}><u>{row.data?.product?.code}</u></Link>
                                                {/* {row.data.comment && (
                                                    <BaseTooltip
                                                        title={row.data.comment}
                                                        placement="top"
                                                    >
                                                        <MessageSquareText className="w-5 ml-2 text-[#5A5587] hover:text-accent cursor-pointer" />
                                                    </BaseTooltip>
                                                )} */}
                                        </div>
                                        : row.type === "group" 
                                        ?
                                            <div className="flex items-center">
                                                <div className="w-1 h-8 bg-[#B49FFB] rounded-sm mr-2"></div> 
                                                <div className="flex space-x-2 font-semibold">
                                                    <div>{row.name}</div> 
                                                    <BaseBadge
                                                        className="px-2 bg-[#CDCFFD] rounded-xl"
                                                    >
                                                        <div className="flex items-center">
                                                            <CollectionsIcon className="mr-1 bg-[#CDCFFD] text-accent" />
                                                            {row.module === "collection" ? "Collection" : "BOM"}
                                                        </div>
                                                    </BaseBadge>
                                                </div>
                                            </div> 
                                        : "",
                            },
                            {
                                header: t("products.columns.1"),
                                getter: (row) => (
                                    <div className="flex space-x-2">
                                        <div>{row.data?.product?.name}</div>
                                        {row.data?.product?.is_service && (
                                            <BaseBadge className={"bg-light-green"}>
                                                <Forklift size={12} className={"mr-1"} />
                                                <span>{t("isService")}</span>
                                            </BaseBadge>
                                        )}
                                    </div>
                                )
                            },
                            {
                                header: t("products.columns.2"),
                                getter: (row) => row.data?.product?.quantity.toFixed(2) ?? ""
                            },
                            {
                                header: t("products.columns.6"),
                                getter: (row) => row.data?.picks?.reduce((acc, curr) => acc + (curr.picked_quantity ?? 0), 0).toFixed(2) ?? ""
                            },
                            {
                                header: t("products.columns.7"),
                                getter: (row) => {
                                    if (row.data) {
                                        let pickStatus: 0 | 1 | 2 = 0;
                                        const pickedQuantity = row.data?.picks?.reduce((acc, curr) => acc + (curr.picked_quantity ?? 0), 0);

                                        if ((pickedQuantity ?? 0) > 0) {
                                            pickStatus = pickedQuantity == row.data?.product.quantity ? 2 : 1;
                                        }

                                        return jsxSwitch(
                                            {
                                                0: <BaseChip fill={"yellow"} asDot>{t("products.pickStatus.0")}</BaseChip>,
                                                1: <BaseChip fill={"blue"} asDot>{t("products.pickStatus.1")}</BaseChip>,
                                                2: <BaseChip fill={"green"} asDot>{t("products.pickStatus.2")}</BaseChip>
                                            },
                                            pickStatus
                                        );
                                    } else {
                                        return "";
                                    }
                                }
                            },
                            {
                                header: t("products.columns.8"),
                                getter: (row) => {
                                    if (row.data && !row.data?.product.is_service) {
                                        return (
                                            <TableButton
                                                onClick={() => {
                                                    if (row.data) {
                                                        setLocationData(row.data);
                                                        setIsLocationModalOpen(true);
                                                    }
                                                }
                                            }>
                                                {t("products.showLocations")}
                                            </TableButton>
                                        );
                                    }
                                }
                                    
                            }
                        ]}
                        rowProps={(row) => {
                            if (row.type === "group" && !row.data) {
                                return {
                                    className: "group-header"
                                };
                            }
                        
                            if (row.type === "group" && !!row.data) {
                                return { className: "group-cell" };
                            }
                            
                            return {};
                        }}
                        sx={{
                            "& .MuiTableBody-root": {
                                position: "relative",
                                "&::before": {
                                    content: '""',
                                    display: "block",
                                    height: "16px",  // Height of the spacing
                                    backgroundColor: "transparent", // Transparent to keep it invisible
                                },
                            },
                            "& .group-cell .MuiTableCell-root": {
                                padding: "4px 16px"
                            },
                            "& .group-header .MuiTableCell-root": {
                                backgroundColor: "#EEF2FF",
                            },
                            "& .group-header .MuiTableCell-root:first-of-type": {
                                borderTopLeftRadius: "8px",
                                borderBottomLeftRadius: "8px"
                            },
                            "& .group-header .MuiTableCell-root:last-of-type": {
                                borderTopRightRadius: "8px",
                                borderBottomRightRadius: "8px",
                            },
                        }}
                        headerSx={{
                            background: "rgba(249, 249, 249, 1)"
                        }}
                        nothingFound={{
                            text: t("products.noProducts")
                        }}
                        size="small"
                    />
                    <div className="flex justify-end mt-[48px] font-thin">
                        <div className="w-1/2 grid grid-cols-2 gap-y-[16px]">
                            <div className="text-right font-semibold text-accent">
                                {t("products.summary.ordered")}
                            </div>
                            <div className="text-right font-semibold pr-[16px] text-accent">
                                {
                                    ordersSummary.ordered.toFixed(2)
                                }
                            </div>
                            <div className="text-right font-semibold text-accent">
                                {t("products.summary.picked")}
                            </div>
                            <div className="text-right font-semibold pr-[16px] text-[#A6DD4C]">
                                {
                                    ordersSummary.picked.toFixed(2)
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <BaseInputsGrid cols={{
                    lg: 3,
                    sm: 2
                }}>
                    <BaseInputLikeContainer
                        label={t("fields.packaging.label")}
                        value={
                            <div className="flex flex-row items-center space-x-[8px]">
                                {picking.packages.length ? picking.packages?.map(pkg => (
                                    <BaseBadge className={"bg-[#F3F2EF]"} key={pkg.id}>
                                        {`${pkg.quantity} x ${pkg.name}`}
                                    </BaseBadge>
                                )) : t("fields.packaging.notSelected")}
                            </div>
                        }
                    />
                </BaseInputsGrid>
            </div>

            {/* Locations modal */}
            {
                <LocationsModal
                    isOpen={isLocationModalOpen}
                    onClose={() => {
                        setIsLocationModalOpen(false);
                    }}
                    line={locationData}
                />
            }

            {/* Serial numbers modal */}
            {
                serialNumbersLines.length > 0 &&
                <SerialNumbersModal
                    isOpen={isSerialNumberModalOpen}
                    onClose={() => setIsSerialNumberModalOpen(false)}
                    lines={serialNumbersLines
                        .filter(line => line.data && line.data.id && line.data.product)  // Filter out invalid data first
                        .map(line => ({
                            id: line.data!.id,
                            comment: undefined,
                            product: {
                                id: line.data!.product.id,
                                name: line.data!.product.name,
                                code: line.data!.product.code,
                            },
                            picked_quantity: line.data!.picks?.reduce((acc, curr) => acc + (curr.picked_quantity ?? 0), 0),
                            serial_numbers: line.data!.serial_numbers
                        }))}
                    preview
                />
            }

            {/* Batch numbers modal */}
            {
                batchNumbersLines.length > 0 &&
                <BatchNumbersModal
                    isOpen={isBatchNumberModalOpen}
                    onClose={() => setIsBatchNumberModalOpen(false)}
                    lines={batchNumbersLines
                        .filter(line => line.data && line.data.id && line.data.product)  // Filter out invalid data first
                        .map(line => ({
                            id: line.data!.id,
                            comment: undefined,
                            product: {
                                id: line.data!.product.id,
                                name: line.data!.product.name,
                                code: line.data!.product.code,
                            },
                            picked_quantity: line.data!.picks?.reduce((acc, curr) => acc + (curr.picked_quantity ?? 0), 0),
                            batch_numbers: line.data!.batch_numbers
                        }))}
                    preview
                />
            }
        </>
    );
}