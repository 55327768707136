import React from 'react'
import BaseButton from '@reusables/BaseButton';
import BaseCheckbox from '@reusables/BaseCheckbox';
import BaseInput from '@reusables/BaseInput';
import BaseModal from '@reusables/Modals/BaseModal';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useGetProductsReconversionCountQuery, useReconversionProductsMutation } from '@redux/features/products/productsApi';
import { Alert, Collapse } from '@mui/material';
import { BaseLoadingBlocker } from '@reusables/blockers/BaseLoadingBlocker';
import { toastError } from '@helpers/utils';
import { useAppSelector } from '@redux/hooks';
import { toast } from 'react-toastify';
import { isPriceReconversionStateActive } from '@redux/features/products/reconversion/types';
import { useTranslation } from 'react-i18next';

interface ProductsPriceReconversionModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const formSchema = z.object({
    report_email: z.string().email(),
    exclude_imported: z.boolean(),
});

export type ProductsPriceReconversionModalForm = z.infer<typeof formSchema>;

export default function ProductsPriceReconversionModal({ isOpen, onClose }: ProductsPriceReconversionModalProps) {
    const { t } = useTranslation("", { keyPrefix: 'products.allProducts.reconversion.reconversionModal' });

    const { data: productsCount, isLoading: isProductsCountLoading } = useGetProductsReconversionCountQuery(undefined, {skip: !isOpen, refetchOnMountOrArgChange: true});

    const [reconvertProducts] = useReconversionProductsMutation();

    const userEmail = useAppSelector(state => state.auth.user?.email);

    const isPriceReconversionInProgress = useAppSelector(state => isPriceReconversionStateActive(state.priceReconversion.value));

    const isProductsCount = productsCount?.count ?? 0;

    const { control, handleSubmit, reset } = useForm<ProductsPriceReconversionModalForm>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            exclude_imported: false,
            report_email: userEmail ?? '',
        },
    });

    const onSubmit = handleSubmit((data) => {
        console.log(data);

        reconvertProducts(data).unwrap()
            .then(() => {
                handleClose();
                toast.success(t('responses.success'));
            })
            .catch((e) => {
                toastError(e);
            });
    });

    function handleClose() {
        reset();
        onClose();
    }

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            width={700}
            padding="56px"
            useCloseIcon
            position="center"
            keepMounted={false}
        >
            <BaseLoadingBlocker active={isProductsCountLoading}>
                <form onSubmit={onSubmit}>
                    <div className="space-y-[32px]">
                        <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                        {isPriceReconversionInProgress && (
                            <Collapse in={isPriceReconversionInProgress}>
                                <Alert severity="warning">
                                    {t('alerts.reconversionInProgress')}
                                </Alert>
                            </Collapse> 
                        )}
                        <Alert
                            severity="info"
                            variant="standard"
                            sx={{ padding: '16px', borderRadius: '8px' }}
                        > 
                            {isProductsCount ? t("productsAvailable", {count: productsCount?.count}) : t("noProductsAvailable")}
                        </Alert>
                        <div className="space-y-[16px]">
                            <Controller
                                control={control}
                                name="report_email"
                                render={({ field, fieldState }) => (
                                    <BaseInput
                                        {...field}
                                        error={fieldState.error}
                                        label={t("fields.reportEmail.label")}
                                        placeholder={t("fields.reportEmail.placeholder")}
                                        disabled={!isProductsCount}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="exclude_imported"
                                render={({ field, fieldState }) => (
                                    <BaseCheckbox
                                        {...field}
                                        error={fieldState.error}
                                        label={t("fields.excludeImported.label")}
                                        tooltipTitle={t("fields.excludeImported.tooltip")}
                                        disabled={!isProductsCount}
                                    />
                                )}
                            />
                        </div>
                        <div className="grid grid-cols-2 gap-[16px]">
                            <BaseButton 
                                text={t("buttons.cancel")}
                                size="md" 
                                primaryOutlined 
                                onClick={handleClose} 
                                type="button" 
                            />

                            <BaseButton
                                text={t("buttons.startReconversion")}
                                size="md"
                                type='submit'
                                disabled={!isProductsCount}
                            />
                        </div>
                    </div>
                </form>
            </BaseLoadingBlocker>
        </BaseModal>
    )
}
