import { customersApi } from "@redux/features/customers/customersApi";
import { productsApi } from "@redux/features/products/productsApi";
import { useAppDispatch } from "@redux/hooks";
import { internalApiSlice, tags as internalApiTags } from "@redux/api/internalApiSlice";

// This object contains the tags that need to be invalidated for each module
const moduleInvalidationTags: { [key: string]: ({ type: string; id?: string; } | string)[] } = {
    "product": [{ type: "Product" }],
    "customer": [{ type: "Customer" }],
    "stock_count": internalApiTags
};

export function invalidateModuleCache(moduleType: string, dispatch: ReturnType<typeof useAppDispatch>) {
    // Get the tags for the moduleType
    const tags = moduleInvalidationTags[moduleType];

    // Invalidate the tags for the moduleType
    switch (moduleType) {
        case "product":
            dispatch(productsApi.util.invalidateTags(tags));
            break;
        case "customer":
            dispatch(customersApi.util.invalidateTags(tags));
            break;
        case "stock_count":
            dispatch(internalApiSlice.util.invalidateTags(tags));
            break;
        default:
            break;
    }
}