import React from "react";
import OutgoingLocationCell
    from "@components/Dashboard/pages/Sales/Picking/components/MutationLayout/parts/TableBody/OutgoingLocationCell";
import { Box, Stack } from "@mui/material";
import { Control, useFieldArray } from "react-hook-form";
import BaseTable from "@reusables/BaseTable";
import { ReactComponent as DeleteSVG } from "@assets/icons/ic_trash.svg";
import { useTranslation } from "react-i18next";
import QuantityCell
    from "@components/Dashboard/pages/Sales/Picking/components/MutationLayout/parts/TableBody/QuantityCell";
import type { PickingMutationFormTyping } from "../../types";
import BaseBadge from "@reusables/BaseBadge";
import { ReactComponent as CollectionsIcon } from "@assets/icons/sidebar/ic_folders.svg";
import BaseTooltip from "@reusables/BaseTooltip";
import { MessageSquareText } from "lucide-react";


type TableBodyProps = {
    control: Control<PickingMutationFormTyping>;
}

export default function TableBody({control}: TableBodyProps) {
    const {t} = useTranslation("", {keyPrefix: "sales.picking.general"});

    const {fields: lines, update} = useFieldArray({
        control,
        name: "lines",
        keyName: "loop_id"
    });

    return (
        <BaseTable
            hideTableWhenNothingFound
            data={lines}
            rowKeyGetter={row => row.loop_id}
            columns={[
                // Product code
                {
                    header: t("products.columns.0"),
                    getter: (row) => row.data ?
                        row.type === "group" ?
                            <div className="flex items-center">
                                <div 
                                    className="self-center divider-class w-[2px] h-[20px] bg-[#D9D6DA] rounded-sm mr-2"
                                ></div>
                                <div className="ml-2">
                                    {row.data?.product?.code}
                                    {row.data.comment && (
                                        <BaseTooltip
                                            title={row.data.comment}
                                            placement="top"
                                        >
                                            <MessageSquareText className="w-5 ml-2 text-[#5A5587] hover:text-accent cursor-pointer" />
                                        </BaseTooltip>
                                    )}
                                </div>
                            </div>
                        : <div>
                            {row.data?.product?.code}
                            {row.data.comment && (
                                <BaseTooltip
                                    title={row.data.comment}
                                    placement="top"
                                >
                                    <MessageSquareText className="w-5 ml-2 text-[#5A5587] hover:text-accent cursor-pointer" />
                                </BaseTooltip>
                            )}
                          </div>
                        : row.type === "group" 
                        ?
                        <div className="flex items-center">
                            <div className="w-1 h-8 bg-[#B49FFB] rounded-sm mr-2"></div> 
                            <div className="ml-3 absolute min-w-[200px] flex space-x-2">
                                <div className="font-semibold">{row.name}</div> 
                                <BaseBadge
                                    className="px-2 bg-[#CDCFFD] rounded-xl"
                                >
                                    <div className="flex items-center">
                                        <CollectionsIcon className="mr-1 bg-[#CDCFFD] text-accent" />
                                        {row.module === "collection" ? "Collection" : "BOM"}
                                    </div>
                                </BaseBadge>
                            </div>
                        </div> 
                        : "",
                },
                // Product name
                {
                    header: t("products.columns.1"),
                    getter: (row) => row.data?.product?.name ?? "",
                    sx: {maxWidth: "160px"}
                },
                // Quantity ordered (with quantity left indicator)
                {
                    header: t("products.columns.2"),
                    getter: (row) => {
                        if (!row.data?.product) {
                            return "";
                        }
                        const quantityLeft = row.data?.product?.quantity - row.data?.product?.already_picked_quantity;
                        return <>
                            {row.data?.product?.quantity.toFixed(2)}
                            {
                                quantityLeft < row.data?.product?.quantity && (
                                    quantityLeft >= 0 ?
                                        <span className="text-[#D9D6DA] ml-1">
                                                        ({quantityLeft.toFixed(2)} {t("products.quantityLeftPostfix")})
                                                    </span>
                                        :
                                        <span className="text-[#D9D6DA] ml-1">
                                                        ({row.data?.product.already_picked_quantity.toFixed(2)} {t("products.quantityPickedPostfix")})
                                                    </span>
                                )
                            }
                        </>;
                    }
                },
                // Outgoing locations
                {
                    header: t("products.columns.3"),
                    getter: (row, index) => <OutgoingLocationCell
                        index={index}
                        control={control}
                        onAdd={() => void update(index, {
                            ...row,
                            data: {
                                ...row.data,
                                picks: [
                                    ...row.data!.picks,
                                    {
                                        outgoing_location: undefined,
                                        picked_quantity: undefined
                                    }
                                ]
                            } as any
                        })}
                    />,
                    sx: { width: "400px" }
                },
                // Picked quantities
                {
                    header: t("products.columns.4"),
                    getter: (row, index) => <QuantityCell index={index} control={control}/>,
                    sx: {
                        width: "160px"
                    }
                    
                },
                // Actions
                {
                    header: t("products.columns.5"),
                    getter: (row, index) =>
                        <Stack
                            spacing={"16px"}
                        >
                            {
                                row.data?.picks?.map((pick, pickIndex) => {
                                    return (
                                        <Box
                                            key={pickIndex}
                                            display="flex"
                                            alignItems="center"
                                            height={44}
                                        >
                                            {
                                                pickIndex !== 0 &&
                                                <DeleteSVG
                                                    className={"text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer"}
                                                    onClick={() => update(index, {
                                                        ...row,
                                                        data: {
                                                            ...row.data,
                                                            picks: row.data!.picks.filter((_, i) => i !== pickIndex)
                                                        } as any
                                                    })}
                                                />
                                            }
                                        </Box>
                                    );
                                })
                            }
                        </Stack>,
                    sx: {
                        width: "80px"
                    }
                }
            ]}
            rowProps={(row) => {
                if (row.type === "group" && !row.data) {
                    return {
                        className: "group-header"
                    };
                }
            
                if (row.type === "group" && !!row.data) {
                    return { className: "group-cell" };
                }
                
                return {};
            }}
            sx={{
                "& .MuiTableBody-root": {
                    position: "relative",
                    "&::before": {
                        content: '""',
                        display: "block",
                        height: "16px",  // Height of the spacing
                        backgroundColor: "transparent", // Transparent to keep it invisible
                    },
                },
                "& tbody td": {
                    verticalAlign: "top",
                    "&:nth-child(-n+3)": {
                        paddingTop: "16px"
                    }
                },
                "& .group-header .MuiTableCell-root": {
                    backgroundColor: "#EEF2FF",
                    paddingTop: "8px"
                },
                "& .group-cell .MuiTableCell-root:first-of-type": {
                    position: "relative",
                    "& .divider-class": {
                        width: "2px",
                        height: "90%",
                        backgroundColor: "#D9D6DA",
                        borderRadius: "8px",
                        position: "absolute",
                        left: "16px",
                        bottom: 0,
                    },
                },
                "& .group-header .MuiTableCell-root:first-of-type": {
                    borderTopLeftRadius: "8px",
                    borderBottomLeftRadius: "8px"
                },
                "& .group-header .MuiTableCell-root:last-of-type": {
                    borderTopRightRadius: "8px",
                    borderBottomRightRadius: "8px",
                },
            }}
            headerSx={{
                background: "rgba(249, 249, 249, 1)"
            }}
            nothingFound={{
                text: t("products.noProducts"),
                height: 300
            }}
            size="small"
        />
    )
}