import React from "react";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import dayjs from "dayjs";
import { AddProductFormSchemeTyping, DiscountOption } from "./AddProductModal";
import { normalizePrice } from "@helpers/utils";
import { useFormContext, useWatch } from "react-hook-form";
import { AlertCircle } from "lucide-react";
import BaseTooltip from "@/components/reusables/BaseTooltip";

interface DiscountCardProps {
    discounts: DiscountOption[];
    selectedDiscount?: DiscountOption;
    onDiscountSelect: (discount: DiscountOption) => void;
}

const DiscountCard = ({ discounts, selectedDiscount, onDiscountSelect }: DiscountCardProps) => {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.general.orders.modals.addProduct.cards" });
    const { control } = useFormContext<AddProductFormSchemeTyping>();

    const quantity = useWatch({
        control,
        name: "quantity"
    });

    const unitPrice = useWatch({
        control,
        name: "unit_price"
    });

    // Group discounts by id
    const priceDiscounts = discounts.filter((d) => d.group === "pricesAndDiscounts");
    const customerDiscounts = discounts.filter(d => d.id === "customerDiscount");
    const groupDiscounts = discounts.filter(d => d.id.includes("groupDiscount"));

    const today = dayjs();

    // Function to render discount cards for a given group
    const renderDiscountCards = (group: DiscountOption[]) => {
        return group.map((discount: DiscountOption, index: number) => {
            // const isDisabled = !!discount.min_quantity && (!quantity || quantity < discount.min_quantity);
            const isNotEnoughQuantity = !!discount.min_quantity && (!quantity || quantity < discount.min_quantity);
            const isNotDateValid = discount.valid_from && today.isBefore(discount.valid_from);

            const isDisabled = isNotEnoughQuantity || isNotDateValid;

            const priceToShow = discount.salePrice ?? unitPrice;

            return (
                <div
                    key={index}
                    className={`flex flex-row items-start max-w-sm p-4 border rounded-lg cursor-pointer ${
                        isDisabled
                            ? "bg-gray-200 cursor-not-allowed"
                            : discount.id === selectedDiscount?.id
                                ? "bg-purple-100"
                                : "bg-white hover:outline outline-1 outline-offset-0 outline-purple-300"
                    }`}
                    onClick={() => !isDisabled && onDiscountSelect(discount)}
                >
                    <FormControlLabel
                        className="flex-grow-0"
                        control={
                            <Radio
                                value={discount.id}
                                disabled={isDisabled}
                            />
                        }
                        label=""
                    />
                    <div className="flex-grow-1">
                        <div className="text-lg font-medium flex justify-between">
                            {discount?.name}
                            <BaseTooltip 
                                hidden={!isDisabled} 
                                title={
                                    isNotDateValid
                                        ? t("fields.validFrom.tooltip", { validFrom: dayjs(discount.valid_from).format("DD.MM.YYYY") }) as string
                                        : t("fields.minQuantity.tooltip", { minQuantity: discount.min_quantity }) as string
                                }
                            >
                                <AlertCircle className="h-6 w-6 text-accent" />
                            </BaseTooltip>
                        </div>
                        <div className="text-gray-600 font-semibold">
                            {t("fields.salePrice.label")}: {priceToShow}
                        </div>
                        {discount.min_quantity && (
                            <div className="text-gray-600 font-semibold">
                                {t("fields.minQuantity.label")}: {discount.min_quantity}
                            </div>
                        )}
                        <div className="text-gray-600 font-semibold">
                            {t("fields.discount.label")}: {discount.discount}%
                        </div>
                        <div className="text-gray-600">
                            {t("fields.calculatedPrice.label")}: {normalizePrice((priceToShow ?? 0) * (1 - (discount.discount ?? 0) / 100))}
                        </div>
                        {
                            (discount.valid_from || discount.valid_to) && (
                                <>
                                    <div className="text-gray-600">
                                        {t("fields.validFrom.label")}: {discount.valid_from && dayjs(discount.valid_from).format("DD.MM.YYYY")}
                                    </div>
                                    <div className="text-gray-600">
                                        {t("fields.validTo.label")}: {discount.valid_to ? dayjs(discount.valid_to).format("DD.MM.YYYY") : t("fields.validTo.noExpiration")}
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            );
        });
    };

    return (
        <RadioGroup
            name="discountOptions"
            value={selectedDiscount?.id}
            onChange={(event) => {
                return onDiscountSelect(discounts.find(discount => discount.id === event.target.value) as DiscountOption);
            }}
        >
            <BaseInputsGrid cols={2}>
                {renderSection(t("titles.pricesAndDiscounts"), priceDiscounts)}
                {renderSection(t("titles.generalCustomerDiscount"), customerDiscounts)}
                {renderSection(t("titles.customerGroup"), groupDiscounts)}
            </BaseInputsGrid>
        </RadioGroup>
    );

    function renderSection(title: string, discounts: DiscountOption[]) {
        return discounts.length > 0 && (
            <>
                <div className={`col-span-2 text-xl text-accent`}>
                    {title}
                </div>
                {renderDiscountCards(discounts)}
            </>
        );
    }
};

export default DiscountCard;