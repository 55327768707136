import React from "react";
import { AppDispatch } from "@redux/hooks";
import { productsApi } from "@redux/features/products/productsApi";
import _ from "lodash";
import { OrderStatus, SaleOrder } from "@/types/general";
import BaseTooltip from "@reusables/BaseTooltip";
import BaseTinyActionIcon, { BaseTinyActionIconProps, TinyActionIconType } from "@reusables/BaseTinyActionIcon";
import { TFunction } from "react-i18next";
import i18n from "@/i18n";
import { Actions, Modules, useAbility } from "@/casl.config";


export interface FlattenedLine {
    data: SaleOrder.Components.LineData; 
}[];

export function flattenLines(lines: SaleOrder.Components.Lines): FlattenedLine[] {
    return lines.flatMap(line => {
        if (line.type === "line") {
            return [{ data: line.data }];
        } else if (line.type === "group") {
            return line.data?.map(lineData => ({ data: lineData })) ?? [];
        } 
        return [];
    });
}

/**
 * This function is used to load Root products info. One of its main purposes is to extract the purchasing price to further pass it to the PO creation page.
 *
 * @param items - An array of objects, each containing the product_id, quantity_to_order, and an optional discount.
 * @param dispatch - A dispatch function from Redux.
 *
 * @returns A Promise that resolves to an array of objects. Each object contains detailed information about a product, including its id, name, code, quantity to order, unit price, and tax.
 *
 * @throws Will throw an error if the API call fails or if the payload from the API response is not found.
 */
export async function formPurchaseOrderLines(
    items: {
        product_id: number;
        quantity_to_order: number;
        discount?: number;
    }[],
    dispatch: AppDispatch
) {
    const productIds = items.map((item) => item.product_id);
    const itemsKeyedByProductId = _.keyBy(items, "product_id");

    let extendedProductsQuery;
    try {
        // Getting extended product info
        extendedProductsQuery = dispatch(
            productsApi.endpoints.getProductsFull.initiate({
                filters: {
                    ids: productIds
                },
                reactive: true
            })
        );

        const extendedProductsData = await extendedProductsQuery.unwrap();

        if (extendedProductsData.payload) {
            return extendedProductsData.payload
                .map((product) => {
                    const quantityToOrder = itemsKeyedByProductId[product.id].quantity_to_order;

                    return {
                        product: {
                            id: product.id,
                            name: product.name,
                            code: product.code
                        },
                        quantity: quantityToOrder,
                        unit_price: product.prices.purchase_price,
                        tax: product.tax
                    };
                });
        } else {
            throw new Error("No payload found");
        }
    } catch (e) {
        console.error(e);

        extendedProductsQuery?.unsubscribe();

        return Promise.reject(e);
    }
}

/**
 * Return a filtered (ones, that need to be purchased) and transformed (quantity is set to the needed number) array of order lines to be used in the refill modal.
 **/
export function formRefillModalLines(lines: SaleOrder.Root["lines"]) {
    // Finding lines, where ordered quantity is greater than in stock quantity
    // (meaning, we would like to create a purchase order for those lines to fulfill the sale order)
    const linesToRefill = flattenLines(lines).filter((order) => !order.data.product.is_service && order.data.quantity > (order.data.product.in_stock ?? 0));

    // If present, then we need to show a suggestion to refill low stock products
    return linesToRefill.map((line) => ({
        product: {
            id: line.data.product.id,
            name: line.data.product.name,
            code: line.data.product.code,
            has_bom: line.data.product.has_bom
        },
        quantity: line.data.quantity - (line.data.product.in_stock ?? 0)
    }));
}

export const statusOptions = [
    // { label: "New", value: OrderStatus.Base.new },
    // { label: "In progress", value: OrderStatus.Base.in_progress },
    // { label: "Completed", value: OrderStatus.Base.completed },
    { label: i18n.t("general.orders.statuses.base.cancelled"), value: OrderStatus.Base.cancelled },
];

interface EntityWithStatus {
    status: {
        name: OrderStatus.Base;
    };
}

export function isOrderDisabled(actionEntity?: EntityWithStatus): boolean {
    return (
        actionEntity?.status.name === OrderStatus.Base.cancelled ||
        actionEntity?.status.name === OrderStatus.Base.completed
    );
}

// This function is used to get the title for the cancel button tooltip
// Provided to avoid ternary operator complexity in the CancelOrderAction component
const getTooltipTitle = (t: TFunction, statusName?: string): string => {
    const statusMessages: Record<string, string> = {
      [OrderStatus.Base.cancelled]: "alreadyCancelled",
      [OrderStatus.Base.completed]: "alreadyCompleted",
      // Add more statuses here as needed
    };
  
    if (statusName && statusMessages[statusName]) {
      return t(`actions.cancellationNotAllowed.${statusMessages[statusName]}`);
    }
  
    return t("actions.cancel");
};

interface CancelOrderActionProps {
    actionEntity?: EntityWithStatus;
    action: Actions;
    module: Modules;
    t: TFunction;
    onClick: () => void;
    tinyActionIconVariant?: BaseTinyActionIconProps["variant"];
}

export function CancelOrderAction({ actionEntity, action, module, t, onClick, tinyActionIconVariant = "gradient" }: CancelOrderActionProps) {
    const ability = useAbility();

    return (
        <>
            <BaseTooltip
                active={!isOrderDisabled(actionEntity)}
                title={t("actions.cancel") || ""}
                placement="bottom"
            >
                <BaseTooltip
                    active={isOrderDisabled(actionEntity)}
                    title={getTooltipTitle(t, actionEntity?.status.name)}
                    placement="bottom"
                >
                    <BaseTinyActionIcon
                        type={TinyActionIconType.Cancel}
                        onClick={() => {
                            if (ability.can(action, module) && !isOrderDisabled(actionEntity)) {
                                onClick();
                            }
                        } }
                        variant={tinyActionIconVariant}
                        className={!isOrderDisabled(actionEntity)
                            ? ""
                            : "opacity-20 cursor-not-allowed"} />
                </BaseTooltip>
            </BaseTooltip>
        </>
    )
}