import { internalApiSlice, transformResponse } from "../../api/internalApiSlice";
import { Integration, PowerOffice, Tripletex, TwentyFourSevenOffice } from "@/types/general";

export const integrationsApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getIntegrations: builder.query<Integration.Root, void>({
            query: () => ({
                url: `integrations`
            }),
            providesTags: [{ type: "Integrations", id: "LIST" }],
            transformResponse
        }),

        connectIntegration: builder.mutation<Integration.ActiveState.Root, Integration.DTO.Create & {
            slug: string
        }>({
            query: ({ slug, ...body }) => ({
                url: `integrations/${slug}`,
                method: "POST",
                data: body
            }),
            invalidatesTags: [{ type: "Integrations", id: "LIST" }],
            transformResponse
        }),

        detachIntegration: builder.mutation<void, string>({
            query: (slug) => ({
                url: `integrations/${slug}`,
                method: "DELETE",
                data: slug
            }),
            invalidatesTags: [{ type: "Integrations", id: "LIST" }]
        }),

        getPOGOSalesAccounts: builder.query<PowerOffice.SalesAccounts.Root[], void>({
            query: () => ({
                url: `poweroffice/sales-accounts`
            }),
            transformResponse
        }),

        getPOGOProjects: builder.query<PowerOffice.Projects.Root[], void>({
            query: () => ({
                url: `poweroffice/projects`
            }),
            transformResponse
        }),

        getTripletexProjects: builder.query<Tripletex.Projects.Root[], void>({
            query: () => ({
                url: `tripletex/projects`
            }),
            transformResponse
        }),

        importTripletexData: builder.mutation<void, Tripletex.DTO.Import>({
            query: (data) => ({
                url: `data-transfer/integrations/tripletex/import`,
                method: "POST",
                data: {
                    entities: data.entities,
                    default_product_location: data.default_product_location
                }
            })
        }),

        importTwentyFoundSevenOfficeData: builder.mutation<void, TwentyFourSevenOffice.DTO.Import>({
            query: (data) => ({
                url: `data-transfer/integrations/twenty_four_seven_office/import`,
                method: "POST",
                data: {
                    entities: data.entities,
                    default_product_location: data.default_product_location
                }
            })
        }),

        importPOGOData: builder.mutation<void, { store_id: number; section_id?: number }>({
            query: (data) => ({
                url: `data-transfer/integrations/poweroffice/import`,
                method: "POST",
                data: {
                    default_product_location: data
                }
            })
        }),

        updatePOGOConfiguration: builder.mutation<Integration.ActiveState.PowerOfficeGO, PowerOffice.DTO.UpdateConfiguration>({
            query: (data) => ({
                url: `integrations/poweroffice/configuration`,
                method: "PUT",
                data
            }),
            transformResponse
        }),

        getTwentyFourSevenOfficeSalesAccounts: builder.query<TwentyFourSevenOffice.SalesAccounts.Root[], void>({
            query: () => ({
                url: `twenty_four_seven_office/sales-accounts`
            }),
            transformResponse
        }),

        updateTripletexConfiguration: builder.mutation<Integration.ActiveState.Tripletex, Tripletex.DTO.UpdateConfiguration>({
            query: (data) => ({
                url: `integrations/tripletex/configuration`,
                method: "PUT",
                data
            }),
            transformResponse
        })
    })
});


export const {
    useConnectIntegrationMutation,
    useGetIntegrationsQuery,
    useDetachIntegrationMutation,
    useGetPOGOSalesAccountsQuery,
    useImportTripletexDataMutation,
    useImportPOGODataMutation,
    useGetPOGOProjectsQuery,
    useUpdatePOGOConfigurationMutation,
    useUpdateTripletexConfigurationMutation,
    useGetTripletexProjectsQuery,
    useImportTwentyFoundSevenOfficeDataMutation,
    useGetTwentyFourSevenOfficeSalesAccountsQuery
} = integrationsApi;