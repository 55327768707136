import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetPurchaseOrderQuery } from "@redux/features/purchases/purchasesApi";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import ViewLayout from "../components/ViewLayout";

import PurchaseOrderDeletionModal
    from "@components/Dashboard/pages/PurchasingPage/PurchasesPage/components/modals/DeletePurchaseOrder";

import { Can, useAbility } from "@/casl.config";
import { BaseTinyActionIcon, TinyActionIconType } from "@reusables/BaseTinyActionIcon";
import { useExportOrderMutation } from "@redux/api/internalApiSlice";
import { downloadBlobExport } from "@helpers/utils";
import { useAppDispatch } from "@redux/hooks";
import { handleQuickReceive } from "@redux/features/receipts/thunks";
import BasePermissionBlocker from "@reusables/BasePermissionBlocker";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import BaseOrderStatusBadge from "@reusables/BaseOrderStatusBadge";
import { CancelOrderAction } from "@components/Dashboard/pages/Sales/Orders/utils";
import OrderCancellationModal from "../components/modals/CancellationOrderModal";

export default function PurchaseDetailsPage() {
    const history = useHistory();
    const {t} = useTranslation("", {keyPrefix: "purchaseOrder.mutations.details"});

    const ability = useAbility();

    const dispatch = useAppDispatch();

    const {id} = useParams<{ id: string }>();

    const {data: purchaseOrder, isLoading: isPurchaseOrderLoading} = useGetPurchaseOrderQuery(parseInt(id));

    const [isDeletionModalOpen, setIsDeletionModalOpen] = React.useState<boolean>(false);
    const [isCancellationModalOpen, setIsCancellationModalOpen] = React.useState<boolean>(false);

    const [exportPDF, {isLoading: isExportLoading}] = useExportOrderMutation();

    return (
        <>
            <Can not I="view" a="purchase_order">
                <div className="levitation-extended">
                    <NoPermissionBanner/>
                </div>
            </Can>

            <Can I="view" a="purchase_order">
                <BaseRecordRenderer
                    record={purchaseOrder}
                    loading={isPurchaseOrderLoading || isExportLoading}
                    hideLevitation
                >
                    <div className="space-y-[40px] mb-[24px] levitation-extended">
                        <div className="flex justify-between">
                            <div className="flex space-x-2 items-center">
                                <h6 className="text-accent grow m-auto">{t("heading")} #{purchaseOrder?.code ?? purchaseOrder?.id}</h6>
                                {purchaseOrder?.status.name && <BaseOrderStatusBadge status={purchaseOrder.status.name} />}
                            </div>
                            <div className="space-x-[8px] flex items-center h-full">
                                <BasePermissionBlocker action="purchase_order_pdf.download" module="purchase_order">
                                    <BaseTinyActionIcon
                                        type={TinyActionIconType.PDF}
                                        onClick={() =>
                                            ability.can("purchase_order_pdf.download", "purchase_order") && (
                                                exportPDF({id: purchaseOrder?.id as number, type: "purchase"})
                                                .unwrap()
                                                .then(data => {
                                                    downloadBlobExport(() => data, `purchase_order_${purchaseOrder?.code ?? purchaseOrder?.id}`, "pdf");
                                                })
                                            )
                                        }
                                    />
                                </BasePermissionBlocker>
                                <BasePermissionBlocker action="create" module="receive">
                                    <BaseTinyActionIcon
                                        type={TinyActionIconType.StartReceiving}
                                        onClick={() => {
                                            if (purchaseOrder?.id && ability.can("create", "receive")) {
                                                dispatch(handleQuickReceive(purchaseOrder.id, history))
                                            }
                                        }}
                                    />
                                </BasePermissionBlocker>
                                <BasePermissionBlocker action="edit" module="purchase_order">
                                    <BaseTinyActionIcon
                                        type={TinyActionIconType.Edit}
                                        onClick={() => ability.can("edit", "purchase_order") && void history.push(`/dashboard/purchasing/${purchaseOrder?.id}/edit`)}
                                    />
                                </BasePermissionBlocker>
                                <BasePermissionBlocker action="delete" module="purchase_order">
                                    <BaseTinyActionIcon
                                        type={TinyActionIconType.Delete}
                                        onClick={() => ability.can("delete", "purchase_order") && setIsDeletionModalOpen(true)}
                                    />
                                </BasePermissionBlocker>
                                <BasePermissionBlocker action="cancel" module="purchase_order">
                                    <CancelOrderAction
                                        actionEntity={purchaseOrder}
                                        action="cancel"
                                        module="purchase_order"
                                        t={t}
                                        onClick={() => setIsCancellationModalOpen(true)}
                                        tinyActionIconVariant="bordered"
                                    />
                                </BasePermissionBlocker>
                            </div>
                            {/* <div id="reference_number"/> */}
                        </div>
                        <ViewLayout.Main
                            purchaseOrder={purchaseOrder as NonNullable<typeof purchaseOrder>}
                        />
                    </div>
                    <ViewLayout.Receipts
                        purchaseOrder={purchaseOrder as NonNullable<typeof purchaseOrder>}
                    />
                </BaseRecordRenderer>
            </Can>

            <PurchaseOrderDeletionModal
                isOpen={isDeletionModalOpen}
                entity_id={parseInt(id)}
                onClose={() => setIsDeletionModalOpen(false)}
                onDeletion={() => history.push("/dashboard/purchasing")}
            />
            <OrderCancellationModal
                isOpen={isCancellationModalOpen} 
                entityId={parseInt(id)} 
                onClose={() => setIsCancellationModalOpen(false)}
                onCancellation={() => history.push("/dashboard/purchasing")} 
            />
        </>
    );
}
