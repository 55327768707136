import BaseImportModal from "@/components/reusables/BaseImportModal";
import React from "react";
import { useStartImportMutation } from "@redux/features/importApi";
import { toastError } from "@helpers/utils";
import { useGetImportableColumnsQuery } from "@redux/features/importExport/importExportApi";

interface StockImportModalProperties {
    isOpen?: boolean;
    onClose?: () => void;
}

export default function StockImportModal(props: StockImportModalProperties) {
    const [importProducts, { isLoading: isImportLoading }] = useStartImportMutation();

    const { data: columns = [], isLoading: isColumnsLoading } = useGetImportableColumnsQuery({ module: "stock" });

    return (
        <>
            <BaseImportModal
                optionsList={columns}
                isOpen={props.isOpen}
                onClose={props.onClose}
                isLoading={isImportLoading || isColumnsLoading}
                onImport={(formData) => {
                    importProducts({ module: "stock", formData }).unwrap()
                        .then(() => {
                            props.onClose?.();
                        })
                        .catch(e => {
                            console.error(e);
                            toastError(e);
                        });
                }}
            />
        </>
    );
}