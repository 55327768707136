import { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";

export type BaseInfiniteLoaderChildrenProps<T> = {
    /**
     * Complete list of lazy-loaded options.
     */
    data: T[];
    /**
     * Fired when the search if updated (when typing is finished).
     */
    onSearch: (value: string) => void;
    /**
     * Allows manual triggering of reloading.
     */
    trigger: () => void;
    /**
     * Tracks user typing in search field.
     */
    isTyping: boolean;
    /**
     * Returns `true` if the last `fetch.fn` returned no results (empty array).
     */
    hasNextPage: boolean;
    /**
     * Returns `true` if the last `fetch.fn` is currently loading.
     */
    isNextPageLoading: boolean;

    searchValue: string;
}

type BaseInfiniteLoaderProps<T> = {
    fetch: (search: string, page: number, limit: number) => void,
    result: T[];
    isLoading: boolean;
    limit?: number;
    children: (props: BaseInfiniteLoaderChildrenProps<T>) => JSX.Element;
    skip?: boolean | (() => boolean);
};

export default function BaseInfiniteLoader<T>({ children, limit = 100, ...props }: BaseInfiniteLoaderProps<T>) {
    const [options, setOptions] = useState<T[]>([]);
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(true);

    const [search, setSearch] = useState("");

    const [isTyping, setIsTyping] = useState(false);
    const debounceIsTyping = useRef(_.debounce(setIsTyping, 1500));

    const refetch = useCallback(() => {
        props.fetch(search, page, limit);
    }, [search, page, limit]);

    useEffect(() => {
        console.log("[Result] is triggered", props.result)
        setHasNextPage(props.result.length >= limit);

        if (props.result.length > 0) {
            setOptions([...options, ...props.result]);
            setPage(page + 1);
        }
    }, [props.result]);

    useEffect(() => {
        if (!isTyping) {
            setOptions([]);
            setPage(1);
            props.fetch(search, 1, limit);
        }
    }, [isTyping]);

    const response: BaseInfiniteLoaderChildrenProps<T> = {
        data: options,
        onSearch: (value) => {
            setIsTyping(true);
            setSearch(value);
            debounceIsTyping.current(false);
        },
        searchValue: search,
        trigger: () => refetch(),
        isTyping: isTyping,
        hasNextPage: hasNextPage,
        isNextPageLoading: props.isLoading
    };

    return children(response);
}