import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import ViewLayout from "../components/ViewLayout";

import { useGetSaleOrderQuery } from "@redux/features/sales/orders/salesOrdersApi";

import { Can, useAbility } from "@/casl.config";
import { BaseTinyActionIcon, TinyActionIconType } from "@reusables/BaseTinyActionIcon";
import { useExportOrderMutation } from "@redux/api/internalApiSlice";
import { downloadBlobExport } from "@helpers/utils";
import OrderDeletionModal from "../components/modals/DeleteOrder";
import BaseTooltip from "@reusables/BaseTooltip";
import BasePermissionBlocker from "@reusables/BasePermissionBlocker";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import BaseOrderStatusBadge from "@reusables/BaseOrderStatusBadge";
import { CancelOrderAction } from "../utils";
import BaseSyncedFromSourcesBadge from "@reusables/BaseSyncedFromSourcesBadge";
import OrderCancellationModal from "../components/modals/CancellationOrderModal";

export default function SaleOrderDetailsPage() {
    const history = useHistory();
    const { t } = useTranslation("", { keyPrefix: "sales.orders.mutations.details" });

    const ability = useAbility();

    const { id } = useParams<{ id: string }>();

    const { data: saleOrder, isLoading: isSaleOrderLoading } = useGetSaleOrderQuery(parseInt(id));

    const [isDeletionModalOpen, setIsDeletionModalOpen] = React.useState<boolean>(false);
    const [isCancellationModalOpen, setIsCancellationModalOpen] = React.useState<boolean>(false);

    const [exportPDF, { isLoading: isExportLoading }] = useExportOrderMutation();

    return (
        <>
            <Can not I="view" a="sale_order">
                <div className="levitation-extended">
                    <NoPermissionBanner />
                </div>
            </Can>

            <Can I="view" a="sale_order">
                <BaseRecordRenderer record={saleOrder} loading={isSaleOrderLoading || isExportLoading} hideLevitation>
                    <div className="space-y-[40px] mb-[24px] levitation-extended">
                        <div className="flex justify-between">
                            <div className="flex space-x-2 items-center">
                                <h6 className="text-accent grow m-auto">
                                    {t("heading")} #{saleOrder?.code ?? saleOrder?.id}
                                </h6>
                                {saleOrder?.status.name && <BaseOrderStatusBadge status={saleOrder.status.name} />}
                                <BaseSyncedFromSourcesBadge
                                    integrations={[
                                        {
                                            slug: "tripletex",
                                            integrated: () => !!saleOrder?.tripletex_id
                                        },
                                        {
                                            slug: "twenty_four_seven_office",
                                            integrated: () => !!saleOrder?.twenty_four_seven_office_id,
                                        },
                                        {
                                            slug: "lime",
                                            integrated: () => !!saleOrder?.lime_id
                                        },
                                        {
                                            slug: "poweroffice",
                                            integrated: () => !!saleOrder?.poweroffice_id
                                        }
                                    ]}
                                    width={24}
                                    height={24}
                                />
                            </div>
                            <div className="space-x-[8px] flex items-center h-full">
                                <BaseTinyActionIcon
                                    type={TinyActionIconType.PDF}
                                    onClick={() =>
                                        exportPDF({ id: saleOrder?.id as number, type: "sale" })
                                            .unwrap()
                                            .then((data) => {
                                                downloadBlobExport(() => data, `sales_order_${saleOrder?.code ?? saleOrder?.id}`, "pdf");
                                            })
                                    }
                                />
                                <BasePermissionBlocker action="create" module="picking">
                                    <BaseTooltip active={ability.can("create", "picking")} title="Create picking"
                                                 placement="bottom">
                                        <BaseTinyActionIcon type={TinyActionIconType.StartPicking}
                                                            onClick={() => ability.can("create", "picking") && void history.push(`/dashboard/picking/new`, { saleOrder: saleOrder })} />
                                    </BaseTooltip>
                                </BasePermissionBlocker>
                                <BasePermissionBlocker action="edit" module="sale_order">
                                    <BaseTooltip active={ability.can("edit", "sale_order")} title="Edit" placement="bottom">
                                        <BaseTinyActionIcon type={TinyActionIconType.Edit} onClick={() => ability.can("edit", "sale_order") && history.push(`/dashboard/sales/orders/${saleOrder?.id}/edit`)} />
                                    </BaseTooltip>
                                </BasePermissionBlocker>
                                <BasePermissionBlocker action="delete" module="sale_order">
                                    <BaseTooltip active={ability.can("delete", "sale_order")} title="Delete" placement="bottom">
                                        <BaseTinyActionIcon type={TinyActionIconType.Delete} onClick={() => ability.can("delete", "sale_order") && setIsDeletionModalOpen(true)} />
                                    </BaseTooltip>
                                </BasePermissionBlocker>
                                <BasePermissionBlocker action="cancel" module="sale_order">
                                    <CancelOrderAction
                                        actionEntity={saleOrder}
                                        action="cancel"
                                        module="sale_order"
                                        t={t}
                                        onClick={() => setIsCancellationModalOpen(true)}
                                        tinyActionIconVariant="bordered"
                                    />
                                </BasePermissionBlocker>
                            </div>
                        </div>
                        <ViewLayout.Main saleOrder={saleOrder as NonNullable<typeof saleOrder>} />
                    </div>

                    <ViewLayout.Pickings saleOrder={saleOrder as NonNullable<typeof saleOrder>} />
                </BaseRecordRenderer>
            </Can>

            <OrderDeletionModal isOpen={isDeletionModalOpen} entity_id={parseInt(id)} onClose={() => setIsDeletionModalOpen(false)} onDeletion={() => history.push("/dashboard/sales/orders")} />
            <OrderCancellationModal isOpen={isCancellationModalOpen} entityId={parseInt(id)} onClose={() => setIsCancellationModalOpen(false)} onCancellation={() => history.push("/dashboard/sales/orders")} />
        </>
    );
}
