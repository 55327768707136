import React from "react";
import {Box, Stack} from "@mui/material";
import BaseTable from "@reusables/BaseTable";
import {Controller, useFieldArray, useFormContext} from "react-hook-form";
import BaseInput from "@reusables/BaseInput";
import {ReceiptsMutationFormTyping} from "@components/Dashboard/pages/PurchasingPage/Receive/components/MutationLayout";
import FullCheckbox
    from "@components/Dashboard/pages/PurchasingPage/Receive/components/MutationLayout/parts/TableBody/checkboxes/FullCheckbox";
import LineCheckbox
    from "@components/Dashboard/pages/PurchasingPage/Receive/components/MutationLayout/parts/TableBody/checkboxes/LineCheckbox";
import {useTranslation} from "react-i18next";
import ReceiptLocationDropdown
    from "@components/Dashboard/pages/PurchasingPage/Receive/components/MutationLayout/parts/TableBody/ReceiptLocationDropdown";
import BaseBadge from "@reusables/BaseBadge";
import { ReactComponent as CollectionsIcon } from "@assets/icons/sidebar/ic_folders.svg";


export default function TableBody() {
    const {t} = useTranslation("", {keyPrefix: "receive.mutation"});

    const {control, setValue} = useFormContext<ReceiptsMutationFormTyping>()

    const {fields: receipts} = useFieldArray({
        control,
        name: "receipts"
    })

    return (
        <BaseTable
            hideTableWhenNothingFound
            data={receipts}
            rowKeyGetter={row => row.id}
            columns={[
                // Selection checkbox indicator
                {
                    header: <FullCheckbox/>,
                    getter: (row, index) => row.order.data 
                    ? <LineCheckbox index={index}/> 
                    : <div className="flex items-center">
                    <div className="w-1 h-8 bg-[#B49FFB] rounded-sm mr-2"></div>
                        <div className="ml-3 absolute min-w-[120px] flex space-x-2">
                            <div>{row.order.name}</div>
                            <BaseBadge 
                                className="px-2 bg-[#CDCFFD] rounded-xl"
                            >
                                <div className="flex items-center">
                                    <CollectionsIcon className="mr-1 bg-[#CDCFFD] text-accent" />
                                    {row.order.module === "collection" ? "Collection" : "BOM"}
                                </div>
                            </BaseBadge>
                        </div>
                    </div>,
                    sx: {
                        width: "40px"
                    }
                },
                // Product code
                {
                    header: t("products.columns.0"),
                    getter: (row) => row.order.data ? 
                            row.order.type === "group" ?
                            <div className="flex items-center">
                                <div className="self-center w-[2px] h-[20px] bg-[#D9D6DA] rounded-sm mr-2"></div> 
                                {row.order?.data?.product.code}
                            </div>
                        : row.order?.data?.product.code
                        : "",
                    sx: {
                        width: "150px"
                    }
                },
                // Product name
                {
                    header: t("products.columns.1"),
                    getter: (row) => row.order?.data?.product.name ?? "",
                },
                // Location
                {
                    header: t("products.columns.2"),
                    getter: (row, index) => {
                        if(!row.order?.data?.product.is_service && !!row.order.data){
                            return <ReceiptLocationDropdown product_id={row.order?.data?.product.id} index={index}/>
                        }else{
                            return <></>
                        }
                    },
                    sx: {
                        minWidth: "300px"
                    }
                },
                // Received quantity
                {
                    header: t("products.columns.3"),
                    getter: (row, index) => row.order.data ?
                        <Stack direction={"row"} spacing={1} alignItems="center">
                            <Box width={80}>
                                <Controller
                                    name={`receipts.${index}.received_quantity`}
                                    control={control}
                                    render={({field, fieldState}) =>
                                        <BaseInput
                                            {...field}

                                            error={fieldState.error}

                                            type={"number"}
                                            hideErrors
                                            sx={{
                                                ".MuiInput-input": {
                                                    padding: "8px"
                                                }
                                            }}
                                        />
                                    }
                                />
                            </Box>
                            <span>
                                {t("products.receivedQuantityOf")} <span className="font-semibold">{row.available_quantity}</span>
                            </span>
                        </Stack> : "",
                    sx: {
                        width: "200px"
                    }
                }
            ]}
            rowProps={(row) => {
                if (row.order.type === "group" && !row.order.data) {
                    return {
                        className: "group-header"
                    };
                }
            
                if (row.order.type === "group" && !!row.order.data) {
                    return { className: "group-cell" };
                }
                
                return {};
            }}
            sx={{
                "& .MuiTableBody-root": {
                    position: "relative",
                    "&::before": {
                        content: '""',
                        display: "block",
                        height: "16px",  // Height of the spacing
                        backgroundColor: "transparent", // Transparent to keep it invisible
                    },
                },
                "& .MuiTableRow-root:first-of-type .MuiTableCell-root": {
                    borderTop: "none",
                },
                "& .group-cell .MuiTableCell-root": {
                    padding: "4px 16px"
                },
                "& .group-header .MuiTableCell-root": {
                    backgroundColor: "#EEF2FF",
                },
                "& .group-header .MuiTableCell-root:first-of-type": {
                    borderTopLeftRadius: "8px",
                    borderBottomLeftRadius: "8px",
                },
                "& .group-header .MuiTableCell-root:last-of-type": {
                    borderTopRightRadius: "8px",
                    borderBottomRightRadius: "8px",
                },
            }}
            headerSx={{
                background: "rgba(249, 249, 249, 1)"
            }}
            nothingFound={{
                text: t("products.noProducts")
            }}
            size="small"
        />
    )
}