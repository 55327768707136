import React, { useEffect } from "react";
import BaseButton from "@reusables/BaseButton";
import BaseModal from "@reusables/Modals/BaseModal";
import { Trans, useTranslation } from "react-i18next";
import { useApproveCountMutation } from "@redux/features/stockCount/stockCountApi";
import { toast } from "react-toastify";
import { StockCount } from "@/types/general";
import _ from "lodash";
import BaseMaterialCheckbox from "@reusables/BaseMaterialCheckbox";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import BaseInput from "@reusables/BaseInput";
import BaseBadge from "@reusables/BaseBadge";
import { toastError } from "@helpers/utils";
import { Alert, Collapse } from "@mui/material";
import { isStockCountApprovalActive } from "@redux/features/stockCount/types";
import { finishStockCountApproval, startStockCountApproval } from "@redux/features/stockCount/stockCountSlice";

interface ApproveModalProperties {
    isOpen: boolean;
    onClose: () => void;
    report?: StockCount.Root;
}

const formSchema = z.object({
    email: z.string().email(),
    shouldZeroOutStock: z.boolean(),
    verificationWord: z.object({
        required: z.string(),
        entered: z.string()
    }).refine((schema) => {
            return schema.required != "" && schema.required === schema.entered;
        }, {
            message: "Verification word does not match"
        }
    )
});

type FormSchemaTyping = z.infer<typeof formSchema>;

export default function ApproveModal(props: ApproveModalProperties) {
    const { t } = useTranslation("", { keyPrefix: "inventory.stockCount.modals.approve" });

    const dispatch = useAppDispatch();

    const isStockCountApprovalInProgress = useAppSelector(state => isStockCountApprovalActive(state.stockCount.status));

    const [approveReport, { isLoading: isApprovingLoading }] = useApproveCountMutation();

    const worker = _.join([props.report?.user.first_name, props.report?.user.last_name], " ");

    const userEmail = useAppSelector(state => state.auth.user?.email);

    const form = useForm<FormSchemaTyping>({
        resolver: zodResolver(formSchema),
        reValidateMode: "onChange",
        defaultValues: {
            shouldZeroOutStock: false
        }
    });

    useEffect(() => {
        if (props.isOpen) {
            if (props.report) {
                form.setValue("email", userEmail!);
                form.setValue("shouldZeroOutStock", false);
                form.setValue("verificationWord", {
                    required: props.report.code,
                    entered: ""
                });
            }
        } else {
            form.reset();
        }
    }, [props.report, userEmail]);

    const onSubmit = form.handleSubmit((data) => {
        if (props.report) {
            dispatch(startStockCountApproval());
            approveReport({
                id: props.report.id,
                email: data.email,
                zero_count: data.shouldZeroOutStock
            })
                .unwrap()
                .then(() => {
                    toast.info(t("responses.success"));
                })
                .catch((e) => {
                    console.error(e);
                    toastError(t("responses.error"));

                    dispatch(finishStockCountApproval({ has_errors: true }));
                })
                .finally(() => props.onClose());
        }
    }, console.error);


    return (
        <>
            <BaseModal
                isOpen={props.isOpen}
                onClose={props.onClose}
                width={700}
                padding="56px"
                isLoading={isApprovingLoading}
            >
                <form className="space-y-[32px]" onSubmit={onSubmit}>
                    <div className="text-xl font-semibold text-center text-accent">
                        {t("heading")}
                    </div>
                    <div className="text-center text-accent font-thin">
                        <Trans
                            i18nKey="subheading" t={t}
                            components={{
                                badge: <BaseBadge className="bg-gray-200 font-semibold" />,
                                bold: <span className="font-semibold" />
                            }}
                            values={{
                                reportId: props.report?.code ?? props.report?.id,
                                worker
                            }}
                        />
                    </div>
                    <div className="space-y-[16px]">
                        <div>
                            <Controller
                                control={form.control}
                                name="verificationWord.entered"
                                render={({ field, fieldState }) => (
                                    <BaseInput
                                        {...field}
                                        error={fieldState.error}
                                        label={t("verificationWord.label") + " *"}
                                        placeholder={props.report?.code}
                                    />
                                )}
                            />
                        </div>
                        <Collapse in={isStockCountApprovalInProgress}>
                            <Alert severity="warning">
                                {t("stockCountApprovalInProgressAlert")}
                            </Alert>
                        </Collapse>
                        <div>
                            <Controller
                                control={form.control}
                                name="email"
                                render={({ field, fieldState }) => (
                                    <BaseInput
                                        {...field}
                                        error={fieldState.error}
                                        label={t("email.label") + " *"}
                                        placeholder={t("email.placeholder")}
                                    />
                                )}
                            />
                            <div className={"text-secondary-gray text-xs mt-2"}>
                                {t("email.tip")}
                            </div>
                        </div>
                        <div>
                            <Controller
                                control={form.control}
                                name="shouldZeroOutStock"
                                render={({ field }) => (
                                    <div
                                        className="flex items-center space-x-2 cursor-pointer"
                                        onClick={() => field.onChange(!field.value)}
                                    >
                                        <BaseMaterialCheckbox checked={field.value} />
                                        <span>{t("zeroOutStock.label")}</span>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-[16px]">
                        <BaseButton text={t("buttons.cancel")} size="md" primaryOutlined onClick={props.onClose}
                                    type={"button"} />
                        <BaseButton
                            text={t("buttons.confirm")}
                            size="md"
                            disabled={!form.formState.isValid || isApprovingLoading}
                        />
                    </div>
                </form>
            </BaseModal>

        </>
    );
}
