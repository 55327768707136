import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

// MUI
import { IconButton, Menu } from "@mui/material";

// icons
import { ReactComponent as UserIconSvg } from "@assets/images/icons/dashboard/user.svg";

// hooks
// reusables
import LanguageChanger from "../LanguageChanger";
import NavigationCard from "./NavigationCard";

// translation
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import BaseMaterialButton from "@reusables/BaseMaterialButton";

import "./index.scss";
import { useAppDispatch } from "@redux/hooks";
import { logout } from "@redux/features/auth/authSlice";
import BaseMicroUtils from "@reusables/BaseMicroUtils";
import NavStatHeader from "@components/DashboardNav/components/NavStatHeader";
import ImportIndicator from "@components/DashboardNav/components/ImportIndicator";
import ExportIndicator from "@components/DashboardNav/components/ExportIndicator";
import PriceReconversionIndicator from "./components/PriceReconversionIndicator";
import { useAbility } from "@/casl.config";
import StockCountIndicator from "@components/DashboardNav/components/StockCountIndicator";

function DashboardNav() {
    const { t, i18n } = useTranslation();

    const history = useHistory();

    const dispatch = useAppDispatch();
    const ability = useAbility();

    // notification menu
    const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
    const [notificationMenuAnchorEl, setNotificationMenuAnchorEl] =
        useState<HTMLAnchorElement | HTMLElement | null>(null);
    const closeNotificationMenu = () => {
        setNotificationOpen(false);
        setNotificationMenuAnchorEl(null);
    };

    // user-menu
    const [openUserActions, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | HTMLElement | null>(null);
    const closeUserPopover = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    return (
        <nav className="flex content-between gap-[16px] mb-[24px]">
            <div className="2xl:w-8/12 md:w-7/12 sm:w-full flex items-stretch content-between space-x-[16px]">
                <NavigationCard className="px-[16px]">
                    <LanguageChanger />
                </NavigationCard>
                <NavStatHeader />
            </div>

            <div className="2xl:w-4/12 md:w-5/12 sm:w-full">
                <div className="flex justify-between items-stretch space-x-[16px] h-full">
                    <NavigationCard className="grow">
                        <div className="flex items-center w-full">
                            <div className="font-semibold tracking-wide text-accent mr-[16px] grow headerSplit:text-sm">
                                {dayjs().format("ddd, D MMM YYYY")}
                            </div>
                            <div
                                className="w-[80px] py-[4px] px-[8px] text-center text-white text-sm font-thin bg-[linear-gradient(90.89deg,#8850F4_0.67%,_#6959FC_99.24%)] rounded-[8px]">
                                <BaseMicroUtils.CurrentTime refreshInMilliseconds={60000}
                                                            transform={d => d.format("hh:mm A")} />
                            </div>
                        </div>
                    </NavigationCard>

                    <div className="flex items-center">
                        <ImportIndicator />
                        <ExportIndicator />
                        {ability.can("price.reconversion", "product") && (
                            <PriceReconversionIndicator />
                        )}
                        <StockCountIndicator />
                        <span className={`text-center w-[50px] ${openUserActions ? "levitation rounded-xl" : ""}`}>
                            <IconButton onClick={(event) => {
                                setOpen((prevValue) => !prevValue);
                                setAnchorEl(event.currentTarget.parentElement);
                            }
                            } className={`w-[40px]`}>
                                <UserIconSvg />
                            </IconButton>
                        </span>
                    </div>
                </div>
            </div>

            <Menu
                id={openUserActions ? "user-popover" : undefined}
                open={openUserActions}
                anchorEl={anchorEl}
                onClose={closeUserPopover}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                sx={{ borderRadius: "20px", marginTop: "10px" }}
            >
                <div className="py-[24px] px-[14px]">
                    <ul className="space-y-[3px] account-dropdown-ul">
                        <li className="py-0">
                            <Link
                                className="block py-[10px]"
                                onClick={closeUserPopover}
                                to={"/dashboard/settings/general/account/profile"}>{t("dashboard.navbar.userMenu.myAccount")}
                            </Link>
                        </li>
                        <li className="py-0">
                            <Link 
                                className="block py-[10px]"
                                onClick={closeUserPopover} 
                                to={"/dashboard/settings/company/details"}>{t("dashboard.navbar.userMenu.companyInfo")}
                            </Link>
                        </li>
                        {/*<li><Link to={"/dashboard/settings/general/account/profile"}>{t("dashboard.navbar.userMenu.subscription")}</Link></li>*/}
                    </ul>
                    <div className="mt-[3px]">
                        <BaseMaterialButton
                            className="w-full" fontWeight={600}
                            onClick={() => {
                                dispatch(logout());
                            }}
                        >{t("dashboard.navbar.userMenu.action")}</BaseMaterialButton>
                    </div>
                </div>
            </Menu>
        </nav>
    );
}

export default DashboardNav;
