import {
    ExportRequest,
    flattenExportRequest,
    flattenPaginationRequest,
    internalApiSlice,
    PaginationRequest,
    PaginationResponse,
    transformResponse
} from "@redux/api/internalApiSlice";
import { StockCount } from "@/types/general";

export const stockCountApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getStockCounts: builder.query<PaginationResponse<StockCount.Root>, PaginationRequest<StockCount.DTO.Filters, StockCount.DTO.OrderBy>>({
            query: (req) => ({
                url: `stock-count`,
                params: {
                    ...flattenPaginationRequest(req)
                },
                parseDates: ["date"]
            }),
            providesTags: (result, error, page) =>
                result
                    ? [
                        ...result.payload.map(({ id }) => ({ type: "StockCount" as const, id })),
                        { type: "StockCount", id: "PARTIAL-LIST" }
                    ]
                    : [{ type: "StockCount", id: "PARTIAL-LIST" }]
        }),

        approveCount: builder.mutation<void, {id: number} & StockCount.DTO.Approve>({
            query: ({id, ...data}) => ({
                url: `stock-count/approve/${id}`,
                method: "PUT",
                data: data
            }),
            transformResponse,
        }),

        declineCount: builder.mutation<void, { id: number, comment?: string }>({
            query: ({ id, ...body }) => ({
                url: `stock-count/decline/${id}`,
                method: "PUT",
                data: {
                    decline_comment: body.comment
                }
            }),
            invalidatesTags: (result, error, args) => [{ type: "StockCount", id: args.id }],
            transformResponse
        }),

        mergeCounts: builder.mutation<void, { merging: number[] }>({
            query: ({ merging }) => ({
                url: `stock-count/merge`,
                method: "POST",
                data: {
                    merging
                }
            }),
            invalidatesTags: [
                "StockCount",
                "Picking",
                "Adjustment",
                "Transfer"
            ],
            transformResponse
        }),

        exportStockCounts: builder.mutation<void, ExportRequest<StockCount.DTO.ExportFilters>>({
            query: (args) => ({
                url: `stock-count/export`,
                responseType: "blob",
                method: "POST",
                data: flattenExportRequest(args)
            })
        }),

        downloadRollbackFile: builder.query<Blob, number>({
            query: (id) => ({
                url: `stock-count/${id}/rollback-file`,
                method: "GET",
                responseType: "blob"
            })
        })
    })
});

export const {
    useGetStockCountsQuery,
    useApproveCountMutation,
    useDeclineCountMutation,
    useExportStockCountsMutation,
    useMergeCountsMutation,
    useDownloadRollbackFileQuery
} = stockCountApi;