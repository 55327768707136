import {Receive} from "@/types/general";
import React, {useMemo} from "react";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import BaseTable from "@reusables/BaseTable";
import {ReactComponent as DetailsSVG} from "@assets/icons/ic_document_search.svg";
import {Stack} from "@mui/material";
import SerialNumbersModal
    from "@components/Dashboard/pages/PurchasingPage/Receive/components/modals/SerialNumbersModal";
import BatchNumbersModal from "@components/Dashboard/pages/PurchasingPage/Receive/components/modals/BatchNumbersModal";
import BaseBadge from "@/components/reusables/BaseBadge";
import { ReactComponent as CollectionsIcon } from "@assets/icons/sidebar/ic_folders.svg";

interface ViewLayoutProperties {
    receipt: Receive.Extended;
}

export default function ViewLayout({receipt}: ViewLayoutProperties) {
    const {t} = useTranslation("", {keyPrefix: "receive.details"});

    const transformedLines = useMemo(() => {
        return receipt.receives.flatMap(receive => {
            if (receive.type === "line") {
                return [receive];
            } else if (receive.type === "group" && Array.isArray(receive.data)) {
                return [
                    {
                        type: "group",
                        name: receive.name,
                        module: receive.module,
                        data: undefined
                    },
                    ...receive.data.map(line => ({
                        ...receive,
                        data: line
                    }))
                ];
            }
            return [];
        });
    }, [receipt]);

    // Filtering receipts with serial numbers and batch numbers to show "open serials modal" or "open batches modal"
    const serialNumbersReceipts = useMemo(() => {
        return transformedLines?.filter(receive => receive.data?.serial_numbers?.length);
    }, [receipt]);

    const batchNumbersReceipts = useMemo(() => {
        return transformedLines.filter(receive => receive.data?.batch_numbers?.length);
    }, [receipt]);

    const [serialNumberModalOpen, setSerialNumberModalOpen] = React.useState(false);
    const [batchNumberModalOpen, setBatchNumberModalOpen] = React.useState(false);

    return (
        <>
            <div className="space-y-[40px]">
                <BaseInputsGrid>
                    <InputLikeContainer
                        label={t("fields.supplier")}
                        value={receipt.supplier.name}
                    />
                    <InputLikeContainer
                        label={t("fields.purchaseOrder")}
                        value={receipt.purchase_order.code ?? receipt.purchase_order.id}
                        linkValue={`/dashboard/purchasing/${receipt.purchase_order.id}/details`}
                    />
                    <InputLikeContainer
                        label={t("fields.receiveDate")}
                        value={receipt.date.format("DD.MM.YYYY")}
                    />
                </BaseInputsGrid>
                <div className="border border-solid border-gray-300 rounded-[8px] p-[16px]">
                    <div className="flex mb-[46px] items-center space-x-[8px]">
                        <div className="text-xl font-thin text-gray-600 grow">{t("products.heading")}</div>
                        <Stack direction={"row"} spacing={3}>
                            {
                                serialNumbersReceipts.length > 0 &&
                                <Stack
                                    direction={"row"}
                                    spacing={1}
                                    className={"hover:text-accent cursor-pointer"}
                                    onClick={() => setSerialNumberModalOpen(true)}
                                >
                                    <span>{t("products.modalReferences.serialNumbers")}</span>
                                    <DetailsSVG />
                                </Stack>
                            }
                            {
                                batchNumbersReceipts.length > 0 &&
                                <Stack
                                    direction={"row"}
                                    spacing={1}
                                    className={"hover:text-accent cursor-pointer"}
                                    onClick={() => setBatchNumberModalOpen(true)}
                                >
                                    <span>{t("products.modalReferences.batchNumbers")}</span>
                                    <DetailsSVG />
                                </Stack>
                            }
                        </Stack>
                    </div>
                    <BaseTable
                        hideTableWhenNothingFound
                        data={transformedLines}
                        columns={[
                            {
                                header: t("products.columns.0"),
                                getter: (row) => row.data ?
                                    row.type === "group" ?
                                        <div className="flex items-center">
                                            <div className="self-center w-[2px] h-[20px] bg-[#D9D6DA] rounded-sm mr-2"></div>
                                            {row.data?.order.product.code}
                                        </div>
                                        : row.data?.order.product.code
                                    : row.type === "group" ?
                                    <div className="flex items-center">
                                        <div className="w-1 h-8 bg-[#B49FFB] rounded-sm mr-2"></div>
                                        <div className="ml-3 absolute min-w-[120px] flex space-x-2">
                                            <div>{row.name}</div>
                                            <BaseBadge
                                                className="px-2 bg-[#CDCFFD] rounded-xl"
                                            >
                                                <div className="flex items-center">
                                                    <CollectionsIcon className="mr-1 bg-[#CDCFFD] text-accent"/>
                                                    {row.module === "collection" ? "Collection" : "BOM"}
                                                </div>
                                            </BaseBadge>
                                        </div>
                                    </div>
                                    : "",
                                sx: { minWidth: "150px" }
                            },
                            {
                                header: t("products.columns.1"),
                                getter: (row) => row.data?.order.product.name ?? ""
                            },
                            {
                                header: t("products.columns.2"),
                                getter: (row, index) => row.data && `${row.data?.location.store.name}${row.data?.location.section?.name ? ` - ${row.data?.location.section.name}` : ""}`
                            },
                            {
                                header: t("products.columns.3"),
                                getter: (row, index) => row.data?.received_quantity,
                                sx: {
                                    width: "200px"
                                }
                            }
                        ]}
                        rowProps={(row) => {
                            if (row.type === "group" && !row.data) {
                                return {
                                    className: "group-header"
                                };
                            }
                        
                            if (row.type === "group" && !!row.data) {
                                return { className: "group-cell" };
                            }
                            
                            return {};
                        }}
                        sx={{
                            "& .MuiTableBody-root": {
                                position: "relative",
                                "&::before": {
                                    content: '""',
                                    display: "block",
                                    height: "16px",  // Height of the spacing
                                    backgroundColor: "transparent", // Transparent to keep it invisible
                                },
                            },
                            "& .group-cell .MuiTableCell-root": {
                                padding: "4px 16px"
                            },
                            "& .group-header .MuiTableCell-root": {
                                backgroundColor: "#EEF2FF",
                            },
                            "& .group-header .MuiTableCell-root:first-of-type": {
                                borderTopLeftRadius: "8px",
                                borderBottomLeftRadius: "8px"
                            },
                            "& .group-header .MuiTableCell-root:last-of-type": {
                                borderTopRightRadius: "8px",
                                borderBottomRightRadius: "8px",
                            },
                        }}
                        headerSx={{
                            background: "rgba(249, 249, 249, 1)"
                        }}
                        nothingFound={{
                            text: t("products.noProducts")
                        }}
                        size="small"
                    />
                </div>
            </div>

            {/* Serial numbers modal */}
            {
                serialNumbersReceipts.length > 0 &&
                <SerialNumbersModal
                    isOpen={serialNumberModalOpen}
                    onClose={() => setSerialNumberModalOpen(false)}
                    receipts={serialNumbersReceipts.map(receive => ({
                        ...receive.data,
                        order: {
                            id: receive.data.order.id,
                            data: receive.data.order
                        }
                    }))}
                    preview
                />
            }

            {/* Batch numbers modal */}
            {
                batchNumbersReceipts.length > 0 &&
                <BatchNumbersModal
                    isOpen={batchNumberModalOpen}
                    onClose={() => setBatchNumberModalOpen(false)}
                    receipts={batchNumbersReceipts.map(receive => ({
                        ...receive.data,
                        order: {
                            id: receive.data.order.id,
                            data: receive.data.order
                        }
                    }))}
                    preview
                />
            }
        </>
    );
}

function InputLikeContainer({label, value, linkValue}: {
    label: string,
    value: string | number | undefined,
    linkValue?: string
}) {
    return (
        <div>
            <div className="font-semibold text-inputs-label-dim mb-[24px]">{label}</div>
            {
                linkValue ?
                    <Link to={linkValue} className={"text-accent text-decoration-underline"}>{value}</Link>
                    :
                    <div className="text-accent">
                        {value}
                    </div>
            }
        </div>
    );
}