import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StockCountApprovalStatus } from "@redux/features/stockCount/types";

const initialState = {
    status: StockCountApprovalStatus.IDLE,
    has_errors: false
};

export const stockCountSlice = createSlice({
    name: "stock_count",
    initialState: initialState,
    reducers: {
        startStockCountApproval: (state) => {
            state.status = StockCountApprovalStatus.ACTIVE;
        },

        finishStockCountApproval: (state, action: PayloadAction<{ has_errors: boolean }>) => {
            state.status = StockCountApprovalStatus.FINISHED;
            state.has_errors = action.payload.has_errors;
        },

        resetStockCountApproval: (state) => {
            state.status = StockCountApprovalStatus.IDLE;
            state.has_errors = false;
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    startStockCountApproval,
    finishStockCountApproval,
    resetStockCountApproval
} = stockCountSlice.actions;

export default stockCountSlice.reducer;