import React from 'react'
import {useTranslation} from 'react-i18next';
import {Users} from '@/types/general';
import {useDeleteUserMutation} from '@redux/features/users/usersApi';
import BaseModal from '@reusables/Modals/BaseModal';
import BaseButton from '@reusables/BaseButton';
import {toast} from 'react-toastify';
import { toastError } from '@helpers/utils';

interface DeletionModalProperties {
  isOpen: boolean;
  onClose: () => void;
  user?: Users.Root;
}

export default function DeletionModal(props: DeletionModalProperties) {
  const {t} = useTranslation('', {keyPrefix: 'settings.general.companyInformation.tabs.users.modals.deletion'});

  const [deleteUser, {isLoading: isDeletionLoading}] = useDeleteUserMutation();

  return (
    <BaseModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      width={700}
      padding="56px"
      isLoading={isDeletionLoading}
    >
      <div className="space-y-[32px]">
        <div className="text-xl font-semibold text-center text-accent">{t('heading')}</div>
        <div className="text-center text-accent">
          {`${t("subheading")} `} {<span className="font-semibold">{`${props.user?.first_name} ${props.user?.last_name}`}?</span>}
        </div>
        <div className="grid grid-cols-2 gap-[16px]">
          <BaseButton text={t('buttons.cancel')} size="md" primaryOutlined onClick={props.onClose}/>
          <BaseButton text={t('buttons.delete')} size="md" onClick={() => {
            if (props.user) {
              deleteUser(props.user.id).unwrap()
              .then(() => {
                toast.success(t('responses.success'));
              }).catch((err) => {
                toastError(err);
              }).finally(() => props.onClose());
            }
          }}/>
        </div>
      </div>
    </BaseModal>
  );
}
