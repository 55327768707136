import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    selectCategoryByCode,
    useCreateCategoryMutation,
    useUpdateCategoryMutation
} from "@redux/features/categories/categoriesApi";
import { Category } from "@/types/general";

import BaseModal from "@reusables/Modals/BaseModal";
import BaseInput from "@reusables/BaseInput";
import BaseButton from "@reusables/BaseButton";
import { toast } from "react-toastify";

import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { globalStore } from "@redux/store";
import BaseCheckbox from "@reusables/BaseCheckbox";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { removeEmpty } from "@helpers/utils";
import PowerofficeIntegrationSection
    from "@components/Dashboard/pages/SettingsPage/General/Categories/modals/MutationModal/PowerofficeIntegrationSection";
import { integrationsApi } from "@redux/features/integrations/integrationsApi";
import { isSystemIntegratedSelector } from "@redux/features/auth/authSlice";
import TwentyFourSevenOfficeIntegrationSection from "./TwentyFourSevenOfficeIntegrationSection";

interface MutationModalProperties {
    isOpen: boolean;
    onClose: () => void;
    category?: Category.Root;
}

const formScheme = z.object({
    code: z.object({
        value: z.string().optional(),
        checkable: z.boolean().optional()
    }).refine((code) => {
        return code.checkable ? selectCategoryByCode(globalStore.getState(), code.value) === undefined : true;
    }, {
        params: {
            i18n: "general.validation.custom.code.unique"
        },
        path: ["value"]
    }),
    name: z.string(),
    is_service: z.boolean(),
    integrations: z.object({
        poweroffice: z.object({
            sales_account: z.object({
                id: z.number(),
                code: z.number(),
                name: z.string()
            }).nullish()
        }).nullish(),
        twenty_four_seven_office: z.object({
            sales_account: z.object({
                id: z.number(),
                name: z.string(),
                number: z.number()
            }).nullish()
        }).nullish()
    })
});

export type CategoryMutationFormTyping = z.infer<typeof formScheme>

export default function MutationModal(props: MutationModalProperties) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.categories.modals.mutation" });

    const dispatch = useAppDispatch();
    const isPowerOfficeIntegrated = useAppSelector(isSystemIntegratedSelector("poweroffice"));
    const isTwentyFourSevenOfficeIntegrated = useAppSelector(isSystemIntegratedSelector("twenty_four_seven_office"));

    // Getting RTK Query hooks for saving data
    const [createCategory, { isLoading: isCreationLoading }] = useCreateCategoryMutation();
    const [updateCategory, { isLoading: isUpdateLoading }] = useUpdateCategoryMutation();

    // Getting form methods from react-hook-form
    const { control, handleSubmit, setValue, reset } = useForm<CategoryMutationFormTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            code: {
                checkable: true
            },
            is_service: false,
            integrations: {}
        }
    });

    // Updating values depending on the selected category
    useEffect(() => {
        reset();

        if (props.category) {
            setValue("code.value", props.category.code);
            setValue("code.checkable", false);
            setValue("name", props.category.name);
            setValue("is_service", props.category.is_service);

            const categoryPoweroffice = props.category.integrations.poweroffice;
            if (categoryPoweroffice) {
                dispatch(integrationsApi.endpoints.getPOGOSalesAccounts.initiate(undefined, {
                    subscribe: false
                })).unwrap()
                    .then(sas => {
                        const salesAccount = sas.find(sa => sa.id == categoryPoweroffice.sales_account_id);
                        if (salesAccount) {
                            setValue("integrations.poweroffice.sales_account", salesAccount);
                        }
                    })
                    .catch(console.error);
            }

            const category24SO = props.category.integrations.twenty_four_seven_office;
            if (category24SO) {
                dispatch(integrationsApi.endpoints.getTwentyFourSevenOfficeSalesAccounts.initiate(undefined, {
                    subscribe: false
                })).unwrap()
                    .then(sas => {
                        const salesAccount = sas.find(sa => sa.id == category24SO.sales_account_id);
                        if (salesAccount) {
                            setValue("integrations.twenty_four_seven_office.sales_account", salesAccount);
                        }
                    })
                    .catch(console.error);
            }
        }
    }, [props.category, reset, props.isOpen]);

    const onSubmit = handleSubmit((data) => {
        const integrationsData = {
            integrations: {
                ...(data.integrations.poweroffice?.sales_account && {
                    poweroffice: {
                        sales_account_id: data.integrations.poweroffice.sales_account.id
                    }
                }),
                ...(data.integrations.twenty_four_seven_office?.sales_account && {
                    twenty_four_seven_office: {
                        sales_account_id: data.integrations.twenty_four_seven_office.sales_account.id
                    }
                })
            }
        };

        if (props.category) {
            updateCategory({
                id: props.category.id,
                name: data.name,
                is_service: data.is_service,
                ...integrationsData
            }).unwrap().then(() => {
                toast.success(t("responses.update.success"));
            }).catch(() => {
                toast.error(t("responses.update.error"));
            }).finally(() => {
                props.onClose();
            });
        } else {
            createCategory(removeEmpty({
                name: data.name,
                code: data.code.value,
                is_service: data.is_service,
                ...integrationsData
            })).unwrap().then(() => {
                toast.success(t("responses.creation.success"));
            }).catch(() => {
                toast.error(t("responses.creation.error"));
            }).finally(() => {
                props.onClose();
            });
        }
    }, console.error);

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={562}
            padding="56px"
            useCloseIcon
            isLoading={isCreationLoading || isUpdateLoading}
        >
            <form className="space-y-[32px]" onSubmit={onSubmit}>
                <div className="text-xl font-semibold text-center text-accent">
                    {
                        props.category ?
                            props.category.name
                            :
                            t("creationHeading")
                    }
                </div>

                <Controller
                    name={"code.value"}
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t("fields.number.label")}

                            disabled={props.category !== undefined}
                        />
                    )}
                />

                <Controller
                    name={"name"}
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t("fields.name.label")}
                        />
                    )}
                />

                <Controller
                    name={"is_service"}
                    control={control}
                    render={({ field }) => (
                        <BaseCheckbox
                            {...field}
                            label={t("fields.isService.label")}
                        />
                    )}
                />

                {
                    isPowerOfficeIntegrated && (
                        <PowerofficeIntegrationSection control={control} />
                    )
                }

                {
                    isTwentyFourSevenOfficeIntegrated && (
                        <TwentyFourSevenOfficeIntegrationSection control={control} />
                    )
                }

                <div>
                    <BaseButton
                        buttonWidth="100%"
                        text={props.category ? t("buttons.save") : t("buttons.create")}
                        size="md"
                        type="submit"
                    />
                </div>
            </form>
        </BaseModal>
    );
}
