import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { FileUp, ScanText, XCircle } from "lucide-react";
import { useEchoEffect } from "@helpers/utils";
import { useTranslation } from "react-i18next";
import BaseModal from "@reusables/Modals/BaseModal";
import { ReactComponent as SuccessSVG } from "@assets/icons/ic_success.svg";
import { BaseCircularProgressWithContent } from "@reusables/BaseCircularProgressWithContent";
import { invalidateModuleCache } from "../cacheUtils";
import {
    finishStockCountApproval,
    resetStockCountApproval,
    startStockCountApproval
} from "@redux/features/stockCount/stockCountSlice";
import { isStockCountApprovalActive, isStockCountApprovalFinished } from "@redux/features/stockCount/types";

export default function StockCountIndicator(): JSX.Element {
    const { t } = useTranslation("", { keyPrefix: "inventory.stockCount.indicator" });

    const dispatch = useAppDispatch();

    const company = useAppSelector((state) => state.auth.user?.company);

    const stockCountApprovalState = useAppSelector((state) => state.stockCount);

    // Listen for "ApprovalStarted"
    useEchoEffect(
        `stock_count.${company?.id}`,
        "StockCount\\StockCountApprovalStarted",
        () => {
            dispatch(startStockCountApproval());
        },
        undefined,
        [company]
    );

    // Listen for "ApprovalFinished"
    useEchoEffect<{ success: boolean }>(
        `stock_count.${company?.id}`,
        "StockCount\\StockCountApprovalFinished",
        (finishData) => {
            dispatch(finishStockCountApproval({has_errors: !finishData.success}));
            finishData.success && invalidateModuleCache("stock_count", dispatch);
        },
        undefined,
        [company]
    );

    const [isResultModalOpen, setIsResultModalOpen] = useState(false);

    return (
        <>
            {
                // While ACTIVE, show a progress indicator
                isStockCountApprovalActive(stockCountApprovalState.status) && (
                    <BaseCircularProgressWithContent
                        size={26}
                        tooltip={t("states.inProgress")}
                    >
                        <ScanText size={12} />
                    </BaseCircularProgressWithContent>
                )
            }
            {
                // When FINISHED, show a final state (100% progress) and allow opening a modal for result
                isStockCountApprovalFinished(stockCountApprovalState.status) && (
                    <>
                        <BaseCircularProgressWithContent
                            size={26}
                            variant="determinate"
                            value={100}
                            sx={{
                                color: stockCountApprovalState.has_errors ? "#E25C5C" : "#A6DD4C"
                            }}
                            onClick={() => setIsResultModalOpen(true)}
                            tooltip={stockCountApprovalState.has_errors
                                ? t("states.finishedWithErrors")
                                : t("states.finishedSuccessfully")}
                        >
                            <FileUp size={12} />
                        </BaseCircularProgressWithContent>

                        <BaseModal
                            isOpen={isResultModalOpen}
                            onClose={() => {
                                dispatch(resetStockCountApproval());
                                setIsResultModalOpen(false);
                            }}
                            padding="40px"
                            position="center"
                        >
                            {
                                stockCountApprovalState.has_errors ? (
                                    <div className="flex items-center gap-4">
                                        <XCircle width={40} height={40} fill="#FF0000" color="#FFF" />
                                        <div className="flex flex-col items-start space-y-4">
                                            <div className="text-accent font-bold text-xl text-center">
                                                {t("states.error.title")}
                                            </div>
                                            <div className="text-accent text-lg">
                                                {t("states.error.message")}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex items-center gap-4">
                                        <SuccessSVG width={40} height={40} />
                                        <div className="text-accent font-bold text-xl text-center">
                                            {t("states.applied")}
                                        </div>
                                    </div>
                                )
                            }
                        </BaseModal>
                    </>
                )
            }
        </>
    );
}
