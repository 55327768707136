import React, { useCallback, useState } from "react";
import BaseTable from "@reusables/BaseTable";
import BaseAdminFilter from "@reusables/BaseAdminFilter";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import { declOfNum, normalizePrice, useOrdering, usePagination } from "@/helpers/utils";
import { BOM } from "@/types/general";
import { useGetBOMsFullQuery } from "@redux/features/bom/bomApi";
import { Menu, Pagination } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeletionModal from "../components/modals/DeletionModal";
import { ability, Can } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import BaseTooltip from "@/components/reusables/BaseTooltip";
import BaseTinyActionIcon, { TinyActionIconType } from "@/components/reusables/BaseTinyActionIcon";
import ProduceModal from "../components/modals/ProduceModal";
import { copyBOM } from "@components/Dashboard/pages/BOM/utils";

export default function BOMPage() {
    const { t } = useTranslation("", { keyPrefix: "bom.main" });

    const history = useHistory();

    // ===> ACTIONS DOTS CONFIGURATION <===
    const [actionsAnchor, setActionsAnchor] = useState<HTMLDivElement>();
    const [actionEntity, setActionEntity] = useState<BOM.Root>();

    const [searchingValue, setSearchingValue] = useState<string>();
    const searchInputDebounce = useCallback(
        _.debounce(setSearchingValue, 1000),
        []
    );

    const filters = {
        search: searchingValue
    };

    const { orderBy, setOrderBy } = useOrdering<BOM.DTO.OrderBy>({
        name: "id",
        type: "desc"
    });

    const pagination = usePagination({ page: 1, limit: 8 });

    const { data, isFetching: dataLoading } = useGetBOMsFullQuery({
        filters,
        orderBy,
        pagination: { page: pagination.page, limit: pagination.limit }
    });

    const [isProduceModalOpen, setIsProduceModalOpen] = useState<boolean>(false);
    const [activeDeletion, setActiveDeletion] = useState<BOM.Root>();

    return (
        <>
            <BaseAdminFilter
                permissionModule="product"
                subPermission="bill_of_materials.create"
                handleSearch={searchInputDebounce}
                handleCreate={() => history.push("/dashboard/bill-of-materials/new")}
            />

            <div className="levitation-extended mt-[32px]">
                <Can not I="bill_of_materials.view" a="product">
                    <NoPermissionBanner />
                </Can>

                <Can I="bill_of_materials.view" a="product">
                    <div className="flex justify-between mb-[24px]">
                        {
                            <>
                                <div
                                    className="bold-highlight capitalize">{data?.meta.total} {declOfNum(data?.meta.total ?? 0, [t("table.titleDeclinations.0"), t("table.titleDeclinations.1"), t("table.titleDeclinations.2")])}
                                </div>
                                <Can I={"bill_of_materials.create"} a={"product"}>
                                    <div
                                        className="font-thin underline underline-offset-1 text-[#686868] hover:text-accent cursor-pointer"
                                        onClick={() => history.push("/dashboard/bill-of-materials/production-history")}
                                    >
                                        {t("producingHistory")}
                                    </div>
                                </Can>
                            </>
                        }
                    </div>
                    <BaseTable
                        data={data?.payload ?? []}
                        columns={[
                            {
                                header: t("table.columns.0"),
                                getter: (row) =>
                                    <>
                                        <Can I="bill_of_materials.create" a="product">
                                            <div
                                                className="bold-highlight text-accent hover:underline cursor-pointer"
                                                onClick={() => history.push(`/dashboard/bill-of-materials/${row.id}/details`)}
                                            >
                                                {row.name}
                                            </div>
                                        </Can>

                                        <Can not I="bill_of_materials.create" a="product">
                                            <div className="bold-highlight text-accent">{row.name}</div>
                                        </Can>
                                    </>,
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.1"),
                                getter: (row) =>
                                    <>
                                        <Can I="view" a="product">
                                            <Link
                                                to={`/dashboard/products/${row.product.id}/details`}
                                            >
                                                <u>
                                                    {row.product.name}
                                                </u>
                                            </Link>
                                        </Can>

                                        <Can not I="view" a="product">
                                            <div className="bold-highlight text-accent">{row.product.name}</div>
                                        </Can>
                                    </>,
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.2"),
                                getter: (row) => row.product.category?.name,
                                comparator: () => 0

                            },
                            {
                                header: t("table.columns.4"),
                                getter: (row) => row.product?.in_stock,
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.5"),
                                getter: (row) => row.produced,
                                comparator: () => 0
                            },
                            {
                                visible:
                                    ability.can("bill_of_materials.create", "product") ||
                                    ability.can("bill_of_materials.create", "product"),
                                header: t("table.columns.6"),
                                getter: (row, index, isCollapsed) => (
                                    <div
                                        className="flex space-x-[4px] justify-center align-center py-[10px] cursor-pointer group"
                                        onClick={(e) => {
                                            setActionsAnchor(e.currentTarget);
                                            setActionEntity(row);
                                        }}>
                                        {
                                            [...Array(3)].map((x, i) => <div key={i}
                                                                             className={`${isCollapsed ? "bg-gray-600" : "bg-gray-300"} rounded-full w-[5px] h-[5px] group-hover:bg-gray-600 transition-[.15s]`}></div>)
                                        }
                                    </div>
                                ),
                                preventCollapsePropagation: true
                            }
                        ]}

                        collapse={{
                            fill: "#D9DAFD",
                            borderColor: "#B3B5EB",
                            content: (mainRow) => {
                                return (
                                    <BaseTable
                                        data={mainRow.components}
                                        columns={[
                                            {
                                                header: <div className="text-accent font-semibold">
                                                    {t("table.subtable.columns.0")}
                                                </div>,
                                                getter: (row) => row.product?.name
                                            },
                                            {
                                                header: <div className="text-accent font-semibold">
                                                    {t("table.subtable.columns.1")}
                                                </div>,
                                                getter: (row) => row?.quantity
                                            },
                                            {
                                                header: <div className="text-accent font-semibold">
                                                    {t("table.subtable.columns.2")}
                                                </div>,
                                                getter: (row) => normalizePrice(row?.product?.prices.purchase_price)
                                            },
                                            {
                                                header: <div className="text-accent font-semibold">
                                                    {t("table.subtable.columns.3")}
                                                </div>,
                                                getter: (row) => normalizePrice(row?.product?.prices.purchase_price * row?.quantity)
                                            },
                                            {
                                                header: <div className="text-accent font-semibold">
                                                    {t("table.subtable.columns.4")}
                                                </div>,
                                                getter: (row) => row?.product?.in_stock
                                            }
                                        ]}
                                        size="small"
                                        nothingFound={{
                                            height: 200
                                        }}
                                        hideTableWhenNothingFound
                                    />
                                );
                            }
                        }}

                        alternate
                        isDataLoading={dataLoading}
                        manualControls={{
                            ordering: (newOrdering) => {
                                if (newOrdering) {
                                    let name: BOM.DTO.OrderBy | undefined;

                                    switch (newOrdering?.index) {
                                        case 0:
                                            name = "id";
                                            break;
                                        case 1:
                                            name = "name";
                                            break;
                                        case 2:
                                            name = "productName";
                                            break;
                                        case 3:
                                            name = "categoryName";
                                            break;
                                        case 4:
                                            name = "produced";
                                            break;
                                        default:
                                            name = undefined;
                                    }

                                    if (name)
                                        setOrderBy({
                                            name,
                                            type: newOrdering?.order
                                        });
                                } else {
                                    setOrderBy(undefined);
                                }
                            }
                        }}
                    />

                    <Menu
                        id="basic-menu"
                        anchorEl={actionsAnchor}
                        open={!!actionsAnchor}
                        onClose={() => setActionsAnchor(undefined)}
                    >
                        <div
                            className="flex space-x-[16px] p-[18px] bg-gradient-to-r from-gradients-primaryLinear-from to-gradients-primaryLinear-to text-[#fff]"
                        >
                            {/* <Can I="create" a="produce"> */}
                            <BaseTooltip title={`${t("table.actions.produce")}`} placement="bottom">
                                <BaseTinyActionIcon
                                    type={TinyActionIconType.Produce}
                                    onClick={() => {
                                        setIsProduceModalOpen(true);
                                        setActionsAnchor(undefined);
                                    }}
                                    variant="gradient"
                                />
                            </BaseTooltip>
                            {/* </Can> */}
                            <Can I="bill_of_materials.create" a="product">
                                <BaseTooltip title={`${t("table.actions.copy")}`} placement="bottom">
                                    <BaseTinyActionIcon
                                        type={TinyActionIconType.Copy}
                                        onClick={() => {
                                            if (!actionEntity) return;

                                            copyBOM(actionEntity, history);
                                        }}
                                        variant="gradient"
                                    />
                                </BaseTooltip>

                                <BaseTooltip title={`${t("table.actions.edit")}`} placement="bottom">
                                    <BaseTinyActionIcon
                                        type={TinyActionIconType.Edit}
                                        onClick={() => history.push(`/dashboard/bill-of-materials/${actionEntity?.id}/edit`)}
                                        variant="gradient"
                                    />
                                </BaseTooltip>
                            </Can>
                            <Can I="bill_of_materials.create" a="product">
                                <BaseTooltip title={`${t("table.actions.delete")}`} placement="bottom">
                                    <BaseTinyActionIcon
                                        type={TinyActionIconType.Delete}
                                        onClick={() => {
                                            setActiveDeletion(actionEntity);
                                            setActionsAnchor(undefined);
                                        }}
                                        variant="gradient"
                                    />
                                </BaseTooltip>
                            </Can>
                        </div>
                    </Menu>


                    <Pagination className="mt-[32px]" {...pagination.adapt(data)} />
                </Can>
            </div>

            <ProduceModal
                isOpen={isProduceModalOpen}
                onClose={() => setIsProduceModalOpen(false)}
                entity_id={actionEntity?.id}
            />

            {/* DELETION MODAL */}
            <DeletionModal
                isOpen={!!activeDeletion}
                entity={activeDeletion}
                onClose={() => setActiveDeletion(undefined)}
                onDeletion={() => {
                    setActiveDeletion(undefined);
                }}
            />
        </>
    );
}
