import React, { useEffect } from "react";
import BaseModal from "@reusables/Modals/BaseModal";
import { useTranslation } from "react-i18next";
import BaseButton from "@reusables/BaseButton";
import { useUpdateTripletexConfigurationMutation } from "@redux/features/integrations/integrationsApi";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import BaseSwitch from "@reusables/BaseSwitch";
import { cn, toastError } from "@helpers/utils";
import BaseHelp from "@reusables/BaseHelp";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { Integration } from "@/types/general";
import { enableIntegration } from "@redux/features/auth/authSlice";

interface PowerOfficeSettingsModal {
    isOpen: boolean;
    onClose: () => void;
}

const formSchema = z.object({
    projects_support: z.boolean()
});

type FormTyping = z.infer<typeof formSchema>;

export default function TripletexSettingsModal({ isOpen, onClose }: PowerOfficeSettingsModal) {
    const { t } = useTranslation("", { keyPrefix: "imports.modules.tripletex.settingsModal" });

    const [updateConfiguration, { isLoading: isConfigurationUpdateLoading }] = useUpdateTripletexConfigurationMutation();

    const dispatch = useAppDispatch();

    const form = useForm<FormTyping>({
        defaultValues: {
            projects_support: false
        }
    });

    const tripletexIntegrationSettings = useAppSelector(state => state.auth.user?.company?.integrations?.tripletex);

    const onSubmit = form.handleSubmit((data) => {
        updateConfiguration({
            projects_support: data.projects_support
        })
            .unwrap()
            .then((data) => {
                dispatch(enableIntegration(data));
                onClose();
            })
            .catch(err => {
                console.error(err);
                toastError(err);
            });
    });


    useEffect(() => {
        if (tripletexIntegrationSettings && "projects_support" in tripletexIntegrationSettings) {
            form.setValue("projects_support", (tripletexIntegrationSettings as Integration.ActiveState.PowerOfficeGO).projects_support);
        }
    }, [tripletexIntegrationSettings]);

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            width={800}
            padding="45px"
            useCloseIcon
            isLoading={isConfigurationUpdateLoading}
            position={"center"}
        >
            <form onSubmit={onSubmit}>
                <div className="text-xl font-semibold text-center text-accent mb-4">
                    {t("heading")}
                </div>

                <div className="text-xl font-medium text-accent mb-4">
                    {t("features")}
                </div>
                <Controller
                    control={form.control}
                    name="projects_support"
                    render={({ field }) => (
                        <div
                            className={cn(
                                `flex flex-row items-center justify-between grow border-[1px] border-solid rounded-lg bg-white transition-all overflow-hidden relative`,
                                `border-inputs-border-default`,
                                "hover:border-inputs-border-focused",
                                "p-3 cursor-pointer",
                                field.value && "border-inputs-border-focused"
                            )}
                            onClick={() => field.onChange(!field.value)}
                        >
                            <div className={"flex items-center gap-2"}>
                                <div>{t("projectsSupport.label")}</div>
                                <BaseHelp value={t("projectsSupport.tip")} />
                            </div>

                            <BaseSwitch
                                checked={field.value}
                            />
                        </div>
                    )}
                />

                <div className={"mt-8 grid grid-cols-2 gap-2"}>
                    <BaseButton
                        buttonWidth="100%"
                        text={t("buttons.cancel")}
                        size="md"
                        type="button"
                        primaryOutlined
                        onClick={onClose}
                    />
                    <BaseButton
                        buttonWidth="100%"
                        text={t("buttons.save")}
                        size="md"
                        type="submit"
                    />
                </div>
            </form>
        </BaseModal>
    );
}