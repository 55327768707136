import { useTranslation } from "react-i18next";
import { useGetTripletexProjectsQuery } from "@redux/features/integrations/integrationsApi";
import { Accordion, AccordionDetails, AccordionSummary, Skeleton } from "@mui/material";
import { ChevronDown, Lock } from "lucide-react";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import React from "react";
import { Tripletex } from "@/types/general";
import BaseIntegrationInject from "@reusables/BaseIntegrationInject";
import BaseBadge from "@reusables/BaseBadge";
import { genT, PredefinedTranslations } from "@helpers/utils";

interface TripletexIntegrationSectionProps {
    value: Tripletex.Projects.Root[];
    onChange: (projects: Tripletex.Projects.Root[]) => void;
}

export default function TripletexIntegrationSection({ value, onChange }: TripletexIntegrationSectionProps) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.locations.integrations.tripletex" });

    const { data: projects, isLoading: isProjectsLoading } = useGetTripletexProjectsQuery();

    return (
        <Accordion defaultExpanded>
            <AccordionSummary
                expandIcon={<ChevronDown />}
                aria-controls="tripletex-integration-content"
                id="tripletex-integration-header"
            >
                <BaseIntegrationInject integration={"tripletex"}>
                    {
                        (integration, isLoading) =>
                            <div className={"flex flex-row items-center space-x-2"}>
                                {
                                    isLoading
                                        ?
                                        <>
                                            <Skeleton variant="circular" width={40} height={40} />
                                            <Skeleton variant="text" width={150} height={30} />
                                        </>
                                        :
                                        <>
                                            <img src={integration?.imageUrl} width={40} height={40}
                                                 alt={integration?.name} />
                                            <div className={"text-accent"}>
                                                {integration?.name}
                                            </div>
                                        </>
                                }
                                <BaseBadge className={"bg-purple-400 text-white"}>
                                    {genT(PredefinedTranslations.IntegrationsBadge)}
                                </BaseBadge>
                            </div>
                    }
                </BaseIntegrationInject>
            </AccordionSummary>
            <AccordionDetails>
                <div className="max-w-[500px]">
                    <BaseDropdown
                        label={t("projects.label")}
                        placeholder={t("projects.placeholder")}

                        value={value}
                        options={projects ?? []}
                        getter={{
                            key: opt => opt.id,
                            label: opt => opt.name,
                            renderOption: (opt, icon) => (
                                <div className="flex flex-row items-center space-between">
                                    <div className={"flex flex-row items-center space-x-2 grow"}>
                                        <span className="ml-[8px]">{opt.name}</span>
                                        {
                                            opt.isClosed &&
                                            <BaseBadge className={"bg-gray-400 px-[4px]"}>
                                                <Lock size={14} className="mt-[-1px] mr-[4px]" />
                                                <span className="font-medium">{t("projects.types.closed")}</span>
                                            </BaseBadge>
                                        }
                                    </div>
                                    {icon}
                                </div>
                            )
                        }}
                        onChange={(_, sel) => onChange(sel)}
                        isLoading={isProjectsLoading}

                        virtualize
                        autocomplete
                        multiple
                    />
                </div>
            </AccordionDetails>
        </Accordion>
    );
}
