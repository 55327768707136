import React, { PropsWithChildren } from "react";
import { Integration } from "@/types/general";
import BaseIntegrationInject from "@reusables/BaseIntegrationInject";
import { Collapse, Skeleton } from "@mui/material";
import BaseBadge from "@reusables/BaseBadge";
import { cn, genT, PredefinedTranslations } from "@helpers/utils";
import { ReactComponent as CircleChevronSVG } from "@assets/icons/ic_expand_down_circled.svg";

type BaseIntegrationSectionProps = PropsWithChildren<{
    integration: Integration.Components.Slug;
    isOpen?: boolean;
    onOpenChange?: (isOpen: boolean) => void;
    hideIntegrationBadge?: boolean;
}>;

export default function BaseIntegrationSection({
                                                   integration,
                                                   children,
                                                   isOpen,
                                                   onOpenChange,
                                                   hideIntegrationBadge
                                               }: BaseIntegrationSectionProps) {
    return (
        <div
            className={
                cn(
                    "border-[1px] border-solid rounded-lg bg-white transition-all overflow-hidden relative",
                    "hover:border-inputs-border-focused",
                    isOpen ? "border-inputs-border-focused" : `border-inputs-border-default cursor-pointer`
                )
            }
        >
            <div
                className={"flex flex-row items-center justify-between space-x-2 cursor-pointer p-[16px]"}
                onClick={() => void onOpenChange?.(!isOpen)}
            >
                <BaseIntegrationInject integration={integration}>
                    {
                        (integration, isLoading) =>
                            <div className={"flex flex-row items-center space-x-2"}>
                                {
                                    isLoading
                                        ?
                                        <>
                                            <Skeleton variant="circular" width={40} height={40} />
                                            <Skeleton variant="text" width={150} height={30} />
                                        </>
                                        :
                                        <>
                                            <img src={integration?.imageUrl} width={40}
                                                 alt={integration?.name} />
                                            <div className={"text-accent"}>
                                                {integration?.name}
                                            </div>
                                        </>
                                }
                                {
                                    !hideIntegrationBadge &&
                                    <BaseBadge className={"bg-purple-400 text-white"}>
                                        {genT(PredefinedTranslations.IntegrationsBadge)}
                                    </BaseBadge>
                                }
                            </div>
                    }
                </BaseIntegrationInject>

                <CircleChevronSVG
                    className={cn("cursor-pointer text-accent transition-[.25s]")}
                    style={{ transform: !isOpen ? "rotate(0deg)" : "rotate(180deg)" }}
                />
            </div>
            <Collapse in={isOpen}>
                <div
                    className={"border-t-[1px] border-t-inputs-border-default space-y-1 p-[16px]"}
                    style={{ borderTopWidth: "1px", borderTopStyle: "solid" }}
                >
                    {children}
                </div>
            </Collapse>
        </div>
    );
}