export enum StockCountApprovalStatus {
    IDLE = "idle",
    ACTIVE = "active",
    FINISHED = "finished",
}

export function isStockCountApprovalActive(state: StockCountApprovalStatus) {
    return state === StockCountApprovalStatus.ACTIVE;
}

export function isStockCountApprovalFinished(state: StockCountApprovalStatus) {
    return state === StockCountApprovalStatus.FINISHED;
}
