import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PriceReconversionStatus, PriceReconversionState } from './types';

const initialState = {
    value: {
        status: PriceReconversionStatus.IDLE
    } as PriceReconversionState
};

export const priceReconversionSlice = createSlice({
    name: 'reconversion',
    initialState: initialState,
    reducers: {
        startPriceReconversion: (state, action: PayloadAction<{ module: string }>) => {
            state.value = {
                status: PriceReconversionStatus.ACTIVE,
                module: action.payload.module
            };
        },

        updatePriceReconversionProgress: (state, action: PayloadAction<{ current: number[], total: number[] }>) => {
            if (state.value.status === PriceReconversionStatus.ACTIVE) {
                const { current, total } = action.payload;

                state.value = {
                    ...state.value,
                    progress: current.map((c, i) => ({
                        current: c,
                        total: total[i]
                    }))
                };
            }
        },

        finishPriceReconversion: (state, action: PayloadAction<{ has_errors: boolean, data?: any }>) => {
            state.value = {
                status: PriceReconversionStatus.FINISHED,
                module: state.value.module,
                has_errors: action.payload.has_errors,
                data: action.payload.data
            };
        },

        resetPriceReconversion: (state) => {
            state.value = {
                status: PriceReconversionStatus.IDLE
            };
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    startPriceReconversion,
    updatePriceReconversionProgress,
    finishPriceReconversion,
    resetPriceReconversion
} = priceReconversionSlice.actions;

export default priceReconversionSlice.reducer;