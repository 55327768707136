import React, { useEffect, useMemo } from "react";
import { OrderStatus, PurchaseOrder, Receive } from "@/types/general";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import { useGetModuleSuppliersQuery } from "@redux/features/suppliers/suppliersApi";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import BaseDatepicker from "@reusables/BaseDatepicker";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert } from "@mui/material";
import { ArrayElementType, toastError } from "@helpers/utils";
import BaseButton from "@reusables/BaseButton";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { emptyReceiveOrder } from "@redux/features/purchases/purchasesSlice";
import TableBody from "src/components/Dashboard/pages/PurchasingPage/Receive/components/MutationLayout/parts/TableBody";
import TableHeading
    from "@components/Dashboard/pages/PurchasingPage/Receive/components/MutationLayout/parts/TableHeading";
import { purchasesApi, useGetModulePurchaseOrdersQuery } from "@redux/features/purchases/purchasesApi";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import BaseOrderStatusBadge from "@reusables/BaseOrderStatusBadge";
import { isOrderDisabled } from "@/components/Dashboard/pages/Sales/Orders/utils";

const formScheme = z.object({
    extractedFromTransit: z.boolean().optional(), // will be set to "true" if order is obtained from any transit source (e.g. Upcoming shipments). Need to prevent supplier change wiping PO.

    supplier: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string().nullish()
    }),

    purchase_order: z.object({
        id: z.number(),
        code: z.string().nullish(),
        status: z.object({
            name: z.nativeEnum(OrderStatus.Base),
            meta: z.object({
                cancel_reason: z.string().nullish()
            }).nullish()
        })
    }).readonly(),

    receive_date: z.object({
        date: z.instanceof(dayjs as unknown as typeof Dayjs).refine((value) => value.isValid(), {
            message: "Invalid date"
        })
    }),

    receipts: z
        .array(
            z
                .object({
                    order: z
                        .object({
                            type: z.string(),
                            module: z.string().nullish(),
                            name: z.string().nullish(),
                            data: z.object({
                                id: z.number(),
                                product: z.object({
                                    id: z.number(),
                                    code: z.string(),
                                    name: z.string(),

                                    is_service: z.boolean(),

                                    has_serial_number: z.boolean(),
                                    has_batch_number: z.boolean()
                                })
                            }).nullish()
                        })
                        .readonly(),
                    location: z
                        .object({
                            quantity: z.number(),
                            store: z.object({
                                id: z.number(),
                                name: z.string()
                            }),
                            section: z
                                .object({
                                    id: z.number(),
                                    name: z.string()
                                })
                                .optional()
                        })
                        .optional(),

                    available_quantity: z.number().readonly(),
                    received_quantity: z.coerce.number().min(0).nullish(),

                    serial_numbers: z.array(
                        z.object({
                            serial_number: z.string()
                        })
                    ),

                    batch_numbers: z.array(
                        z.object({
                            batch_number: z.string(),
                            expiration_date: z
                                .instanceof(dayjs as unknown as typeof Dayjs)
                                .refine((value) => value.isValid(), {
                                    message: "Invalid date"
                                })
                                .optional()
                        })
                    )
                })
                .refine(
                    (scheme) => {
                        return (scheme.received_quantity ?? 0) <= scheme.available_quantity;
                    },
                    {
                        message: "Received quantity cannot be greater than available quantity",
                        path: ["received_quantity"]
                    }
                )
                .superRefine((scheme, ctx) => {
                    if (scheme.order?.data?.product.is_service) return;

                    if (scheme.location && !scheme.received_quantity) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: "Required",
                            path: ["received_quantity"]
                        });
                    } else if (scheme.received_quantity && !scheme.location) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: "Required",
                            path: ["location"]
                        });
                    }
                })
        )
        .refine(
            (scheme) => {
                return scheme.some((receipt) => receipt.received_quantity || receipt.location);
            },
            {
                message: "You need to receive at least one product"
            }
        )
        .superRefine((scheme, ctx) => {
            // Filter lines that either have serial numbers or batch numbers
            // and also have at least one pick with a defined outgoing location and picked quantity.
            const filteredLines = scheme.filter(({ order, received_quantity, location }) => {
                if (order.data?.product) {
                    const { has_serial_number, has_batch_number } = order.data.product;
                    return (has_serial_number || has_batch_number) && received_quantity && location;
                }
            });

            // Check if there are any lines with serial number errors
            const hasSerialError = filteredLines.some(({ order, serial_numbers, received_quantity }) => {
                if (!order.data?.product.has_serial_number) return false;

                return serial_numbers.length !== received_quantity;
            });

            // Check if there are any lines with batch number errors
            const hasBatchError = filteredLines.some(({ order, batch_numbers, received_quantity }) => {
                if (!order.data?.product.has_batch_number) return false;

                return batch_numbers.length == 0 || batch_numbers.length > (received_quantity ?? 0);
            });

            // Add an issue if serial number errors are found
            if (hasSerialError) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Serial numbers are filled incorrectly!",
                    path: ["serial_numbers"]
                });
            }

            // Add an issue if batch number errors are found
            if (hasBatchError) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Batch numbers are filled incorrectly!",
                    path: ["batch_numbers"]
                });
            }
        })
});

export type ReceiptsMutationFormTyping = z.infer<typeof formScheme>;

interface ReceiveMutationLayoutProperties {
    activeReceipt?: Receive.Extended;
    onMutation?: (transfer: ReceiptsMutationFormTyping) => void;
}

export default function MutationLayout({ onMutation }: ReceiveMutationLayoutProperties) {
    // Loading translations for mutation layout
    const { t } = useTranslation("", { keyPrefix: "receive.mutation" });

    const dispatch = useAppDispatch();
    const transitOrder = useAppSelector((state) => state.purchases.receiveOrder);

    const methods = useForm<ReceiptsMutationFormTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            receive_date: {
                date: dayjs()
            },
            purchase_order: undefined,
            receipts: []
        }
    });

    const { control, watch, getValues, setValue, handleSubmit, resetField, formState } = methods;

    // [SUPPLIERS] Loading suppliers for the dropdown
    const { data: supplierOptions, isLoading: isSupplierOptionsLoading } = useGetModuleSuppliersQuery("receive");

    const supplierWatch = useWatch({
        control,
        name: "supplier"
    });

    // [PURCHASE ORDERS] Loading purchase orders for the dropdown, if [SUPPLIER] is selected
    const {
        data: purchaseOrdersOptions,
        isFetching: isPurchaseOrdersFetching,
        isLoading: isPurchaseOrdersLoading
    } = useGetModulePurchaseOrdersQuery(
        {
            module: "receive",
            suppliers: watch("supplier") ? [getValues("supplier").id] : undefined
        },
        {
            skip: !watch("supplier")
        }
    );

    // [RECEIPTS] Updating receipts array, if [PURCHASE ORDER] is selected
    const purchaseOrderWatch = useWatch({
        control,
        name: "purchase_order"
    }) as PurchaseOrder.Root;

    // [EXTENDED PURCHASE ORDER] Loading extended purchase order information to fill order lines
    const [isExtendedPurchaseOrderLoading, setIsExtendedPurchaseOrderLoading] = React.useState(false);

    const isPurchaseOrderDisabled = isOrderDisabled(purchaseOrderWatch);

    useEffect(() => {
        const purchaseOrder = getValues("purchase_order") as PurchaseOrder.Root;

        if (purchaseOrder && !isOrderDisabled(purchaseOrder)) {
            const lr = (v: number) => v >= 0 ? v : 0;

            setIsExtendedPurchaseOrderLoading(true);

            dispatch(purchasesApi.endpoints.getModulePurchaseOrder.initiate({module: "receive", id: purchaseOrder.id}, { subscribe: false }))
                .unwrap()
                .then((PO) => {
                    const newReceipts: ReceiptsMutationFormTyping["receipts"] = PO.lines.map((order, index) => {
                        if (order.type === "line" && !Array.isArray(order.data)) {
                            return {
                                order: {
                                    type: order.type,
                                    data: {
                                        id: order.data.id,
                                        product: {
                                            id: order.data.product?.id,
                                            name: order.data.product.name,
                                            code: order.data.product.code,
                                            has_serial_number: order.data.product.has_serial_number,
                                            has_batch_number: order.data.product.has_batch_number,
                                            is_service: order.data.product.is_service
                                        }
                                    }
                                },
                                available_quantity: lr(order.data.quantity - (order.data.received_quantity ?? 0)),
                                location: undefined as unknown as ArrayElementType<ReceiptsMutationFormTyping["receipts"]>["location"],
                                serial_numbers: [],
                                batch_numbers: []
                            };
                        } else if (order.type === "group" && Array.isArray(order.data)) {
                            return [
                                {
                                    order: {
                                        type: order.type,
                                        group_id: order.id,
                                        name: order.name,
                                        module: order.module,
                                        key: index.toString()
                                    },
                                    available_quantity: 0, // or any default value for headers
                                    location: undefined as unknown as ArrayElementType<ReceiptsMutationFormTyping["receipts"]>["location"],
                                    serial_numbers: [],
                                    batch_numbers: []
                                },
                                ...order.data.map((line) => ({
                                    order: {
                                        type: "group",
                                        group_id: order.id,
                                        key: index.toString(),
                                        data: {
                                            id: line.id,
                                            product: {
                                                id: line.product?.id,
                                                name: line.product.name,
                                                code: line.product.code,
                                                has_serial_number: line.product.has_serial_number,
                                                has_batch_number: line.product.has_batch_number,
                                                is_service: line.product.is_service
                                            }
                                        }
                                    },
                                    available_quantity: lr(line.quantity - (line.received_quantity ?? 0)),
                                    location: undefined as unknown as ArrayElementType<ReceiptsMutationFormTyping["receipts"]>["location"],
                                    serial_numbers: [],
                                    batch_numbers: []
                                }))
                            ];
                        }
                        return [];
                    }).flat();

                    setValue("receipts", newReceipts, { shouldValidate: false });
                })
                .catch(e => {
                    console.error(e);
                    toastError(e);
                })
                .finally(() => {
                    setIsExtendedPurchaseOrderLoading(false);
                });
        } else {
            resetField("receipts");
        }
    }, [purchaseOrderWatch, setValue, resetField]);

    useEffect(() => {
        if (transitOrder) {
            setValue(
                "supplier",
                {
                    id: transitOrder.supplier.id,
                    name: transitOrder.supplier.name
                },
                { shouldValidate: false }
            );

            setValue(
                "purchase_order",
                {
                    id: transitOrder.id,
                    code: transitOrder.code,
                    status: {
                        name: transitOrder.status.name,
                        meta: {
                            cancel_reason: transitOrder.status.meta?.cancel_reason
                        }
                    }
                },
                { shouldValidate: false }
            );

            setValue("extractedFromTransit", true, { shouldValidate: false });

            dispatch(emptyReceiveOrder());
        }
    }, [transitOrder]);

    // [WIPING PURCHASE ORDERS] Wiping purchase orders, if [SUPPLIER] is changed
    useEffect(() => {
        !getValues("extractedFromTransit") && resetField("purchase_order");
    }, [supplierWatch]);

    // [SUBMIT] Submitting the form
    const onSubmit = handleSubmit((data) => {
        onMutation?.({
            ...data,
            receipts: data.receipts.filter((receipt) => (receipt.order.data?.product.is_service ? receipt.received_quantity : receipt.received_quantity && receipt.location))
        });
    }, console.error);

    // Just a workaround to operate with custom errors like "serial_numbers" and "batch_numbers" added via superRefine in ZOD
    const serialAndBatchNumberErrors = useMemo(() => {
        const linesErrors = formState.errors.receipts as {
            serial_numbers?: { message: string };
            batch_numbers?: { message: string };
        };

        if (linesErrors) {
            return {
                ...(!!linesErrors.serial_numbers && { serial_numbers: linesErrors.serial_numbers.message }),
                ...(!!linesErrors.batch_numbers && { batch_numbers: linesErrors.batch_numbers.message })
            };
        }
    }, [formState]);

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={onSubmit}>
                    <div className="space-y-[40px]">
                        <BaseInputsGrid>
                            {/* Supplier */}
                            <Controller
                                name="supplier"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <BaseDropdown
                                        {...field}
                                        {...fieldState}
                                        label={t("fields.supplier.label")}
                                        placeholder={t("fields.supplier.placeholder")}
                                        options={supplierOptions}
                                        getter={{
                                            label: (opt) => opt.name,
                                            key: (opt) => opt.id,
                                            caption: (opt) => opt.code ?? opt.id
                                        }}
                                        isLoading={isSupplierOptionsLoading}
                                        autocomplete
                                    />
                                )}
                            />

                            {/* Purchase order */}
                            <Controller
                                name="purchase_order"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <BaseDropdown
                                        {...field}
                                        {...fieldState}
                                        label={t("fields.purchaseOrder.label")}
                                        placeholder={t("fields.purchaseOrder.placeholder")}
                                        options={purchaseOrdersOptions}
                                        getter={{
                                            label: (opt) => opt.code ?? `${opt.id}`,
                                            key: (opt) => opt.id,
                                            renderOption: (opt) => (
                                                <div className="flex justify-between">
                                                    <span className="bold-highlight">{opt.code ?? opt.id}</span>
                                                    <BaseOrderStatusBadge status={opt.status.name} />
                                                </div>
                                            )
                                        }}
                                        isLoading={isPurchaseOrdersFetching || isPurchaseOrdersLoading}
                                        autocomplete
                                    />
                                )}
                            />

                            {/* Receive date*/}
                            <Controller
                                name="receive_date.date"
                                control={control}
                                render={({ field, fieldState }) => <BaseDatepicker {...field} {...fieldState}
                                                                                   label={t("fields.receiveDate.label")} />}
                            />
                        </BaseInputsGrid>

                        {/* Error for the general "receive more than one" error */}
                        {formState?.errors.receipts?.root &&
                            <Alert severity="error">{formState.errors.receipts.root.message}</Alert>}

                        {/* Error for bad SERIALS filling */}
                        {!!serialAndBatchNumberErrors?.serial_numbers &&
                            <Alert severity="error">{serialAndBatchNumberErrors.serial_numbers}</Alert>}

                        {/* Error for bad BATCHES filling */}
                        {!!serialAndBatchNumberErrors?.batch_numbers &&
                            <Alert severity="error">{serialAndBatchNumberErrors.batch_numbers}</Alert>}

                        <BaseLoadingBlocker active={isExtendedPurchaseOrderLoading}>
                            <div className="border border-solid border-gray-300 rounded-[8px] p-[16px]">
                                {/* Product table heading*/}
                                <TableHeading />
                                {purchaseOrderWatch ? (
                                    <>
                                        {isPurchaseOrderDisabled ? (
                                            <div
                                                className="flex items-center justify-center text-xl font-thin text-notFound-blocks uppercase"
                                                style={{ height: 300 }}
                                            >
                                                {purchaseOrderWatch.status.name === OrderStatus.Base.cancelled && (
                                                    <span>{t("fields.purchaseOrder.status.cancelled")}</span>
                                                )}
                                                {purchaseOrderWatch.status.name === OrderStatus.Base.completed && (
                                                    <span>{t("fields.purchaseOrder.status.completed")}</span>
                                                )}
                                            </div>
                                        ) : (
                                            <TableBody />
                                        )}
                                    </>
                                ) : (
                                    <TableBody />
                                )}
                            </div>
                        </BaseLoadingBlocker>

                        <div className="flex justify-center">
                            <BaseButton text={t("buttons.save")} size="md" buttonWidth="200px"
                                        disabled={isPurchaseOrderDisabled}
                            />
                        </div>
                    </div>
                </form>
            </FormProvider>
        </>
    );
}
