import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseLabel from "@reusables/BaseLabel";
import dayjs, { Dayjs } from "dayjs";
import { z } from "zod";
import { productsApi, useGetModuleProductQuery } from "@redux/features/products/productsApi";
import { Controller, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import BaseModal from "@reusables/Modals/BaseModal";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseDropdown, { adaptInfiniteLoader } from "@reusables/dropdowns/BaseDropdown";
import BaseInput from "@reusables/BaseInput";
import BaseDatepicker from "@reusables/BaseDatepicker";
import BaseButton from "@reusables/BaseButton";
import { refinements } from "@helpers/refinements";
import { Category, Product } from "../../types";
import { useGetCategoriesQuery } from "@redux/features/categories/categoriesApi";
import BaseCheckbox from "@reusables/BaseCheckbox";
import { toastError } from "@helpers/utils";
import BaseInfiniteLoader from "@reusables/dropdowns/BaseInfiniteLoader";
import { useAppDispatch } from "@redux/hooks";
import { Product as ProductType } from "@/types/general";

const ORDER_PRICE_PRECISION = import.meta.env.VITE_ORDER_PRICE_PRECISION;
const maxDecimalPrecisionRefinement = refinements.maxDecimalPrecision(ORDER_PRICE_PRECISION);

const formScheme = z.object({
    type: z.enum(["product", "category"]),
    id: z.number().optional(),
    product: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string()
    }).nullish(),
    category: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string().nullish()
    }).nullish(),
    sale_price: z.coerce.number()
        .min(0)
        .refine(maxDecimalPrecisionRefinement.refine, maxDecimalPrecisionRefinement.message)
        .optional(),
    discount: z.coerce.number().min(0).max(100).optional(),
    min_quantity: z.coerce.number().min(0).optional(),
    valid_from: z.instanceof(dayjs as unknown as typeof Dayjs).nullish(),
    valid_to: z.instanceof(dayjs as unknown as typeof Dayjs).nullish()
}).superRefine((data, ctx) => {
    if (data.valid_from && data.valid_to) {
        const validFromDate = dayjs(data.valid_from);
        const validToDate = dayjs(data.valid_to);

        if (validToDate.isBefore(validFromDate)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                params: {
                    i18n: "general.validation.global.compareDate"
                },
                path: ["valid_to"]
            });
        }
    }

    if (data.type === "product" && !data.product) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            params: {
                i18n: "general.validation.global.required"
            },
            path: ["product"]
        });
    }

    if (data.type === "category" && !data.category) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            params: {
                i18n: "general.validation.global.required"
            },
            path: ["category"]
        });
    }

    if (data.type === "category" && !data.discount) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            params: {
                i18n: "general.validation.global.required"
            },
            path: ["discount"]
        });
    }
});

type FormTyping = z.infer<typeof formScheme>;

export interface MutationProductModalProps {
    isOpen: boolean;
    onClose: () => void;

    onItemAddition: (newProduct: FormTyping) => void;
    item: Product | Category | undefined;
}

export default function MutationProductModal({
                                                 isOpen,
                                                 onClose,
                                                 onItemAddition,
                                                 item
                                             }: MutationProductModalProps): JSX.Element {
    const { t } = useTranslation("", { keyPrefix: "customers.general.tabs.pricesAndDiscounts.modals" });

    const dispatch = useAppDispatch();

    // const {data: productsOptions, isLoading: productsOptionsLoading} = useGetModuleProductsQuery("customer");
    const { data: categoryOptions, isLoading: categoryOptionsLoading } = useGetCategoriesQuery();

    const [tempProductValues, setTempProductValues] = useState<Partial<FormTyping>>({});
    const [tempCategoryValues, setTempCategoryValues] = useState<Partial<FormTyping>>({});

    const { control, ...form } = useForm<FormTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            valid_from: dayjs()
        }
    });

    const typeWatch = useWatch({
        control,
        name: "type"
    });

    const productWatch = useWatch({
        control,
        name: "product"
    });

    const {
        data: selectedProduct,
        isLoading: isSelectedProductLoading
    } = useGetModuleProductQuery({ module: "customer", id: productWatch?.id as number }, {
        skip: !productWatch?.id
    });

    useEffect(() => {
        // If the product is not selected or the selected product is different from the product in the form
        if (!item || (item.type === "product" && item.product?.id !== productWatch?.id)) {
            if (selectedProduct) {
                form.setValue("sale_price", selectedProduct.prices.selling_price);
                form.setValue("min_quantity", selectedProduct?.min_sale_quantity || undefined);
            }
        }
    }, [selectedProduct, isSelectedProductLoading]);

    useEffect(() => {
        console.log("Effect on open", isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            form.reset({
                type: item?.type ?? "product",
                valid_from: dayjs(),
                ...item && {
                    discount: item.discount,
                    min_quantity: item.min_quantity,
                    valid_from: item.valid_from,
                    valid_to: item.valid_to,
                    ...(item.type === "product" && {
                        product: item.product,
                        sale_price: item.sale_price,
                    }),
                    ...(item.type === "category" && {
                        id: item.id,
                        category: item.category,
                    }),
                }
            });
            setTempProductValues({});
            setTempCategoryValues({});
        }
    }, [isOpen, item]);

    // Reset the form when the type changes
    useEffect(() => {
        if (!item) {
            if (typeWatch === "product") {
                // Save current category-related values
                setTempCategoryValues({
                    category: form.getValues("category"),
                    discount: form.getValues("discount"),
                    min_quantity: form.getValues("min_quantity"),
                    valid_from: form.getValues("valid_from"),
                    valid_to: form.getValues("valid_to"),
                });
    
                // Restore product-related values
                form.reset({
                    type: "product",
                    product: tempProductValues.product || null,
                    sale_price: tempProductValues.sale_price,
                    discount: tempProductValues.discount,
                    min_quantity: tempProductValues.min_quantity,
                    valid_from: tempProductValues.valid_from || dayjs(),
                    valid_to: tempProductValues.valid_to || null,
                });
            } else if (typeWatch === "category") {
                // Save current product-related values
                setTempProductValues({
                    product: form.getValues("product"),
                    sale_price: form.getValues("sale_price"),
                    discount: form.getValues("discount"),
                    min_quantity: form.getValues("min_quantity"),
                    valid_from: form.getValues("valid_from"),
                    valid_to: form.getValues("valid_to"),
                });
    
                // Restore category-related values
                form.reset({
                    type: "category",
                    category: tempCategoryValues.category || null,
                    discount: tempCategoryValues.discount,
                    min_quantity: tempCategoryValues.min_quantity,
                    valid_from: tempCategoryValues.valid_from || dayjs(),
                    valid_to: tempCategoryValues.valid_to || null,
                });
            }
        }
    }, [typeWatch, item]);

    const onSubmit = form.handleSubmit(data => {
        onItemAddition(data);
        onClose();
    });

    const [productsOptions, setProductsOptions] = useState<ProductType.Root[]>([]);
    const [isProductLazyLoadingFetching, setIsProductLazyLoadingFetching] = useState(false);

    return (
        <>
            <BaseModal
                isOpen={isOpen}
                onClose={onClose}
                width={900}
                padding="56px"
                useCloseIcon
            >
                <form className="space-y-[32px]" onSubmit={onSubmit}>
                <div className="modal-title">
                    {item
                        ? item.type === "product"
                            ? t("heading.editProduct")
                            : t("heading.editCategory")
                        : typeWatch === "product"
                        ? t("heading.addProduct")
                        : t("heading.addCategory")}
                </div>

                    <Controller
                        control={control}
                        name="type"
                        render={({ field }) => (
                            <div className="flex flex-row space-x-[32px]">
                                <BaseCheckbox
                                    value={field.value === "product"}
                                    onChange={() => field.onChange("product")}
                                    label={t("type.0")}
                                    disabled={item?.type === "category"}
                                />
                                <BaseCheckbox
                                    value={field.value === "category"}
                                    onChange={() => field.onChange("category")}
                                    label={t("type.1")}
                                    disabled={item?.type === "product"}
                                />
                            </div>
                        )}
                    />

                    <BaseInputsGrid cols={2}>
                        {typeWatch === "product" && (
                            <>
                                <Controller
                                    name="product"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <BaseInfiniteLoader
                                            fetch={(search, page, limit) => {
                                                setIsProductLazyLoadingFetching(true);
                                                dispatch(productsApi.endpoints.getModuleProductsFull.initiate(
                                                        {
                                                            filters: {
                                                                search
                                                            },
                                                            pagination: {
                                                                page,
                                                                limit
                                                            },
                                                            module: "customer"
                                                        },
                                                        {
                                                            subscribe: false
                                                        }
                                                    )
                                                ).unwrap()
                                                    .then(res => {
                                                        setProductsOptions(res?.payload ?? []);
                                                    })
                                                    .catch(e => {
                                                        toastError(e);

                                                        console.error("An error occurred in the products lazy loader", e);
                                                    })
                                                    .finally(() => {
                                                        setIsProductLazyLoadingFetching(false);
                                                    });
                                            }}
                                            limit={100}
                                            result={productsOptions}
                                            isLoading={isProductLazyLoadingFetching}
                                        >
                                            {
                                                (infinity) => (
                                                    <>
                                                        <BaseDropdown
                                                            {...field}
                                                            {...fieldState}

                                                            {...adaptInfiniteLoader(infinity)}
                                                            label={t("dropdowns.product.label") + " *"}
                                                            placeholder={t("dropdowns.product.placeholder")}
                                                            getter={{
                                                                label: opt => opt.name,
                                                                key: opt => opt.id,
                                                                caption: opt => opt.code
                                                            }}

                                                            virtualize
                                                            autocomplete
                                                        />
                                                    </>
                                                )
                                            }
                                        </BaseInfiniteLoader>
                                    )}
                                />

                                <Controller
                                    name="sale_price"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <BaseInput
                                            {...field}
                                            error={fieldState.error}
                                            label={t("fields.price.label") + " *"}
                                            type="number"
                                        />
                                    )}
                                />
                            </>
                        )}

                        {typeWatch === "category" && (
                            <div className="col-span-2">
                                <Controller
                                    name="category"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <BaseDropdown
                                            {...field}
                                            {...fieldState}
                                            label={t("dropdowns.category.label") + " *"}
                                            placeholder={t("dropdowns.category.placeholder")}

                                        options={categoryOptions}
                                        getter={{
                                            label: opt => opt.name,
                                            key: opt => opt.id,
                                            caption: opt => opt.code ?? ""
                                        }}

                                            virtualize

                                            autocomplete
                                            isLoading={categoryOptionsLoading}
                                        />
                                    )}
                                />
                            </div>
                        )}

                        <Controller
                            name="discount"
                            control={control}
                            render={({ field, fieldState }) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t("fields.discount.label")}
                                    type="number"
                                />
                            )}
                        />

                        <Controller
                            name="min_quantity"
                            control={control}
                            render={({ field, fieldState }) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t("fields.minQuantity.label")}
                                    type="number"
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name="valid_from"
                            render={({ field, fieldState }) => (
                                <BaseDatepicker
                                    {...field}
                                    {...fieldState}
                                    label={t("fields.validFrom.label")}
                                    placeholder={t("fields.validFrom.placeholder")}
                                    disablePast
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name="valid_to"
                            render={({ field, fieldState }) => (
                                <div className="flex flex-column">
                                    <BaseLabel>{t("fields.validTill.label")} {<span
                                        className="font-thin opacity-50">{t("fields.validTill.tip")}</span>}</BaseLabel>
                                    <BaseDatepicker
                                        {...field}
                                        error={fieldState.error}
                                        placeholder={t("fields.validTill.placeholder")}
                                        disablePast
                                    />
                                </div>
                            )}
                        />
                    </BaseInputsGrid>

                    <BaseButton
                        text={item ? t("buttons.save") : t("buttons.add")}
                        size="md"
                        className="w-full"
                    />
                </form>
            </BaseModal>
        </>
    );
}
