import React from "react";
import { ReactComponent as IntegratedPuzzle } from "@assets/icons/ic_integrated.svg";
import BaseTooltip from "@reusables/BaseTooltip";
import { Integration } from "@/types/general";
import { useGetIntegrationsQuery } from "@redux/features/integrations/integrationsApi";
import _ from "lodash";
import { Link } from "lucide-react";

interface BaseSyncedFromSourcesBadgeProps {
    integrations: {
        slug: Integration.Components.Slug;
        integrated: () => boolean;
    }[];
    width?: number;
    height?: number;
}

export default function BaseSyncedFromSourcesBadge({ integrations, ...props }: BaseSyncedFromSourcesBadgeProps) {
    /**
     * Loading available integrations list
     */
    const { data: availableIntegrationsList } = useGetIntegrationsQuery();

    /**
     * Transforming integrations array into map with slug as key
     */
    const availableIntegrationsMap = _.keyBy(availableIntegrationsList?.integrations ?? [], "slug");

    /**
     *  To always show up-to-date integration names in the list,
     *  filtering out unintegrated systems and
     *  matching slugs with available integrations and getting their names, otherwise using slug as name
     */
    const integratedSystemsNames = integrations
        .filter(integration => integration.integrated())
        .map(integration => {
            const foundBySlug = availableIntegrationsMap[integration.slug];
            return foundBySlug ? foundBySlug.name : integration.slug;
        });

    if (!integratedSystemsNames.length)
        return null;

    return <BaseTooltip
        title={
            <ul>
                {
                    integratedSystemsNames.map((systemName, index) => <li key={index}
                                                                          className={"flex flex-row items-center space-x-1"}>
                        <Link size={10} />
                        <span>{systemName}</span>
                    </li>)
                }
            </ul>
        }
        placement={"top"}
    >
        <IntegratedPuzzle className={"text-[#FFC107]"} width={props.width ?? 18} height={props.height ?? 18} />
    </BaseTooltip>;
}