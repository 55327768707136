import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { declOfNum, manualRequest, removeEmpty, useOrdering, usePagination } from "@helpers/utils";
import { Customer } from "@/types/general";
import BaseAdminFilter from "@reusables/BaseAdminFilter";
import BaseTable from "@reusables/BaseTable";
import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";
import { ReactComponent as TrashSVG } from "@assets/icons/ic_trash.svg";
import DeletionModal from "../components/modals/DeletionModal";
import { AbilityContext, Can } from "@/casl.config";
import { useAbility } from "@casl/react";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import { useGetCustomersFullQuery } from "@redux/features/customers/customersApi";
import { Pagination } from "@mui/material";
import _ from "lodash";
import BaseSyncedFromSourcesBadge from "src/components/reusables/BaseSyncedFromSourcesBadge";
import BasePermissionBlocker from "@reusables/BasePermissionBlocker";

export default function CustomersPage() {
    const history = useHistory();
    const { t } = useTranslation("", { keyPrefix: "customers.main" });

    const ability = useAbility(AbilityContext);

    // =========== FILTERS RELATED =========== //
    const [searchingValue, setSearchingValue] = useState<string>();
    const searchInputDebounce = useCallback(_.debounce(setSearchingValue, 1000), []);

    const { orderBy, setOrderBy } = useOrdering<Customer.DTO.OrderBy>({ name: "id", type: "desc" });

    const pagination = usePagination({ page: 1, limit: 8 });

    const filters = removeEmpty({
        search: searchingValue
    });

    const { data: customers, isLoading: isCustomersLoading } = useGetCustomersFullQuery({
        filters,
        orderBy,
        pagination: {
            page: pagination.page,
            limit: pagination.limit
        }
    });

    // const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);

    const [actionEntity, setActionEntity] = useState<Customer.Root>();
    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState<boolean>(false);

    return (
        <>
            {/* FILTERS BLOCK */}
            <BaseAdminFilter
                permissionModule={"customer"}

                handleSearch={searchInputDebounce}

                handleImport={file => {
                    if (!file)
                        return;

                    const formData = new FormData();
                    formData.append("import", file);

                    manualRequest({
                        route: "/customer/import",
                        body: formData,
                        method: "POST",
                        then: (resp) => {
                            console.log(resp);
                        },
                        catch: (err) => {
                            console.error(err);
                        }
                    });
                }}
                // handleExport={() => setIsExportModalOpen(true)}
                handleCreate={() => history.push("/dashboard/customers/new")}
            />

            <div className="levitation-extended mt-[32px]">
                <Can not I="view" a="customer">
                    <NoPermissionBanner />
                </Can>

                <Can I="view" a="customer">
                    <div className="mb-[24px]">
                        {
                            <div
                                className="bold-highlight capitalize">{customers?.meta.total} {declOfNum(customers?.meta.total ?? 0, [t("table.titleDeclinations.0"), t("table.titleDeclinations.1"), t("table.titleDeclinations.2")])}
                            </div>
                        }
                    </div>

                    <BaseTable
                        data={customers?.payload ?? []}
                        isDataLoading={isCustomersLoading}

                        columns={[
                            {
                                header: t("table.columns.0"),
                                getter: row => {
                                    const basicLayout = <>
                                        <div className={"flex flex-row items-center space-x-2"}>
                                            <div className="bold-highlight">{row.name}</div>
                                            <BaseSyncedFromSourcesBadge
                                                integrations={[
                                                    {
                                                        slug: "tripletex",
                                                        integrated: () => !!row.tripletex_id
                                                    },
                                                    {
                                                        slug: "poweroffice",
                                                        integrated: () => !!row.poweroffice_id
                                                    },
                                                    {
                                                        slug: "twenty_four_seven_office",
                                                        integrated: () => !!row.twenty_four_seven_office_id
                                                    }
                                                ]}
                                            />
                                        </div>
                                        <div className="text-sm text-accent">{row.code}</div>
                                    </>;
                                    return <>
                                        <Can I="view" a="customer">
                                            <div
                                                className="hover:opacity-70 transition-[.15s] cursor-pointer"
                                                onClick={() => history.push(`/dashboard/customers/${row.id}/details`)}
                                            >
                                                {basicLayout}
                                            </div>
                                        </Can>

                                        <Can not I="view" a="customer">
                                            <div>
                                                {basicLayout}
                                            </div>
                                        </Can>
                                    </>;
                                },
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.1"),
                                getter: row => row?.is_person ? `${t("table.person")}` : `${t("table.company")}`
                            },
                            {
                                header: t("table.columns.2"),
                                getter: row => row.contacts[0]?.email ?? "-",
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.3"),
                                getter: row => row.contacts[0]?.phone ?? "-",
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.4"),
                                getter: row => row.contacts[0]?.name ?? "-",
                                comparator: () => 0
                            },
                            {
                                header: <div className="text-center">{t("table.columns.5")}</div>,
                                getter: (row) => <div className="text-center space-x-[12px]">
                                    <BasePermissionBlocker action="edit" module="customer">
                                        <EditSVG
                                            className={`text-tables-secondaryIcon ${ability.can("edit", "customer") ? "hover:text-tables-highlightedIcon cursor-pointer" : "cursor-default"}`}
                                            onClick={() => ability.can("edit", "customer") && history.push(`/dashboard/customers/${row.id}/edit`)}
                                        />
                                    </BasePermissionBlocker>

                                    <BasePermissionBlocker action="delete" module="customer">
                                        <TrashSVG
                                            className={`text-tables-secondaryIcon ${ability.can("delete", "customer") ? "hover:text-tables-highlightedIcon cursor-pointer" : "cursor-default"}`}
                                            onClick={() => {
                                                ability.can("delete", "customer") && (
                                                    setActionEntity(row),
                                                        setIsDeletionModalOpen(true)
                                                );
                                            }}
                                        />
                                    </BasePermissionBlocker>
                                </div>
                            }
                        ]}

                        alternate
                        manualControls={{
                            ordering: (newOrdering) => {
                                if (newOrdering) {
                                    let name: Customer.DTO.OrderBy | undefined;

                                    switch (newOrdering?.index) {
                                        case 0:
                                            name = "customer_name";
                                            break;
                                        case 1:
                                            name = "email";
                                            break;
                                        case 2:
                                            name = "phone";
                                            break;
                                        case 3:
                                            name = "contact_name";
                                            break;
                                        default:
                                            name = undefined;
                                    }

                                    if (name)
                                        setOrderBy({
                                            name,
                                            type: newOrdering.order
                                        });
                                } else {
                                    setOrderBy(undefined);
                                }
                            }
                        }}
                    />

                    <Pagination
                        className="mt-[32px]"
                        {...pagination.adapt(customers)}
                    />
                </Can>
            </div>

            {/* <MainExportModal
                isOpen={isExportModalOpen}
                onClose={() => setIsExportModalOpen(false)}
                pagination={pagination}
            /> */}

            {/* DELETION MODAL */}
            <DeletionModal
                isOpen={isDeletionModalOpen}
                onClose={() => setIsDeletionModalOpen(false)}
                entity={actionEntity}
            />
        </>
    );
}