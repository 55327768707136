import React from 'react'
import {useTranslation} from 'react-i18next';
import BaseTable from '@reusables/BaseTable'
import {Customer} from '@/types/general'
import dayjs from 'dayjs'
import { Can } from '@/casl.config';
import NoPermissionBanner from '@/components/ErrorPages/NoPermissionBanner';

export default function DiscountTab({ customer }: { customer: Customer.Extended }) {
    const {t} = useTranslation("", {keyPrefix: "customers.general.tabs.pricesAndDiscounts"});

    const discountData = [
        ...(customer.products
            ? customer.products.map((product) => ({
                    ...product,
                    type: "product" as const,
                }))
            : []),
        ...(customer.category_discounts
            ? customer.category_discounts.map((category) => ({
                    ...category,
                    type: "category" as const,
                }))
            : []),
    ]

    return (
        <>
            <Can not I="product_list.view" a="customer">
                <NoPermissionBanner />
            </Can>

            <Can I="product_list.view" a="customer">
                <BaseTable
                    data={discountData}
                    columns={[
                        {
                            header: t("table.columns.0"),
                            getter: (row) =>
                                row.type === "product"
                                    ? row.product?.name
                                    : (
                                        <div className="flex items-center space-x-2">
                                            <div>{row.category?.name}</div>
                                            <span className="bg-[#E5E5E5] rounded-[16px] px-2 py-1 font-thin text-xs text-[#686868]">
                                                {t("table.categoryLabel")}
                                            </span>
                                        </div>
                                    )
                        },
                        {
                            header: t("table.columns.1"),
                            getter: (row) => row.type === "product" ? row.sale_price : ""
                        },
                        {
                            header: t("table.columns.2"),
                            getter: (row) => row.discount ?? "-"
                        },
                        {
                            header: t("table.columns.3"),
                            getter: (row) => row.min_quantity ?? "-"
                        },
                        {
                            header: t("table.columns.4"),
                            getter: (row) => row.valid_from?.format("DD.MM.YYYY") ?? "-"
                        },
                        {
                            header: t("table.columns.5"),
                            getter: (row) => {
                                if (!row.valid_to) {
                                    // No expiration date
                                    return <span className='bg-[#E5E5E5] rounded-[16px] px-2 py-1 font-thin'>{t("table.noExpiration")}</span>;
                                }
                            
                                const expirationDate = dayjs(row.valid_to);
                                const currentDate = dayjs();
                            
                                // Check if expiration date is in the future
                                if (currentDate.isBefore(expirationDate)) {
                                    return expirationDate.format("DD.MM.YYYY");
                                } else {
                                    return (
                                        <div>
                                            {expirationDate.format("DD.MM.YYYY")} 
                                            <span className='bg-[#E5E5E5] rounded-[16px] px-2 py-1 font-thin'>
                                                {t("table.expiredLabel")}
                                            </span>
                                        </div>
                                    );
                                }
                            }
                        }
                    ]}
                />
            </Can>
        </>
    )
}
